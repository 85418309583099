const calcTime = (time) => {
    let timelabel = 0
    //SE MENOR DE ZERO
    if(Math.floor(time) === 0 && (time - Math.floor(time)) > 0){
      return (`${Math.floor(time * 60)}s`)
    } 
  
    if(Math.floor(time) === 0 && (time - Math.floor(time)) === 0){
      return 0
    }
  
    //se ENTRE 1 E 60
    if(time > 1 && time < 60){
      return (`${Math.floor(time)}m ${ Math.floor((time - Math.floor(time)) * 60)}s`)
    }
  
    //SE MAIOR DE 60
    if(time > 60){
      if((time / 60) > 24){
        return (`${Math.floor((time / 60) / 24)}d ${ Math.floor((time - (Math.floor((time / 60) / 24))*24*60)/60)}h`)
      } else {
        return (`${Math.floor((time / 60))}h ${Math.floor(((time / 60) - Math.floor((time / 60))) * 60)}m`)
      }
      
      return timelabel
    }
  }

  const durationTime = (time) => {
    const timeLabel = new Date(time * 1000).toISOString().substr(11, 8).split(':');
    let duration = ''
    if(Number(timeLabel[0]) > 0){
      duration = duration + timeLabel[0] + ':'
    }
    if(Number(timeLabel[1]) > 0){
      duration = duration + timeLabel[1]
    } else {
      duration = duration + '0'
    }
    if(Number(timeLabel[2]) > 0){
      duration = duration + ":" + timeLabel[2]
    }
    return duration
  }

  const secondsToTime = (seconds) => {
    
    if(seconds > 0 && seconds < 60){
      return `0:${seconds < 10 ? `0${seconds}` : seconds}`
    } else if(seconds >=60 && seconds <= 3599) {
      const min = Math.floor(seconds / 60)
      const sec = parseInt(seconds) - (parseInt(min*60))
      return `${min < 10 ? `${min}` : min}:${sec < 10 ? `0${sec}` : sec}`
    }  else if(seconds > 3599) {
      const hr = Math.floor(seconds / 3600)
      let sec = parseInt(seconds) - (parseInt(hr*3600))
      const min = Math.floor(sec / 60)
      sec = parseInt(sec) - parseInt(min * 60)
      return `${hr < 10 ? `${hr}` : hr}:${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`

    } else {
      return `0:00`
    }
  }

  const formatHoursMinutes = (minutes) => {
    
    if(minutes > 0 && minutes < 60){
      return `0:${minutes < 10 ? `0${minutes}` : minutes}`
    } else if(minutes >=60 && minutes <= 3599) {
      const min = Math.floor(minutes / 60)
      const sec = parseInt(minutes) - (parseInt(min*60))
      return `${min < 10 ? `${min}` : min}:${sec < 10 ? `0${sec}` : sec}`
    }  else if(minutes > 3599) {
      const hr = Math.floor(minutes / 60)
      let min = parseInt(minutes) - (parseInt(hr*60))
      //const min = Math.floor(sec / 60)
      //sec = parseInt(sec) - parseInt(min * 60)
      //return `${hr < 10 ? `${hr}` : hr}:${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`
      return `${hr}:${min < 10 ? `0${min}` : min}`

    } else {
      return `0:00`
    }
  }




  export {
    calcTime,
    durationTime,
    secondsToTime,
    formatHoursMinutes
  }