import React, { Component } from 'react'
import { Box } from '@atlaskit/primitives';
import { Checkbox } from '@atlaskit/checkbox';

export default class IndeterminateCheckbox2 extends Component {
  state = {
    data: [],
    parent: { label: 'Todos', checked: false, isIndeterminate: false }
  }
  componentDidMount() {
    const { parent } = this.state
    let allChecked = true
    let anyChecked = false
    this.props.data.forEach(x => {
      allChecked = allChecked && x.checked
      anyChecked = anyChecked || x.checked
    })
    parent.isIndeterminate = anyChecked
    parent.checked = anyChecked
    if (allChecked) {
      parent.isIndeterminate = false
    }
    this.setState({ data: this.props.data })
  }
  onChangeParent = () => {
    const { data, parent } = this.state
    parent.checked = !parent.checked
    parent.isIndeterminate = !parent.checked
    data.forEach(item => {
      item.checked = parent.checked
    })
    this.setState({ data, parent })
    this.props.onChange(data)
  }
  onChange = (item) => {
    const { data, parent } = this.state
    let allChecked = true
    let anyChecked = false
    data.forEach(x => {
      if (x.label === item.label) {
        x.checked = !x.checked
      }
      allChecked = allChecked && x.checked
      anyChecked = anyChecked || x.checked
    })
    parent.isIndeterminate = anyChecked
    parent.checked = anyChecked
    if (allChecked) {
      parent.isIndeterminate = false
    }
    this.setState({ data, parent })
    this.props.onChange(data)
  }
  render() {
    const { data, parent } = this.state
    return (
      <Box>
        <Checkbox
          isChecked={parent.checked}
          isIndeterminate={parent.isIndeterminate}
          onChange={this.onChangeParent}
          label="Todos"
          value={parent.label}
          name="parent"
        />
        <div className='pl-1'>
          {data.map((item, i) => {
            return (
              <Checkbox
                key={`array-itens-${i}`}
                isChecked={item.checked}
                onChange={() => this.onChange(item)}
                label={
                  item.color ?
                    <div className='d-flex'>
                      <div className='color-filter-insights' style={{ backgroundColor: item.color }}></div>
                      <span>{item.label}</span>
                    </div>
                    :
                    item.label
                }
                value={item.label}
                name={`array-itens-${i}`}
              />
            )
          })}
        </div>
      </Box>
    )
  }
}
