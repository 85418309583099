import React, { Component } from 'react'
import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import AvatarPessoa from '../../../../utility/AvatarPessoa';
import { Querys } from '../../../../database/base';
import ButtonAtl from '@atlaskit/button';
import { Cache } from '../../cache/cache';

export default class SelectPeople extends Component {
    state = {
        data: [],
        defaultValue: null
    }
    componentWillMount() {
        if (this.state.defaultData && this.state.defaultData.length > 0)
            this.setState({ data: this.state.defaultData })
        else
            Querys.Person.List().then(({ data }) => this.setState({ data }))

        if (this.props.defaultValue && this.props.defaultValue.length > 0) {
            this.setState({
                defaultValue: this.props.defaultValue.map((item) => {
                    return {
                        label: (
                            <div className='d-flex' key={`select-people-${item.id}`}>
                                <AvatarPessoa codigo={item.id} />
                                <span className='person-select-name'>{item.name}</span>
                            </div>),
                        value: item.id,
                        name: item.name,
                        item
                    }
                })
            })
        }

        if (this.props.defaultUserLogin) {
            const user = Cache.user().get()
            this.setState({
                defaultValue: {
                    label:
                        <div className='d-flex'>
                            <AvatarPessoa codigo={user.codigo} />
                            <span className='person-select-name'>{user.name}</span>
                        </div>,
                    value: user.codigo
                }
            })
        }
    }
    render() {
        const options = (this.props.defaultData ?? this.state.data).map((item) => {
            return {
                label: (
                    <div className='d-flex' key={`select-people-${item.id}`}>
                        <AvatarPessoa codigo={item.id} />
                        <span className='person-select-name'>{item.name}</span>
                    </div>),
                value: item.id,
                name: item.name,
                item
            }
        })
        let value = this.state.defaultValue
        if (this.props.valueID) {
            value = options.find(x => x.value === this.props.valueID)
        }
        return (
            <>
                {!this.props.disableHader &&
                    <Field label={this.props.label} name="example-text">
                        {({ fieldProps }) => { }}
                    </Field>}
                <>
                    <Select
                        isMulti={this.props.isMulti ?? false}
                        isClearable={this.props.isClearable}
                        placeholder={this.props.placeholder ?? 'Não atribuído'}
                        className="w-100"
                        value={value}
                        options={options}
                        filterOption={(option, rawInput) => {
                            return option.data.name.toLowerCase().includes(rawInput.toLowerCase())
                        }}
                        onChange={e => {
                            this.setState({ defaultValue: e })
                            if (!(this.props.isMulti ?? false)) {
                                let value = e?.value ?? null
                                this.props.onChange(value)
                            } else {
                                let value = e ?? []
                                this.props.onChange(value)
                            }
                        }}
                    />
                    {this.props.assignedToMe &&
                        <ButtonAtl appearance='link' className='atl-button'
                            onClick={() => {
                                const user = Cache.user().get()
                                this.setState({
                                    defaultValue: {
                                        label:
                                            <div className='d-flex'>
                                                <AvatarPessoa codigo={user.codigo} />
                                                <span className='person-select-name'>{user.name}</span>
                                            </div>,
                                        value: user.codigo
                                    }
                                })
                                this.props.onChange(user.codigo)
                            }}>
                            <b>Atribuir a mim</b>
                        </ButtonAtl>}
                </>
            </>
        )
    }
}
