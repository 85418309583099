import { getCache, setCache } from "./base"

const key = '_suricatoo_cache_omboarding'
const defaultCache = {
    stOnboardingRun: false
}
export const get = () => {
    return getCache(key, defaultCache)
}

export const set = (obj) => {
    const df = get(key)
    setCache(key, { ...df, ...obj })
}