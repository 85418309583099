const INITIAL_STATE = {
    pessoasKey: {},
    pessoas: [],
    QuantidadePendencias: 0,
    DetalhesUsuario: {}
}

export const cachePessoas = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ATUALIZAR_PESSOA_CODIGO": {
            const pessoa = action.data
            state.pessoas.forEach(element => {
                if (element.Codigo === pessoa.Codigo) {
                    element = { ...pessoa }
                }
            });
            state.pessoasKey[pessoa.Codigo] = { ...pessoa }
            return {
                ...state,
                pessoas: [...state.pessoas],
                pessoasKey: { ...state.pessoasKey }
            }
        }
        case "CARREGAR_PESSOAS": {
            const pessoas = action.data ?? []
            state.pessoasKey = {}
            pessoas.forEach((pessoa) => {
                state.pessoasKey[pessoa.Codigo] = { ...pessoa }
            })
            return {
                ...state,
                pessoas: [...pessoas],
                pessoasKey: { ...state.pessoasKey }
            }
        }
        case "QUANTIDADE_PENDENCIAS": {            
            return {
                ...state,
                QuantidadePendencias: action.QuantidadePendencias
            }
        }
        case "ATUALIZA_QUANTIDADE_PENDENCIAS": {            
            return {
                ...state,
                QuantidadePendencias: action.QuantidadePendencias
            }
        }
        case "DETALHES_USUARIO": {            
            return {
                ...state,
                DetalhesUsuario: action.DetalhesUsuario
            }
        }
        case "REMOVER_PESSOA": {

            const codigo = action.data
            delete state.pessoasKey[codigo]
            let i = 0
            state.pessoas.forEach((item, index) => {
                if (item.Codigo === codigo) {
                    i = index
                    return
                }
            })

            state.pessoas.slice(i, 1)
            return {
                ...state,
                pessoasKey: { ...state.pessoasKey },
                pessoas: [...state.pessoas.filter( f => f.Codigo !== codigo )]
            }
        }
        
        default: {
            return state
        }
    }
}
