import React, { Component } from 'react'
import { Navbar } from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import logoImg from "../../../assets/img/logo/logoSuricate.png"
import "./Navbar.css"
import { connect } from 'react-redux'
import ButtonAtl from '@atlaskit/button';
import BaseComponentSuricatoo, { Translate } from '../../../utility/BaseComponentSuricatoo'
import { injectIntl } from 'react-intl'
import AddIcon from '@atlaskit/icon/glyph/add'
import CreateCard from '../../../views/pages/create-card/CreateCard'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Card from '../../../views/pages/card/Card'
import GlobalSearch from './GlobalSearch'
import ModalSuricatoo from '../../../utility/ModalSuricatoo'
import Tempo from '../../../views/pages/tempo/Tempo'
import { SpotlightTarget } from '@atlaskit/onboarding'
import Tooltip from '@atlaskit/tooltip';
import RecentIcon from '@atlaskit/icon/glyph/recent'
import FavLink from '../../../views/pages/painel/components/FavLink'

class ThemeNavbar extends BaseComponentSuricatoo {
  state = {
    openTime: false
  }
  render() {
    const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
    const navbarTypes = ["floating", "static", "sticky", "hidden"]
    if (!this.props.user) {
      return (<div></div>)
    }
    const { user } = this.props
    return (
      <React.Fragment>
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <Navbar
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-light": this.props.navbarColor === "default" || !colorsArr.includes(this.props.navbarColor),
              "navbar-dark": colorsArr.includes(this.props.navbarColor),
              "bg-primary":
                this.props.navbarColor === "primary" && this.props.navbarType !== "static",
              "bg-danger":
                this.props.navbarColor === "danger" && this.props.navbarType !== "static",
              "bg-success":
                this.props.navbarColor === "success" && this.props.navbarType !== "static",
              "bg-info":
                this.props.navbarColor === "info" && this.props.navbarType !== "static",
              "bg-warning":
                this.props.navbarColor === "warning" && this.props.navbarType !== "static",
              "bg-dark":
                this.props.navbarColor === "dark" && this.props.navbarType !== "static",
              "d-none": this.props.navbarType === "hidden" && !this.props.horizontal,
              "floating-nav":
                (this.props.navbarType === "floating" && !this.props.horizontal) || (!navbarTypes.includes(this.props.navbarType) && !this.props.horizontal),
              "navbar-static-top":
                this.props.navbarType === "static" && !this.props.horizontal,
              "fixed-top": this.props.navbarType === "sticky" || this.props.horizontal,
              "scrolling": this.props.horizontal && this.props.scrolling

            }
          )}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <div className='d-flex'>

                  <div style={{ marginRight: '7px' }}>
                    <ModalSuricatoo open={this.state.openTime} onClose={() => this.setState({ openTime: false })} width='1200px' autoFocus={false}>
                      {this.state.openTime && <Tempo />}
                    </ModalSuricatoo>
                    <SpotlightTarget name='kanban-button-tempo'>
                      <Tooltip content='Mostrar tempo das atividades' position='bottom-start'>
                        <ButtonAtl
                          style={{ width: '39px', height: '39px', marginRight: '5px' }}
                          className='atl-button'
                          onClick={() => this.setState({ openTime: true })}
                          iconBefore={<RecentIcon label="" size="large" />}
                          appearance="default"
                        >
                        </ButtonAtl>
                      </Tooltip>
                    </SpotlightTarget>
                  </div>
                  <CreateCard />
                  <GlobalSearch />
                </div>
                {this.props.horizontal ? (
                  <div className="logo d-flex align-items-center">
                    <div>
                      <img src={logoImg} width='40' style={{ position: 'relative', top: '-5px', left: '-2px' }} />
                    </div>
                    <h2 className="text-white brand-text mb-0">
                      <span className="text-white">
                        Minzi
                      </span>
                    </h2>
                  </div>
                ) : null}

                <NavbarUser
                  handleAppOverlay={this.props.handleAppOverlay}
                  changeCurrentLang={this.props.changeCurrentLang}
                  user={this.props}
                  userName={user.name}
                  codigo={user.Codigo}
                  userImg={userImg}
                />
              </div>
            </div>
          </div>
        </Navbar>
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.login.user
  }
}
export default connect(mapStateToProps)(ThemeNavbar)
