import {del, get, post, put} from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.RouterUrl}/api/management`
}

export const PreFilter = (params) => {
    return post(`${getUrl()}/detailed/report/filters`, params)
}