import {del, get, post, put} from "./base"
import config from '../Config'


const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/helper`
}

export const AppInfo = () => {
    return get(`${getUrl()}/app/info`)
}

export const Search = (search) => {
    return get(`${getUrl()}?search=${search}`)
}

export const GetByID = (id, params) => {
    return get(`${getUrl()}/${id}?${params}`)
}

export const Update = (id, data) => {
    return put(`${getUrl()}/${id}`, data)
}

export const Delete = (id) => {
    return del(`${getUrl()}/${id}`)
}

export const Create = (data) => {
    return post(`${getUrl()}`, data)
}

export const Publish = (id) => {
    return put(`${getUrl()}/${id}/publish`)
}

export const PublishProd = (id) => {
    return put(`${getUrl()}/${id}/publishProd`)
}

export const Reviewed = (id, data) => {
    return put(`${getUrl()}/${id}/review`)
}

export const ListAll = () => {
    return get(`${getUrl()}/list/all`)
}

export const Menu = () => {
    return get(`${getUrl()}/list/menu`)
}