import { del, get, post, put } from "./base"
import config from '../Config'
import axios from "axios"
import { v4 as uuidv4 } from 'uuid';
import { Cache } from "../views/pages/cache/cache";

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.RouterUrl}/api/activity`
}

const getUrlApi = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/activity`
}

export const Create = (data) => {
    return post(`${getUrl()}`, data)
}

export const Start = (cardKey, dataCard, dispatch) => {
    return new Promise((resolve, reject) => {
        const app = JSON.parse(localStorage.getItem(`_suricatoo_app`))
        const appOnline = window.appOnline
        const token = uuidv4();
        const userTracking = window.userTracking
        const deviceID = Cache.deviceID().get()
        post(`${getUrlApi()}/${cardKey}/start`, {
            ...dataCard,
            token,
            app: app?.app ?? false,
            appOnline,
            userTracking,
            deviceID
        }).then(e => {
            window.onStartEvent()
            resolve(e)
        }).catch(e => reject(e))
    })
}

export const Stop = (cardKey) => {
    return new Promise((resolve, reject) => {
        post(`${getUrlApi()}/${cardKey}/stop`).then(r => {
            window.onStopEvent()
            resolve(r)
        }).catch(r => {
            reject(r)
        })

    })

}

export const ActivityCreated = (cardKey) => {
    return post(`${getUrlApi()}/${cardKey}/created`)
}

export const GroupView = (groupID) => {
    return get(`${getUrlApi()}/${groupID}/group`)
}