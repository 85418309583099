import React from 'react';
import Config from '../../../Config';
import { Cache } from '../cache/cache';
const userToken = Cache.user().get()

export const NewMinziEditor = function ({ id, url, style, className, documentID, companyKey, version, documentData, readOnly, card }) {
    if (!url) {
        let urlFrame = ''
        urlFrame = `${Config.WikiAppURL}?p=${userToken.access_token}`
        urlFrame += `&origin=${window.location.origin}`

        if (!documentID) return <> Documento nao valido </>
        if (!companyKey) return <> Documento nao valido </>

        urlFrame += `&document=${documentID}`
        urlFrame += `&companyCode=${companyKey}`
        urlFrame += `&version=${version ?? 0}`
        urlFrame += `&documentData=${documentData ?? null}`
        urlFrame += `&routerURL=${Config.RouterUrl}`

        if (readOnly) urlFrame += `&readOnly=true`
        if (card) urlFrame += `&card=true`

        url = urlFrame
    }

    url += `&apiURL=${Config.ApiURL}`

    return <>
        <iframe
            id={id}
            style={style ?? null} src={url}
            title='minzi-document' allow='clipboard-read; clipboard-write'
            className={`minzi-editor-frame ${className}`}>
        </iframe>
    </>
}
