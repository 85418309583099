import React from 'react'
import { ErrorMessage } from '@atlaskit/form';

export default function ValidationMessage({ name, dirty, isRequired, message, state, defaultValue, errors }) {
    let isValid = false
    const isDirt = dirty[name]
    if (state[name] === defaultValue[name] && isDirt) {
        isValid = false
    } else {
        isValid = true
    }
    if (!isValid)
        return (<ErrorMessage>{message}</ErrorMessage>)
    const isError = errors[name]?.error
    if (isError) {
        return (<ErrorMessage>{errors[name].message}</ErrorMessage>)
    }
    return <></>
}

export function FormValid(state, required, defaultValue) {
    let valid = true
    for (let index = 0; index < required.length; index++) {
        const req = required[index];
        valid = valid && state[req] !== defaultValue[req]
        if (!valid) {
            return valid
        }
    }
    let haveError = true
    Object.keys(state.errors).forEach(item => haveError = haveError && state.errors[item].error)
    if (haveError) {
        valid = false
    }
    return valid
}

export function FieldValid(name, dirty, state, defaultValue) {
    const isDirt = dirty[name]
    if (!isDirt) return false
    return state[name] === defaultValue[name]
}