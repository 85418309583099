import { TimerUtils } from "../../../utility/TimerConvertUtils"

export const InsightsPlugins =
{
    title: {
        display: false,
        text: 'Chart.js Bar Chart - Stacked',
    },
    tooltip: {
        enabled: true,
        callbacks: {
            label: (item) => {
                return `${TimerUtils.HoraMinuto.HoraToHoraMinuto(item.raw)} (${item.dataset.label})`
            },
        },
        filter: (e) => {
            return e.raw > 0
        },
        itemSort: (a, b) => {
            if (a.raw < b.raw) {
                return -1;
            }
            if (a.raw > b.raw) {
                return 1;
            }
            return 0;
        },
    },
    sort: {
        enable: true,
        sortFunction: (a, b) => {
        }
    }
}

export const BuildOptions = (datasets) => {
    const options = {
        x: [],
        y: []
    }
    datasets.labels.forEach(element => {
        options.x.push({ label: element, color: '', checked: true })
    });
    datasets.datasets.forEach(element => {
        options.y.push({ label: element.label, color: element.backgroundColor, checked: true })
    })
    return options
}

export const Filter = (data, { filter, order }) => {
    let promise = new Promise(function (resolve, reject) {
        if (filter?.excludeHeader && filter.excludeHeader.length > 0) {
            filter.excludeHeader.forEach(e => {
                const index = data.labels.findIndex(x => x === e)
                data.labels.splice(index, 1)
                data.datasets.forEach(dt => {
                    dt.data.splice(index, 1)
                })
            })
        }
        if (filter?.excludeData && filter?.excludeData.length > 0) {
            filter.excludeData.forEach(e => {
                const index = data.datasets.findIndex(x => x.label === e)
                data.datasets.splice(index, 1)
            })
        }
        if (order) {
            const dataTotal = []
            data.datasets.forEach((item, index) => {
                const data = []
                item.data.forEach(d => {
                    data.push(d)
                })
                if (index === 0) {
                    dataTotal.push(...data)
                } else {
                    data.forEach((item, index) => {
                        dataTotal[index] += item
                    })
                }
            })
            const itens = []
            dataTotal.forEach((item, index) => {
                itens.push({ value: item, index })
            })
            itens.sort((a, b) => {
                if (order.asc) {
                    if (a.value < b.value)
                        return -1
                    if (a.value > b.value)
                        return 1
                    return 0
                }
                if (!order.asc) {
                    if (a.value < b.value)
                        return 1
                    if (a.value > b.value)
                        return -1
                    return 0
                }
                return 0
            })
            const newData = {
                labels: [],
                datasets: data.datasets,
                options: data.options
            }
            itens.forEach(item => {
                newData.labels.push(data.labels[item.index])
            })
            newData.datasets.forEach((item, index) => {
                const values = []
                itens.forEach(item => {
                    values.push(data.datasets[index].data[item.index])
                })
                newData.datasets[index].data = values
            })
            data = newData
        }
        resolve({ data })
    });
    return promise
}