import React, { useRef, useState, useEffect } from 'react'
import Button from '@atlaskit/button';
import Textfield from '@atlaskit/textfield';
import CrossIcon from '@atlaskit/icon/glyph/cross'
import CheckIcon from '@atlaskit/icon/glyph/check'
import * as Icon from "react-feather"

export default function CreateItem({ backlog, onCreateItem, card }) {
    const [name, setName] = useState('')
    const [createItem, setCreateItem] = useState(false)
    const messagesEnd = useRef(null)

    useEffect(() => {
        document.addEventListener("keydown", keyPress, false)
    }, []);

    function save() {
        if (onCreateItem && createItem && name) {
            onCreateItem({ name, cardKey: 'newItem-' + new Date().getTime(), parent: null, parentCardID: null })
            setName('')
            scrollToBottom()
        }
        else clear()
    }

    function clear() {
        setName('')
        setCreateItem(!createItem)
    }

    function keyPress(e) {
        if (e.code === 'Escape') setCreateItem(false)
        if (e.ctrlKey && e.code === "Space") clear()
    }

    function scrollToBottom() {
        if (card) messagesEnd.current.scrollIntoView({ block: "center" });
        else messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    }

    return (<React.Fragment>
        {
            createItem ?
                <div className="d-flex" ref={messagesEnd}>
                    <Textfield
                        className='display-none'
                        autoFocus={true}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        onKeyDown={e => {
                            if (e.keyCode === 13) save()
                        }} />
                    <Button className='buttons-edit-task atl-button'
                        onClick={() => clear()}
                        iconBefore={<CrossIcon label="" size="medium" />}
                        appearance="default"
                    >
                    </Button>
                    <Button className='buttons-edit-task atl-button'
                        onClick={e => save()}
                        iconBefore={<CheckIcon label="" size="medium" />}
                        appearance="default"
                    >
                    </Button>
                </div> :
                <div className={backlog ? 'accordion-add-item-backlog' : 'accordion-add-item'} onClick={e => save()}>
                    <React.Fragment>
                        <Icon.Plus /> <b>Criar</b> <small>[ctrl + space]</small>
                    </React.Fragment>
                </div>
        }
        <div style={{ marginBottom: !card ? '3rem' : '0px' }}></div>
    </React.Fragment>)
}