import React from "react"
import { X, ChevronDown, Minus, Plus } from "react-feather"
import {
    Input
  } from "reactstrap"
import "../../../assets/scss/components/help.scss"

class Help extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            topics: null,
            activeTopic: null,
            search: '',
            minimized: false
        }
     }

     handleOpenHelp = () => {
         
         setTimeout(() => {
            this.setState({ minimized: false })
          }, 500);

          this.props.handleOpenHelp()
      }

      componentDidMount = () => {
            this.setState({
                topics: this.props.topics
            })
      }

      handleShowTopic = (topic) => {
        this.setState( prevState => (
            {
                activeTopic: prevState.activeTopic === topic ? null : topic
            }
        ))
      }

      searchSpace=(event)=>{
        let keyword = event.target.value;
        if(keyword.length > 2){
            this.setState({search:keyword})
        } else {
            this.setState({search:null}) 
        }
        
      }

      handleMinimizeHelp = () => {
        this.setState(prevState => ({
            minimized: !prevState.minimized
        }))
      }
    
    render() {
        const { show, position, title, description, illustration, className } = this.props
        const { topics, activeTopic, minimized } = this.state
        return (
                <div className={`help-container ${show ? 'show' : ''} ${position}`}>
                    <div className={`help-overlay ${show && !minimized ? 'show' : ''}`} onClick={this.handleOpenHelp} />
                    <div className={`help-content d-flex flex-column ${show ? 'show' : ''} ${minimized ? 'minimized' : ''} ${className ? className : ''} ${position}`}>
                        <div className="help-header">
                            <h3 
                                className={`${minimized ? 'cursor-pointer' : ''}`}
                                onClick={minimized ? this.handleMinimizeHelp : null}
                                >Tópicos de ajuda do Minzi</h3>
                            <Input 
                                className={`mr-m `}
                                value=""
                                autoComplete="off"
                                name="search"
                                type="text"
                                value={this.state.search}
                                placeholder="Buscar"
                                onChange={(e)=>this.searchSpace(e)}
                                />
                        </div>
                        <div className="topics flex-grow-1">
                            {topics &&
                            <React.Fragment>
                                <div className="topcs-title">
                                    <h3>{topics.title}</h3>
                                    {topics.caption && <h6>{topics.caption}</h6>}
                                </div>
                                <div className="topics-content">
                                    {topics && topics.topics && topics.topics.length > 0  ? 
                                        <div>
                                            {topics.topics.filter((data)=>{
                                                    if(this.state.search === null)
                                                        return data
                                                    else if(
                                                    data.title.toLowerCase().includes(this.state.search.toLowerCase()) ||
                                                    data.description.toLowerCase().includes(this.state.search.toLowerCase()) ||
                                                    data.keywords.toLowerCase().includes(this.state.search.toLowerCase())
                                                    
                                                    ){
                                                        return data
                                                    }
                                                }).map( m => 
                                                    <div key={`topic_${m.id}`} className={`topic-item ${activeTopic === m.id ? 'active' : ''}`} onClick={() => this.handleShowTopic(m.id)}>
                                                        <div className="d-flex justify-content-between align-items-center topic-title">
                                                            <span>{m.title}</span>
                                                            <ChevronDown size={15} />
                                                        </div>
                                                        <div className={`topic-description`} dangerouslySetInnerHTML={{ 
                                                            __html: m.description
                                                        }}/>
                                                                                                
                                                    </div>
                                                )}
                                        </div>:
                                        <div>Não há tópicos de ajuda</div>
                                        
                                    }
                                </div>
                            </React.Fragment>
                            }
                            
                        </div>
                        <div className="help-tools d-flex">
                            <div className="help-minus" onClick={this.handleMinimizeHelp}>
                                {minimized ? <Plus /> : <Minus />}
                            </div>
                            <div className="help-close" onClick={this.handleOpenHelp}>
                                <X />
                            </div>

                        </div>
                    </div>
                </div>
        )
    }
}

export default Help