import {del, get, post, put} from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/integration`
}

export const Tokens = () => {
    return get(`${getUrl()}/token`)
}

export const NewToken = (data) => {
    return post(`${getUrl()}/token`, data)
}

export const DeleteToken = (id) => {
    return del(`${getUrl()}/${id}/token`)
}

export const Webhooks = () => {
    return get(`${getUrl()}/webhook`)
}

export const NewWebhook = (data) => {
    return post(`${getUrl()}/webhook`, data)
}

export const DeleteWebhook = (id) => {
    return del(`${getUrl()}/${id}/webhook`)
}

export const UpdateWebhook = (data) => {
    return put(`${getUrl()}/webhook`, data)
}

export const HookMessages = () => {
    return get(`${getUrl()}/hook/messages`)
}
