import React, { Component } from 'react'
import { TimerUtils } from '../../../../../utility/TimerConvertUtils'
import AvatarPessoa from '../../../../../utility/AvatarPessoa';
import { Progress } from 'reactstrap'
import Tooltip from '@atlaskit/tooltip';

export default class CapacityLine extends Component {
    render() {
        const { id, name, capacity, mUserPlannedTime, mUserTotal100, percentUser, mUserExceded, mUserNotUsed } = this.props
        return (
            <div className='d-flex' key={`sprint-capacity-${id}`}>
                {!this.props.noAvatar && <AvatarPessoa codigo={id} nome={name} />}
                <div className='sprint-progress-content'>
                    <Progress multi className='sprint-progress'>
                        <div className="progress-bar progress-bar-completed" style={{ width: mUserPlannedTime / mUserTotal100 * percentUser + '%' }}>
                            {TimerUtils.HoraMinuto.MinutosToHoraMinuto(mUserPlannedTime - mUserExceded)}
                        </div>
                        <div className="progress-bar progress-bar-notUsed" style={{ width: mUserNotUsed / mUserTotal100 * percentUser + '%' }}></div>
                        <div className="progress-bar progress-bar-limit" style={{ width: '1%' }}></div>
                        <div className="progress-bar progress-bar-exceded" style={{ width: mUserExceded / mUserTotal100 * percentUser + '%' }}>
                            {TimerUtils.HoraMinuto.MinutosToHoraMinuto(mUserExceded)}
                        </div>
                    </Progress>
                </div>
                <div className='d-flex'>
                    <Tooltip content='Capacidade total'>
                        <b style={{ marginLeft: '3px', marginTop: '1px' }}>
                            {TimerUtils.HoraMinuto.MinutosToHoraMinuto(capacity * 60)}
                        </b>
                    </Tooltip>
                    <Tooltip content='Total planejado'>
                        <small style={{ marginLeft: `3px` }}>{`(${TimerUtils.HoraMinuto.MinutosToHoraMinuto(mUserPlannedTime)})`}</small>
                    </Tooltip>
                    <Tooltip content='Tempo faltante'>
                        <small style={{ marginLeft: `3px` }}>{`(${TimerUtils.HoraMinuto.MinutosToHoraMinuto((capacity * 60) - mUserPlannedTime)})`}</small>
                    </Tooltip>
                </div>
            </div>
        )
    }
}
