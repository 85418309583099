import { del, get, post, put } from "./base"
import config from '../Config'


const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/spotlight`
}

export const Get = (key) => {
    return get(`${getUrl()}/${key}`)
}

export const Spotlights = () => {
    return get(`${getUrl()}/list/sp`)
}

export const Onboardings = () => {
    return get(`${getUrl()}/list/onboarding`)
}

export const SpotlightsCreate = (data) => {
    return post(`${getUrl()}`, data)
}

export const OnboardingCreate = (data) => {
    return post(`${getUrl()}/onboarding`, data)
}

export const Delete = (id) => {
    return del(`${getUrl()}/${id}`, id)
}

export const DeleteOnboarding = (id) => {
    return del(`${getUrl()}/onboarding/${id}`, id)
}
