import React, { Component } from 'react'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import Lozenge from '@atlaskit/lozenge';

export default class DropdownWorkPlanStatus extends Component {
    render() {
        let status = this.props.data?.find(x => x.type === this.props.status)
        if (!status) {
            return <></>
        }
        return (
            <DropdownMenu
                placement='bottom-start'
                trigger={({ triggerRef, testId, isSelected, ...props }) => (
                    <Button
                        className={`btn-status atl-button ${status.color === 'in progress' ?
                            'btn-inProgress' : status.color === 'done' ? 'btn-done' : ''} ${this.props.spacing && 'card-status-big'}`}
                        {...props}
                        shouldFitContainer
                        iconAfter={<ChevronDownIcon />}
                        ref={triggerRef}
                        spacing={this.props.spacing ?? 'compact'}>
                        <span>{status.name}</span>
                    </Button>
                )}>
                <DropdownItemGroup>
                    {this.props.data?.filter(x => x.type !== this.props.status).map((item, index) => (
                        <DropdownItem key={`dropdownWorkPlanStatus-${index}`} onClick={() => this.props.onClick(item.type)}>
                            <Lozenge appearance={
                                item.color === 'in progress' ?
                                    'inprogress' : item.color === 'done' ? 'success' : 'default'}>{item.name}</Lozenge>
                        </DropdownItem>
                    ))}
                </DropdownItemGroup>
            </DropdownMenu>
        )
    }
}
