import {del, get, post, put} from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.RouterUrl}/api`
}

export const Config = () => {
    return get(`${getUrl()}/management/company`)
}

export const Ping = () => {
    return post(`${getUrl()}/tracking/ping`)
}

export const Grupos = () => {
    return get(`${getUrl()}/back/grupo`)
}

export const ImpersonateCompanies = () => {
    return get(`${config.ApiURL}/impersonate/companies`)
}

export const ImpersonateUsersByCompany = (company) => {
    return get(`${config.ApiURL}/impersonate/${company}/users`)
}
