import React from 'react'
import axios from 'axios'
import Error from '@atlaskit/icon/glyph/error';
import { token } from '@atlaskit/tokens';
import { G300, P300, R300, Y300 } from '@atlaskit/theme/colors';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { Cache } from '../../../views/pages/cache/cache';

const userKey = '_suricatoo_user'

const INITIAL_STATE = {
  user: Cache.user().get(),
  userRole: "admin",
  flags: [],
  flagsMessage: [],
  createCard: 0,
  startActivity: 0,
  cardStart: null,
  appConfig: {},
  updateMenu: 0
}

if (INITIAL_STATE.user) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${INITIAL_STATE.user.access_token}`
}

export const login = (state = INITIAL_STATE, action) => {
  try {

    switch (action.type) {
      case "UPDATE_MENU": {
        return { ...state, updateMenu: state.updateMenu + 1 }
      }
      case "APP_CONFIG": {
        console.log({ appConfig: { ...state.appConfig, ...action } })
        return { ...state, appConfig: { ...state.appConfig, ...action } }
      }
      case "CHANGE_ROLE": {
        return { ...state, userRole: action.userRole }
      }
      case "LOGOUT": {
        localStorage.removeItem(userKey)
        return { ...state, user: null }
      }
      case "CARD_CREATED": {
        return { ...state, createCard: state.createCard + 1 }
      }
      case "START_ACTIVITY": {
        return { ...state, startActivity: state.startActivity + 1, cardStart: action.cardStart }
      }
      case "NEW_ERROR": {
        const newFlag = {
          created: Date.now(),
          description: action.error.message,
          icon:
            <Error
              label="Error"
              primaryColor={token('color.icon.danger', R300)}
            />,
          id: state.flags.length,
          key: state.flags.length,
          title: `Não foi possível realizar a ação!`,
        }
        return { ...state, flags: [...state.flags, newFlag] }
      }
      case "DISMISS_MESSAGE_FLAG": {
        state.flagsMessage.splice(0, 1)
        return { ...state, flagsMessage: [...state.flagsMessage] }
      }
      case "NEW_MESSAGE_FLAG": {
        return { ...state, flagsMessage: [action.message] }
      }
      case "NEW_MESSAGE_SUCCESS": {
        const newFlag = {
          created: Date.now(),
          icon:
            <SuccessIcon
              primaryColor={token('color.icon.success', G300)}
              label="Success"
            />,
          id: state.flags.length,
          key: state.flags.length,
          title: action.message,
        }
        return { ...state, flags: [...state.flags, newFlag] }
      }
      case "WELCOME": {
        state.user.welcome = false
        localStorage.setItem(userKey, JSON.stringify(state.user))
        return { ...state, user: { ...state.user } }
      }
      case "LOGIN": {
        return { ...state, user: action.user }
      }
      case "PREFERENCES_CONFIG": {
        return { ...state, user: { ...state.user, ...action.configs } }
      }
      case "UPDATE_USER": {
        console.log(action.user)
        localStorage.setItem(userKey, JSON.stringify(action.user))
        return { ...state, user: action.user }
      }
      default: {
        return state
      }
    }
  } catch (error) {
    console.log("loginErro: ", error)
    return state
  }
}
