

const onlyUnique = (value, index, self) =>{
    return self.indexOf(value) === index;
}
export {
    onlyUnique
}


