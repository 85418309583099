import React from 'react';

class TimerCount extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        timerOn: false,
        timerStart: 0,
        timerTime: 0,
      };
    }

    startTimer = () => {
        this.setState({
          timerOn: true,
          timerTime: this.state.timerTime,
          timerStart: Date.now() - this.state.timerTime
        });
        this.timer = setInterval(() => {
          this.setState({
            timerTime: Date.now() - this.state.timerStart
          });
        }, 10);
    };

    componentDidMount = () => {
        const { initialTime } = this.props
        console.log(initialTime)
        console.log( Date.now())
        this.startTimer()
    }


    render() {
    const {timerTime, timerStart} = this.state;
    
    let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
        return (
            <div>
                {JSON.stringify(minutes)}
                {JSON.stringify(seconds)}
               {minutes}:{seconds}
            </div>
        )
    }

}

export default TimerCount