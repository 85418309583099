import React from "react"
import { X, ChevronRight, ChevronLeft, Minus, Plus, Check } from "react-feather"

import {
    Input,
    Button,
    Row,
    Col
} from "reactstrap"
import Flatpickr from "react-flatpickr";
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import moment from "moment"

import "../../../assets/scss/components/suricatoo-wizard.scss"
import "flatpickr/dist/themes/light.css";
import "../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss"
import { emailIsValid} from '../../../utility/validations/custom-validates'

const mock = [
    {
        id: 1,
        task: "Reunião com a equipe",
        shared: true
    },
    {
        id: 2,
        task: "Desenvolvimento front-end",
        shared: false
    },
    {
        id: 3,
        task: "Reunião diária (Daily meeting)",
        shared: true
    },
    /*{
        id: 4,
        task: "Lançamentos de NF's dos terceirizados",
        shared: true
    },
    {
        id: 5,
        task: "Fechamento de folha de pagamento",
        shared: true
    },*/
]


class Wizard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            topics: null,
            activeTopic: null,
            search: '',
            minimized: false,
            currentStep: 1,
            businessType: 'project',
            start: new Date(),
            end: "",
            projectName: "",
            sharedTasks: false,
            tasks: [],
            typeTask: '',
            user: {
                nome: '',
                email: '',
                senha: '',
                confirmacaoSenha: ''
            },
            users: []
        }
    }

    handleOpenWizard = () => {

        setTimeout(() => {
            this.setState({ minimized: false, show: false }, () => {
                console.log(this.state)
            })
        }, 100);

        // this.props.handleOpenWizard()
    }

    componentDidMount = () => {
        this.setState({
            topics: this.props.topics
        })
    }

    handleShowTopic = (topic) => {
        this.setState(prevState => (
            {
                activeTopic: prevState.activeTopic === topic ? null : topic
            }
        ))
    }

    searchSpace = (event) => {
        let keyword = event.target.value;
        if (keyword.length > 2) {
            this.setState({ search: keyword })
        } else {
            this.setState({ search: null })
        }

    }

    handleMinimizeHelp = () => {
        this.setState(prevState => ({
            minimized: !prevState.minimized
        }))
    }

    handleNextPage = () => {
        const { currentStep } = this.state;
        const{ onWizardFinished } = this.props
        this.setState({
            currentStep: currentStep === 4 ? 1 : currentStep + 1
        })
        if(currentStep === 4) {
            this.setState({
                show: false
            })
            onWizardFinished(currentStep)
        }
    }

    handlePrevPage = () => {
        const { currentStep } = this.state
        this.setState({
            currentStep: currentStep === 1 ? 4 : currentStep - 1
        })
    }
    selectBusinessType = (type) => {
        this.setState(prevState => ({
            businessType: prevState.businessType === type ? null : type
        }))
    }

    selectSharedTasks = () => {
        this.setState(prevState => ({
            sharedTasks: !prevState.sharedTasks
        }))
    }
    handleAddTask = () => {
        const { typeTask, sharedTasks } = this.state
        if (typeTask.trim().length > 0) {
            const task = {
                id: Math.random(),
                task: typeTask,
                shared: sharedTasks
            }
            this.setState({
                tasks: [...this.state.tasks, task],
                sharedTasks: false,
                typeTask: ''

            })
        }
    }

    handleRemoveTask = (id) => {
        const tasks = this.state.tasks.filter(f => f.id !== id)
        this.setState({
            tasks
        })
    }

    handleAddUser = () => {
        const { user, users } = this.state
        if (user && user.nome.trim().length > 0) {
            const users = {
                id: Math.random(),
                nome: user.nome,
                email: user.email,
                senha: user.senha,
                confirmacaoSenha: user.confirmacaoSenha
            }
            this.setState({
                users: [...this.state.users, users],
                user: {
                    nome: '',
                    email: '',
                    senha: '',
                    confirmacaoSenha: ''
                }
            })
        }
    }

    handleRemoveUser = (id) => {
        const users = this.state.users.filter(usr => usr.id !== id)
        this.setState({
            users
        })
    }




    render() {
        const { position, title, description, illustration, className, onWizardFinished = () => {} } = this.props
        const { topics, activeTopic, minimized, show, currentStep, businessType, sharedTasks, tasks, users , user} = this.state

        return (
            <div className={`sc-wizard-container ${show ? 'show' : 'hidden'}  ${minimized ? 'minimized' : ''} ${position}`}>
                <div className={`sc-wizard-overlay ${show && !minimized ? 'show' : 'hidden'}`} />
                <div className={`sc-wizard-content d-flex flex-column ${show ? 'show' : ''} ${className ? className : ''} ${position}`}>
                    <div className="sc-wizard-header">
                        {/*<h3 
                                className={`text-center ${minimized ? 'cursor-pointer' : ''}`}
                                onClick={minimized ? this.handleMinimizeHelp : null}
                                >Assistente de configuração do Minzi</h3>*/}
                        <div className="wizard-steps">
                            <ul>
                                <li className={`${currentStep === 1 ? 'current' : currentStep > 1 ? 'passed' : ''}`}>1</li>
                                <li className={`${currentStep === 2 ? 'current' : currentStep > 1 ? 'passed' : ''}`}>2</li>
                                <li className={`${currentStep === 3 ? 'current' : currentStep > 3 ? 'passed' : ''}`}>3</li>
                                <li className={`${currentStep === 4 ? 'current' : currentStep > 4 ? 'passed' : ''}`}>4</li>
                                {/* <li className={`${currentStep === 5 ? 'current' : currentStep > 5 ? 'passed' : ''}`}>5</li> */}
                            </ul>
                        </div>

                    </div>
                    <div className="steps flex-grow-1">
                        {
                            // currentStep === 1 ?
                            //     <div className="step flex-grow-1 h-100 p-1 text-center">
                            //         <h3>Olá, seja bem-vindo(a) ao Minzi!</h3>
                            //         <p>O assistente de configurações irá ajudá-lo(a) a configurar o seu produto.</p>
                            //         <hr />
                            //         <h4>Para começar, qual é o nome da sua empresa?</h4>
                            //         <div>
                            //             <input placeholder="Exemplo: Inlog" className="form-control" />
                            //         </div>
                            //     </div>
                            // : 
                            // currentStep === 1 ?
                            //     <div className="step flex-grow-1 h-100 p-1 text-center">
                            //         <h3>É uma empresa de projetos ou de serviços</h3>
                            //         <p>Você pode trabalhar com os dois modelos simultâneamente. Mas nesse momento, iremos ajudá-lo a configurar as tarefas mais comuns para o seu modelo de negócio.</p>
                            //         <hr />
                            //         <h4>Qual a principal finalidade da sua empresa?</h4>
                            //         <div className="detailed-options">
                            //             <ul>
                            //                 <li onClick={() => this.selectBusinessType('project')} className={`${businessType === 'project' ? 'selected' : ''}`}>
                            //                     Minha empresa <b>trabalha com projetos</b>, como uma fábrica de softwares, engenharia, marketing e qualquer segmento em que as <b>tarefas tem um início e um fim</b>.
                            //                     {businessType === 'project' && <div className="option-checked"><Check size={15} /></div>}
                            //                 </li>
                            //                 <li onClick={() => this.selectBusinessType('sector')} className={`${businessType === 'sector' ? 'selected' : ''}`}>
                            //                     Minha empresa <b>trabalha com atividades recorrentes</b>, como uma contabilidade, escritórios de serviços administrativos e qualquer segmento com <b>tarefas sem tempo de expiração</b>.
                            //                     {businessType === 'sector' && <div className="option-checked"><Check size={15} /></div>}
                            //                 </li>
                            //             </ul>
                            //         </div>
                            //         <div class="d-flex justify-content-center mt-1 mb-1 text-center">
                            //             <small>Daqui a pouco iremos adicionar setores ou projetos.</small>
                            //         </div>
                            //     </div>
                            // : currentStep === 2 ?
                            //         <div className="step flex-grow-1 h-100 p-1 mb-1 text-center">
                            //             <h3>Adicione um colaborador</h3>
                            //                 <p>Depois, você poderá adicionar novos ou editar este.</p>
                            //             <hr />
                            //             <p>Precisamos apenas do nome, email e senha</p>
                            //             <div className="mb-1">
                            //                 <Row>
                            //                     <Col><input placeholder="Nome" className="form-control" /></Col>
                            //                 </Row>
                            //                 <Row>
                            //                     <Col lg="9"><input placeholder="E-mail" className="form-control mt-2" /></Col>
                            //                     <Col lg="3"><input placeholder="Senha" className="form-control mt-2" /></Col>
                            //                     {/*<Col lg="2">
                            //                         <div className="mt-2">
                            //                             <Button.Ripple color="primary" block onClick={this.handlePrevPage} style={{paddingTop: "10px", paddingBottom: "10px", width: "100%"}}>
                            //                                 <Plus size={15} />
                            //                             </Button.Ripple>
                            //                         </div>
                            //                     </Col>*/}
                            //                 </Row>
                            //                 <Row>
                            //                     <Col>
                            //                         <div className="d-flex justify-content-center mt-1 mb-1 text-center">
                            //                             <small>Guarde a senha em um local seguro. O colaborador precisará dela para acessar o Minzi.</small>
                            //                         </div>
                            //                     </Col>
                            //                 </Row>

                            //             </div>
                            //         </div>

                            currentStep === 1 ?
                                <div className="step flex-grow-1 h-100 p-1 text-center">
                                    <h3>{businessType === 'project' ? "Adicione o seu primeiro projeto" : "Adicione um setor"}</h3>
                                    <p>{businessType === 'project' ? "Um projeto, agrupa um conjunto de atividades que tem um início e término definidos" : "O financeiro de uma empresa, é um exemplo de setor, onde todos os meses, as tarefas de repetem"}</p>
                                    <hr />
                                    <h4>{businessType === 'project' ? "Precisamos de apenas algumas informações:" : "Qual o nome do primeiro setor que gostaria de adicionar?"}</h4>
                                    {businessType === 'project' ?
                                        <div className="mt-1">
                                            <Row>
                                                <Col>
                                                    <input placeholder="Nome do projeto" className="form-control"  value={this.state.projectName}  onChange={evt => {
                                                            this.setState({ projectName: evt.target.value });
                                                        }} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6" className="text-left mt-2">
                                                    <Flatpickr
                                                        options={{ dateFormat: "d/m/Y" }}
                                                        className="form-control"
                                                        value={this.state.start}
                                                        options={{ minDate: "today", dateFormat: "d/m/Y", locale: "pt" }}
                                                        onChange={date => {
                                                            this.setState({ start: date[0] });
                                                        }}
                                                    />
                                                    <label>Início (opcional)</label>
                                                </Col>
                                                <Col lg="6" className="text-left mt-2">
                                                    <Flatpickr
                                                        options={{ dateFormat: "d/m/Y" }}
                                                        className="form-control"
                                                        value={this.state.end}
                                                        options={{ minDate: new Date().fp_incr(1), dateFormat: "d/m/Y", locale: "pt" }}
                                                        onChange={date => {
                                                            this.setState({ end: date[0] });
                                                        }}
                                                    />
                                                    <label>Término previsto (opcional)</label>
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        <input placeholder="Exemplo: Financeiro" className="form-control" />
                                    }
                                </div>
                                :
                                currentStep === 2 ?
                                    <div className="step flex-grow-1 h-100 p-1 text-center">
                                        <h3>Adicione pelo menos uma tarefa</h3>
                                        <p>As tarefas adicionadas aqui poderão ser selecionadas pelos colaboradores e, então, inicar a contagem dos tempos.</p>
                                        <hr />
                                        <Row>
                                            <Col>
                                                <input
                                                    placeholder="Reunião com a equipe ou Processamento de folha de pagamento"
                                                    className="form-control"
                                                    value={this.state.typeTask}
                                                    onChange={e => this.setState({ typeTask: e.target.value })}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10}>
                                                <div className="detailed-options mt-1">
                                                    <ul>
                                                        <li onClick={() => this.selectSharedTasks()} className={`${sharedTasks ? 'selected' : ''}`}>
                                                            Usarei essa tarefa em muitos projetos ou setores*.
                                                        {sharedTasks && <div className="option-checked"><Check size={15} /></div>}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div className="mt-1">
                                                    <Button color="primary" onClick={this.handleAddTask} block style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                                        <Plus size={15} />
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="table">
                                                    <ul>
                                                        {tasks && tasks.length > 0 ? tasks.map(m =>
                                                            <li key={m.id}>
                                                                {m.shared ? <div className="shared"><Check size={10} /></div> : <div className="shared off"><Check size={10} /></div>}
                                                                <span className="flex-grow-1">{m.task}</span>
                                                                <div className="remove text-danger cursor-pointer" onClick={() => this.handleRemoveTask(m.id)}><X size={15} /></div>
                                                            </li>
                                                        ) :
                                                            <li>
                                                                <span className="flex-grow-1">As tarefas que você adicionar, serão apresentadas aqui.</span>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="d-flex justify-content-center mt-50 mb-1 text-center">
                                                    <small>*Existem tarefas que são praticamente universais em determinadas empresas, como por exemplo, reunião com a equipe, desenvolvimento front-end e formulação de relatórios. Nesse caso, selecione a opção acima para que essa tarefa seja adicionada automaticamente a cada novo setor ou projeto criado. Você poderá removê-los quando desejar.</small>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                    : currentStep === 3 ? <div className="step flex-grow-1 h-100 p-1 text-center">
                                        <h3>Adicione usuários ao projeto</h3>
                                        <p>Você pode adicionar usuários nos seus projetos</p>
                                        {!users || users.length < 2 && <Row>
                                            <Col lg={5}>
                                                <input
                                                    placeholder="Membro da equipe do projeto"
                                                    className="form-control mb-1"
                                                    value={user.nome}
                                                    onChange={e => this.setState({ user: { ...this.state.user, nome: e.target.value } })}
                                                />
                                            </Col>
                                            <Col lg={7}>
                                                <input
                                                    placeholder="E-mail"
                                                    className="form-control"
                                                    value={user.email}
                                                    onChange={e => this.setState({ user: { ...this.state.user, email: e.target.value } })}
                                                />
                                                <small className="danger">{user.email.length > 4 && !emailIsValid(user.email) ? 'E-mail informado não é válido!': ''}</small>
                                            </Col>
                                            <Col lg={5}>
                                                <input
                                                    type="password"
                                                    placeholder="Senha"
                                                    className="form-control"
                                                    value={user.senha}
                                                    onChange={e => this.setState({ user: { ...this.state.user, senha: e.target.value } })}
                                                />
                                            </Col>
                                            <Col lg={5}>
                                                <input
                                                    type="password"
                                                    placeholder="Confirmação de senha"
                                                    className="form-control"
                                                    value={user.confirmacaoSenha}
                                                    onChange={e => this.setState({ user: { ...this.state.user, confirmacaoSenha: e.target.value } })}
                                                />
                                            </Col>
                                            <Col lg={2}>
                                                <div className="mt-0">
                                                    <Button color="primary" disabled={!user.senha || !user.confirmacaoSenha || (user.confirmacaoSenha !== user.senha) || !emailIsValid(user.email)} onClick={this.handleAddUser} block style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                                        <Plus size={15} />
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                               {(user.senha && user.confirmacaoSenha) && user.senha !== user.confirmacaoSenha && <div className="text-center">
                                                    <small className="danger">A senha e a confirmação de senha não conferem</small>
                                                </div>}
                                            </Col>
                                        </Row>}
                                        <Row>
                                            <Col>
                                                <div className="table">
                                                    <ul>
                                                        {users && users.length > 0 ? users.map(usr =>
                                                            <li key={usr.id}>
                                                            <span className="flex-grow-1">{usr.nome} ({usr.email})</span>
                                                                <div className="remove text-danger cursor-pointer" onClick={() => this.handleRemoveUser(usr.id)}><X size={15} /></div>
                                                            </li>
                                                        ) :
                                                            <li>
                                                                <span className="flex-grow-1">Os nomes dos usuários adicionados serão apresentados aqui.</span>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                        :
                                        <div className="step flex-grow-1 h-100 p-1 text-center">
                                            <h3>O Minzi está pronto!</h3>
                                            <p>Suas configurações foram salvas e você já pode começar a usar o sistema.</p>
                                            <hr />
                                            <p>Podemos lhe apresentar o produto, para você saber onde acessar cada recurso:</p>
                                            <div className="detailed-options">
                                                <ul>
                                                    <li onClick={() => this.selectBusinessType('project')} className={`${businessType === 'project' ? 'selected' : ''}`}>
                                                        Aprender, em apenas 3 minutos, as principais funcionalidades do Minzi.<br />(Essa janela será fechada e iniciaremos uma apresentação interativa)
                                                {businessType === 'project' && <div className="option-checked"><Check size={15} /></div>}
                                                    </li>
                                                    <li onClick={() => this.selectBusinessType('sector')} className={`${businessType === 'sector' ? 'selected' : ''}`}>
                                                        Estou sem tempo, irei ver isso depois.<br />(Essa janela será fechada e redirecionar par a módulo principal do sistema)
                                                {businessType === 'sector' && <div className="option-checked"><Check size={15} /></div>}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                        }
                    </div>
                    {currentStep < 5 &&
                        <div className="sc-wizard-footer d-flex justify-content-between p-50">
                            <div>
                                {currentStep > 1 &&
                                    <Button.Ripple color="flat-dark" onClick={this.handlePrevPage}>
                                        <div className="d-inline-block d-none align-middle">
                                            <ChevronLeft size={15} /> <span>Anterior</span>
                                        </div>
                                    </Button.Ripple>
                                }
                            </div>

                            <Button.Ripple disabled={
                                currentStep === 1 && (!this.state.projectName || this.state.projectName.length < 4) ||
                                currentStep === 2 && (!this.state.tasks || this.state.tasks.length < 1) ||
                                currentStep === 3 && (!this.state.users || this.state.users < 1) 

                                } color="primary" onClick={this.handleNextPage}>
                                <div className="d-inline-block d-none align-middle">
                                    <span>{currentStep === 4 ? "Finalizar" : "Próximo"}</span> <ChevronRight size={15} />
                                </div>
                            </Button.Ripple>

                        </div>
                    }
                    <div className="sc-wizard-tools d-flex">
                        <div className="sc-wizard-minus" onClick={this.handleMinimizeHelp}>
                            {minimized ? <Plus /> : <Minus />}
                        </div>
                        <div className="sc-wizard-close" onClick={this.handleOpenWizard}>
                            <X />
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Wizard