import {del, get, post, put} from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/workPlan`
}

export const Status = () => {
    return get(`${getUrl()}/status`)
}

export const Save = (id, data) => {
    return put(`${getUrl()}/${id}`, data)
}

export const Column = (id) => {
    return get(`${getUrl()}/${id}/column`)
}

export const PlansAvailable = () => {
    return get(`${getUrl()}/list`)
}

export const Me = () => {
    return get(`${getUrl()}/me`)
}

export const AddColumn = (data) => {
    return post(`${getUrl()}/column`, data)
}

export const Delete = (id) => {
    return del(`${getUrl()}/${id}`)
}

export const Copy = (id) => {
    return post(`${getUrl()}/${id}/copy`)
}

export const UpdatePlanFields = (id, data) => {
    return put(`${getUrl()}/${id}/planFields`, data)
}

export const ChangeMyWorkplan = (id)=>{
    return put(`${getUrl()}/${id}/change`, id)
}

export const UpdateName = (id, data) => {
    return put(`${getUrl()}/column/${id}/name`, data)
}