import { ToastError } from "./ToastMessages"
import { history } from "../history"

function errorHandle (error) {
    if(error && error.response && error.response.data) {
        let errorMessage = error && error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Ocorreu um erro ao processar sua solicitação'
        return {
            message: errorMessage,
            code: error.response.status
        }    
    } else {
        return {
            message: 'Ocorreu um erro ao processar sua solicitação, atualize a tela e tente novamente',
            code: 0
        }    
    }

}
export {
    errorHandle
}