import { del, get, post, put } from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/sprint`
}

export const AddPersonalCard = (sprintID, cardID) => {
    return post(`${getUrl()}/${sprintID}/addPersonalCard/${cardID}`)
}

export const Create = (data) => {
    return post(`${getUrl()}/create`, data)
}

export const Global = (startDate, endDate) => {
    if (!startDate || !endDate)
        return get(`${getUrl()}/global`)
    return get(`${getUrl()}/global?startDate=${startDate}&endDate=${endDate}`)
}

export const FindByID = (id) => {
    return get(`${getUrl()}/${id}/details`)
}


export const Burndown = (id) => {
    return get(`${getUrl()}/${id}/insight/burndown`)
}

export const Burnup = (id) => {
    return get(`${getUrl()}/${id}/insight/burnup`)
}

export const PlanningByPeople = (id, filter) => {
    if (!filter) filter = {}
    return post(`${getUrl()}/${id}/insight/planningByPeople`, filter)
}

export const DeviationByPeople = (id, filter) => {
    if (!filter) filter = {}
    return post(`${getUrl()}/${id}/insight/deviationByPeople`, filter)
}

export const DeviationByTags = (id, filter) => {
    if (!filter) filter = {}
    return post(`${getUrl()}/${id}/insight/deviationByTags`, filter)
}

export const WorkedTagsByPerson = (id, filter) => {
    if (!filter) filter = {}
    return post(`${getUrl()}/${id}/insight/workedTagsByPerson`, filter)
}

export const DeviationTagsByProject = (id, filter) => {
    if (!filter) filter = {}
    return post(`${getUrl()}/${id}/insight/deviationTagsByProject`, filter)
}

export const PlanningWorkedTagsByProject = (id, filter) => {
    if (!filter) filter = {}
    return post(`${getUrl()}/${id}/insight/planningWorkedTagsByProject`, filter)
}

export const PlanningByPeopleAndProject = (id, filter) => {
    if (!filter) filter = {}
    return post(`${getUrl()}/${id}/insight/planningByPeopleAndProject`, filter)
}

export const ProjectByTags = (id, filter) => {
    if (!filter) filter = {}
    return post(`${getUrl()}/${id}/insight/projectByTags`, filter)
}

export const Info = (id) => {
    return get(`${getUrl()}/${id}/insight/info`)
}

export const GlobalFinished = (offset) => {
    return get(`${getUrl()}/global/finished/${offset}`)
}

export const Active = () => {
    return get(`${getUrl()}/active`)
}

export const Filter = (data) => {
    return post(`${getUrl()}/filter`, data)
}

export const Me = () => {
    return get(`${getUrl()}/me`)
}

export const ByProject = (id) => {
    return get(`${getUrl()}/project/by/${id}`)
}

export const SprintsOpen = (id) => {
    return get(`${getUrl()}/project/${id}/open`)
}

export const FromProject = (id) => {
    return get(`${getUrl()}/project/from/${id}`)
}

export const FromProjectFinishedSprint = (id, offset) => {
    return get(`${getUrl()}/project/from/${id}/${offset}/finished`)
}

export const Cards = (id) => {
    return get(`${getUrl()}/${id}/cards`)
}

export const Update = (id, data) => {
    return put(`${getUrl()}/${id}`, data)
}

export const UpdateParticipantDayOff = (id, data) => {
    return put(`${getUrl()}/${id}/participant/dayOff`, data)
}

export const Resume = (id) => {
    return get(`${getUrl()}/${id}/resume`)
}

export const Participants = (id, data) => {
    return put(`${getUrl()}/${id}/participants`, data)
}

export const Listeners = (id, data) => {
    return put(`${getUrl()}/${id}/listeners`, data)
}

export const Delete = (id) => {
    return del(`${getUrl()}/${id}`)
}

export const Start = (id, data) => {
    return post(`${getUrl()}/${id}/start`, data)
}

export const Finish = (id, data) => {
    return post(`${getUrl()}/${id}/finish`, data)
}