const initialState = {
    trackingEvent: null,
  }
  
  const trackingEvent = (state = initialState, action) => {
    switch (action.type) {
      case "TRACKING_EVENTS":
        return { ...state, trackingEvent: action.data }
    case "TRACKING_EVENT_REMOVE":
        return { ...state, trackingEvent: null }
      default:
        return { ...state }
    }
  }
  
  export default trackingEvent
  