import React, { Component } from 'react'
import { ModalHeader } from '@atlaskit/modal-dialog';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import ValidationMessage from '../../../../../utility/Validation';
import { DatePicker } from '@atlaskit/datetime-picker';
import ButtonAtl from '@atlaskit/button';
import Select from '@atlaskit/select';
import { Querys } from '../../../../../database/base';
import ModalSuricatoo from '../../../../../utility/ModalSuricatoo';

const defaultValue = {
    id: 0,
    name: '',
    start: new Date().toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0]
}

export default class SprintCreateUpdate extends Component {
    state = {
        ...defaultValue,
        dirty: {},
        required: ['name', 'key', 'companyID', 'start'],
        errors: {},
        load: true,
        globalError: '',
        titleError: '',
        duration: { label: '2 semana', value: 2 },
        open: false
    }

    udpateValue = (name, values) => {
        const value = {
            ...values,
            dirty: { ...this.state.dirty, [name]: true },
            errors: { ...this.state.errors, [name]: { error: false } }
        }
        this.setState({ ...value })
    }

    componentDidMount() {
        var date = new Date()
        date.setDate(date.getDate() + this.state.duration.value * 7)
        this.setState({ end: date.toISOString().split('T')[0] })
    }

    componentDidUpdate() {
        if (this.state.open !== this.props.open && this.props.open && this.props.id) {

            let start = new Date(this.props.sprint.start)
            let end = new Date(this.props.sprint.end)
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            let date = new Date()
            date.setDate(date.getDate() + diffDays)

            this.setState({
                open: this.props.open,
                name: this.props.sprint?.name ?? '',
                start: this.props.sprint?.start ?? new Date().toISOString().split('T')[0],
                end: this.props.sprint?.end ?? date.toISOString().split('T')[0],
                duration: { label: diffDays / 7 < 5 ? diffDays / 7 + ' semana' : 'personalizado', value: diffDays / 7 < 5 ? diffDays / 7 : 0 }
            })
        }
    }

    create = () => {
        if (this.props.id)
            Querys.Sprint.Update(this.props.id, { ...this.state, projectID: this.props.projectID })
                .then(() => {
                    this.props.toggleModal()
                    this.props.onSprintCreated()
                })
        else
            Querys.Sprint.Create({ ...this.state, projectID: this.props.projectID })
                .then(() => {
                    this.props.toggleModal()
                    this.props.onSprintCreated()
                })
    }

    render() {
        return (
            <ModalSuricatoo
                open={this.props.open}
                onClose={this.props.toggleModal}
                header={this.props.id ? 'Editar sprint' : 'Criar sprint'}
                footer={(
                    <div className='d-flex justify-content-between mt-2'>
                        <div></div>
                        <div>
                            <ButtonAtl appearance='primary' className='atl-button mr-1' onClick={this.create}>{this.props.id ? 'Atualizar' : 'Criar'}</ButtonAtl>
                            <ButtonAtl appearance='default' className='atl-button' onClick={this.props.toggleModal}>Cancelar</ButtonAtl>
                        </div>
                    </div>
                )}>
                <Field label="Nome" name="name" isRequired>
                    {({ fieldProps }) => (
                        <React.Fragment>
                            <Textfield
                                {...fieldProps}
                                value={this.state.name === null ? '' : this.state.name}
                                onChange={e => {
                                    const name = e.target.value
                                    this.udpateValue(fieldProps.name, { name })
                                }}
                                placeholder="nome da sprint" />
                            <ValidationMessage
                                {...this.state}
                                {...fieldProps}
                                state={this.state}
                                defaultValue={defaultValue}
                                message='Nome é obrigatório' />
                        </React.Fragment>
                    )}
                </Field>
                <Field label="Duração" name="start" isRequired>
                    {({ fieldProps }) => (
                        <React.Fragment>
                            <Select
                                value={this.state.duration}
                                defaultValue={this.state.duration}
                                className="w-100"
                                options={[
                                    { label: '1 semana', value: 1 },
                                    { label: '2 semana', value: 2 },
                                    { label: '3 semana', value: 3 },
                                    { label: '4 semana', value: 4 },
                                    { label: 'personalizado', value: 0 },
                                ]}
                                onChange={e => {
                                    var date = new Date()
                                    date.setDate(date.getDate() + e.value * 7)
                                    this.setState({ duration: e, end: date.toISOString().split('T')[0] })
                                }}
                            />
                        </React.Fragment>
                    )}
                </Field>
                <Field label="Data de início" name="start" isRequired>
                    {({ fieldProps }) => (
                        <React.Fragment>
                            <DatePicker
                                {...fieldProps}
                                locale='pt-BR'
                                value={this.state.start}
                                onChange={e => {
                                    var date = new Date(e)
                                    date.setDate(date.getDate() + this.state.duration.value * 7)
                                    this.udpateValue(fieldProps.name, { start: e, end: date.toISOString().split('T')[0] })
                                }} />
                            <ValidationMessage
                                {...this.state}
                                {...fieldProps}
                                state={this.state}
                                defaultValue={defaultValue}
                                message='Data de inicio é obrigatório' />
                        </React.Fragment>
                    )}
                </Field>
                <Field label="Data de fim" name="end" isRequired isDisabled={this.state.duration.value !== 0}>
                    {({ fieldProps }) => (
                        <React.Fragment>
                            <DatePicker
                                {...fieldProps}
                                locale='pt-BR'
                                value={this.state.end}
                                onChange={e =>
                                    this.udpateValue(fieldProps.name, { end: e })
                                } />
                            <ValidationMessage
                                {...this.state}
                                {...fieldProps}
                                state={this.state}
                                defaultValue={defaultValue}
                                message='Data de fim é obrigatório' />
                        </React.Fragment>
                    )}
                </Field>
            </ModalSuricatoo>
        )
    }
}
