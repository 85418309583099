export const trackingEvent = (data,event) => {
  return dispatch => {
    dispatch({
      type: "TRACKING_EVENTS",
      data: {
        event: event,
        data: data.data
      }
    })
  }
}

export const trackingEventRemove = () => {
  return dispatch => {
    dispatch({
      type: "TRACKING_EVENT_REMOVE",
      data: null
    })
  }
}