import React, { Component } from 'react'
import ModalSuricatoo from '../../../../../utility/ModalSuricatoo'
import Button from '@atlaskit/button'

export default class ConfirmStartSprint extends Component {
    render() {
        return (
            <ModalSuricatoo
                autoFocus={false}
                open={this.props.open}
                header='Confirma iniciar sprint?'
                footer={<>
                    <Button appearance="subtle" className='atl-button' onClick={this.props.onCancel}>
                        Cancelar
                    </Button>
                    <Button appearance="primary" className='atl-button' onClick={this.props.onConfirm}>
                        Confirmar
                    </Button>
                </>}>
                <div>Deseja realmente iniciar a sprint: <b>{this.props.sprint.name}</b></div>
                <br />
                <div>Data inicial: <b>{this.props.sprint.startFormated}</b></div>
                <div>Data final: <b>{this.props.sprint.endFormated}</b></div>
            </ModalSuricatoo>
        )
    }
}
