import { del, get, post, put } from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/card`
}

export const ProjectBacklod = (projectID) => {
    return get(`${getUrl()}/project/${projectID}/backlog`)
}

export const PersonBacklod = (done) => {
    return get(`${getUrl()}/person/backlog/${done}`)
}

export const PersonBoard = (done) => {
    return get(`${getUrl()}/person/board/${done}`)
}

export const History = (id) => {
    return get(`${getUrl()}/${id}/history`)
}

export const Share = (id, data) => {
    return post(`${getUrl()}/${id}/share`, data)
}

export const Delete = (id) => {
    return del(`${getUrl()}/${id}`)
}

export const RoolbackToBacklog = (id) => {
    return put(`${getUrl()}/${id}/roolbackToBacklog`)
}

export const Clone = (id) => {
    return post(`${getUrl()}/${id}/clone`)
}

export const Filter = (filter, project) => {
    return post(`${getUrl()}/filter/by`, { filter, project })
}

export const Attachment = (id, data) => {
    return post(`${getUrl()}/${id}/attachment`, data)
}

export const Import = (destiny, data) => {
    return post(`${getUrl()}/${destiny}/import`, data)
}

export const ImportValidate = (data) => {
    return post(`${getUrl()}/import/validate`, data)
}

export const VerifyCreateActivity = (cardID, projectID) => {
    return post(`${getUrl()}/${cardID}/verifyCreateActivity/${projectID}`)
}

export const Kanban = () => {
    return get(`${getUrl()}/user/kanban`)
}

export const AttachmentComment = (id, data) => {
    return post(`${getUrl()}/comment/${id}/attachment`, data)
}

export const AttachmentDelete = (id, contentId) => {
    return del(`${getUrl()}/attachment/${id}/${contentId}`)
}

export const HistoryDescription = () => {
    return get(`${getUrl()}/history/description`)
}

export const FieldName = () => {
    return get(`${getUrl()}/fields/name`)
}

export const GetByID = (id) => {
    return get(`${getUrl()}/${id}`)
}

export const ContentFile = (cardKey, id, fileType) => {
    return get(`${getUrl()}/file/${cardKey}/${id}/content?fileType=${fileType}`)
}

export const Create = (data) => {
    data.projectValue = null
    return post(`${getUrl()}/create`, data)
}

export const UpdateFields = (id, data) => {
    return put(`${getUrl()}/${id}/fields`, data)
}

export const UpdateTags = (id, data) => {
    return put(`${getUrl()}/${id}/tags`, data)
}

export const Rank = (id, data) => {
    return put(`${getUrl()}/${id}/rank`, data)
}

export const Rankv2 = (data) => {
    return put(`${getUrl()}/rank`, data)
}

export const Board = (id, data) => {
    return put(`${getUrl()}/${id}/board`, data)
}

export const GenerateReport = (data) => {
    return post(`${getUrl()}/export`, data)
}

export const CommentSave = (id, data) => {
    return post(`${getUrl()}/${id}/comment`, data)
}

export const CommentDelete = (id) => {
    return del(`${getUrl()}/comment/${id}`)
}

export const CommentList = (id) => {
    return get(`${getUrl()}/${id}/comment`)
}

export const ChangeDescription = (id, data) => {
    return put(`${getUrl()}/${id}/description`, data)
}

export const EmojiAdd = (id, data) => {
    return post(`${getUrl()}/comment/${id}/emoji`, data)
}

export const EmojiRemove = (id, unified) => {
    return put(`${getUrl()}/comment/emoji/${id}/${unified}`)
}