import { del, get, post, put } from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/project`
}

export const KeyHaveExists = (key) => {
    return get(`${getUrl()}/${key}/exists`)
}

export const GetByID = (id) => {
    return get(`${getUrl()}/${id}/byId`)
}

export const Tags = (id) => {
    return get(`${getUrl()}/${id}/tags`)
}

export const People = (id) => {
    return get(`${getUrl()}/${id}/people`)
}


export const List = () => {
    return get(`${getUrl()}/list`)
}

export const ListBySector = (id, archived, loadAllProjects) => {
    return get(`${getUrl()}/sector/${id}/${archived}?loadAllProjects=${loadAllProjects}`)
}

export const ListAll = () => {
    return get(`${config.RouterUrl}/api/project/list/all`)
}

export const Create = (data) => {
    return post(`${getUrl()}/create`, data)
}

export const Update = (id, data) => {
    return put(`${getUrl()}/${id}/update`, data)
}

export const UpdatePeople = (id, people) => {
    return put(`${getUrl()}/${id}/people`, people)
}

export const ProjectsPerson = (person) => {
    return get(`${config.RouterUrl}/api/back/pessoa/${person}/carregarprojetos`)
}

export const Todos = (projectID) => {
    //https://router-staging.suricatoo.com/api/project/12/tasks/all
    return get(`${config.RouterUrl}/api/project/${projectID}/tasks/all`)
}

export const Delete = (id) => {
    return del(`${config.RouterUrl}/api/project/${id}`)
}

export const Archive = (id) => {
    return put(`${config.RouterUrl}/api/project/${id}/archive`)
}

export const Unarchive = (id) => {
    return put(`${config.RouterUrl}/api/project/${id}/unarchive`)
}

export const UpdateFields = (id, data) => {
    return put(`${getUrl()}/${id}/fields`, data)
}
