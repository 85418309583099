import React, { Component } from 'react'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import AvatarPessoa from "../../../../../utility/AvatarPessoa"
import Textfield from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search'
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear'
import Button from '@atlaskit/button';

export default class DropdownPeopleSelect extends Component {
    state = {
        id: 0,
        name: 'Não atribuido',
        search: ''
    }
    componentWillMount() {
        this.setState({ ...this.props })
    }
    render() {
        let data = this.props.data
        if (data instanceof Function) {
            data = data()
        }
        return (
            <DropdownMenu
                placement={'left-start'}
                trigger={({ triggerRef, testId, isSelected, ...props }) => (
                    <div ref={triggerRef} {...props} className='dropdown-person-menu d-flex justify-content-between'>
                        <div className='d-flex margin-auto'>
                            <AvatarPessoa size='md' codigo={this.state.id} nome={this.state.name} noTooltip={this.props.noTooltip} />
                            {this.props.noTooltip && <span className='dropdown-select-name margin-auto'>{this.state.name}</span>}
                        </div>
                        {this.props.removeResponsable &&
                            <div className='margin-auto'>
                                <Button iconBefore={<SelectClearIcon size='small' />}
                                    onClick={() => {
                                        this.setState({
                                            ...this.state, ...{
                                                id: 0,
                                                name: 'Não atribuido',
                                                search: ''
                                            }
                                        })
                                        if (this.props.onUserSelected) this.props.onUserSelected(null)
                                    }}
                                    appearance="subtle">
                                </Button>
                            </div>}

                    </div>
                )}>
                <DropdownItemGroup>
                    <Textfield
                        autoFocus={true}
                        className='input-search-dropdown-people'
                        value={this.state.search}
                        onChange={e => this.setState({ search: e.target.value })}
                        elemBeforeInput={
                            <div className='search-icon'>
                                <SearchIcon size='small'></SearchIcon>
                            </div>
                        } />
                    {this.state.id > 0 && <div className='remove-select'>
                        <Button shouldFitContainer onClick={()=>{
                            this.setState({ ...this.state, id: 0, name: 'Não atribuido' })
                            if (this.props.onUserSelected) this.props.onUserSelected(null)
                        }}>Remover Selecionado</Button>
                    </div>}
                    <div className='dropdown-people-select'>
                        {data
                            .filter(x =>
                                x.name.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0 ||
                                x.email.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0)
                            .map((item, index) => (
                                <DropdownItem
                                    onClick={() => {
                                        this.setState({ ...this.state, ...item })
                                        if (this.props.onUserSelected) this.props.onUserSelected(item.id)
                                    }}
                                    key={`dropdown-people-${item.id}`}>
                                    <div className='d-flex'>
                                        <div className='avatar-person-dropdown'>
                                            <AvatarPessoa size='md' noTooltip codigo={item.id} />
                                        </div>
                                        <div>
                                            <b>{item.name}</b>
                                            <br />
                                            <small>{item.email}</small>
                                        </div>
                                    </div>
                                </DropdownItem>
                            ))}
                    </div>
                </DropdownItemGroup>
            </DropdownMenu>
        )
    }
}
