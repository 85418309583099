import React from 'react'
import moment from 'moment-timezone'
import { calcTime, formatHoursMinutes } from "./timeLegend"

const timeround = (start, end) => {
    let GMT = moment.parseZone(start).toString().split('GMT')[1]
    GMT = GMT && `${GMT.substr(0, 3)}:${GMT.substr(GMT.length - 2)}`
    const split = end && end.split('T')[1] && end.split('T')[1].substring(0,5) === '23:59' ? true : false
    const roundedStart = start ? parseInt(moment.parseZone(start).format('ss')) > 30 ? moment.parseZone(start).startOf('minute').add(1, 'minute').format(`YYYY-MM-DDTHH:mm:ss${GMT}`) : moment.parseZone(start).startOf('minute').format(`YYYY-MM-DDTHH:mm:ss${GMT}`) : null
    const roundedEnd = !end ? moment.parseZone().tz(moment.tz.guess()).format(`YYYY-MM-DDTHH:mm:ss${GMT}`) : split ?  moment.parseZone(end).startOf('minute').format(`YYYY-MM-DDTHH:mm:ss${GMT}`) : end ? parseInt(moment.parseZone(end).format('ss')) > 30 ? moment.parseZone(end).startOf('minute').add(1, 'minute').format(`YYYY-MM-DDTHH:mm:ss${GMT}`) : moment.parseZone(end).startOf('minute').format(`YYYY-MM-DDTHH:mm:ss${GMT}`) : null
    return {
        start: roundedStart,
        end:  roundedEnd,
        //diff: Math.round(moment(roundedEnd).diff(roundedStart, 'seconds') / 60),
        //start: start,
        //end:  !end ? moment.parseZone().tz(moment.tz.guess()).format(`YYYY-MM-DDTHH:mm:ss${GMT}`) : end,
        diff: Math.round(moment(!end ? moment.parseZone().tz(moment.tz.guess()).format(`YYYY-MM-DDTHH:mm:ss${GMT}`) : end ).diff(start, 'seconds') / 60),
        gmt: GMT,
        running: !end ? true : false
    }
}

const localeGmt = () => {
  const gmtOffset = moment().utcOffset()
  const gmtTohours = gmtOffset < 0 ? -gmtOffset : gmtOffset
  
  return `${gmtOffset < 0 ? '-' : ''}${gmtTohours < 600 ? '0' : ''}${formatHoursMinutes(gmtTohours)}`


}

export {
  timeround,
  localeGmt
}