const INITIAL_STATE = {
    tempo: null
}

export const atualizacao = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "UPDATE_CLOCK": {
            const { tarefa, tempo } = action.data
            return {
                ...state,
                tempo: { tarefa, tempo }
            }
        }
        default: {
            return state
        }
    }
}
