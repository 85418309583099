import React, { Component } from 'react'
import Axios from 'axios'
import url from '../../../Config'
import {
    Button, Badge, UncontrolledTooltip, ButtonGroup, Tooltip as Tooltip2, Spinner
} from 'reactstrap'
import { ToastError } from '../../../utility/ToastMessages'
import * as Icon from "react-feather"
import BaseComponentSuricatoo, { Translate } from '../../../utility/BaseComponentSuricatoo'
import { injectIntl, FormattedMessage } from 'react-intl'
import { toDate } from './Utils'
import If from '../../../utility/If'
import { connect } from 'react-redux'
import * as loading from "./lf30_editor_BSrgKO.json";
import Lottie from 'react-lottie'
import { Link } from 'react-router-dom'
import Checkbox from "../../../components/@vuexy/checkbox/CheckboxesVuexy"
import { TimerUtils } from '../../../utility/TimerConvertUtils'
import { secondsToTime } from '../../../utility/timeLegend'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import InputMask from "react-input-mask"
import moment from 'moment'
import Label from 'reactstrap/lib/Label'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import Input from 'reactstrap/lib/Input'
import TimerCount from './NewTimeCount'
import { Querys } from '../../../database/base'
import ButtonAtl from '@atlaskit/button';
import { onStartActivityAfterTodoID, onStopActivityAfterTodoID } from '../../../redux/reducers/kanban/kanbanAction'
let running = 0

const homeLottie = {
    loop: true,
    autoplay: true,
    animationData: loading.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
const homeLottieStoped = {
    isStopped: false,
    isPaused: false,
    loop: false,
    autoplay: false,
    animationData: loading.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    },
    tooltipOpen: null
};

class TimerTarefa extends BaseComponentSuricatoo {
    state = {
        task: {},
        horaInicial: parseInt(new Date().getTime() / 1000),
        timeInicial: new Date(),
        time: new Date(),
        modalObservacao: false,
        observacao: [],
        popoverOpen: true,
        tempoRestante: '',
        adicionarTempo: 0,
        timerOn: false,
        timerStart: 0,
        timerTime: 0,
        requesting: false,
        actionStartStop: false
    }

    UNSAFE_componentWillMount = () => {
        this.props.onRef(null)
    }


    togglePopover = () => {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        })
    }

    componentDidMount() {
        const { TempoAdicionado } = this.props.task
        this.setState({
            adicionarTempo: TempoAdicionado === 0 ? "" : TempoAdicionado > 599 ? `${TimerUtils.HoraMinuto.MinutosToHoraMinuto(TempoAdicionado)}` : `0${TimerUtils.HoraMinuto.MinutosToHoraMinuto(TempoAdicionado)}`
        })
        const { Iniciada, Tempos } = this.props.task
        const running = Tempos.find(f => f.data_inicio && !f.data_fim)
        if (typeof running !== 'undefined' && Iniciada) {
            //this.startTimer(running.data_inicio)
        }

        this.props.onRef(this)
    }


    componentDidUpdate = (prevProps) => {
        const prevTask = prevProps.task
        const currTask = this.props.task
        if (prevTask.Codigo !== currTask.Codigo) {
            console.log('updated')
        }
    }

    pararAtividade = (atividade) => {
        this.props.requestInit(atividade.Codigo)
        this.acoesAtividadesMenuEsquerda(atividade.Codigo, 'stop')
            .then(resp => {
                this.setState({ actionStartStop: false })
                this.props.onStopActivityAfterTodoID(atividade.CodigoTarefa)
                if (!resp.status === 200) ToastError(resp.response.data.message)
                else {
                    const { task } = this.props
                    this.props.requestInit(false)
                    this.props.pausaTarefas(resp.data)
                    if (atividade.Planejado && resp.data && resp.data.Tarefa && resp.data.Tarefa.arquivado) {
                        this.props.toggleHideTask(task, true)
                    }
                }
            })
            .catch(resp => {
                ToastError(resp.response.data.message)
            })
    }

    iniciarAtividade = (atividade) => {
        this.props.requestInit(atividade.Codigo)
        this.acoesAtividadesMenuEsquerda(atividade.Codigo, 'start')
            .then(resp => {
                this.setState({ actionStartStop: false })
                this.props.onStartActivityAfterTodoID(atividade.CodigoTarefa)
                if (!resp.status === 200) ToastError(resp.response.data.message)
                else {
                    const { task } = this.props
                    //ALTERA O STATUS DA ATIVIDADE
                    if (atividade.TarefaArquivada) {
                        this.arquivarDesarquivar(atividade, 'desarquivar')
                    }
                    this.props.requestInit(false)
                    this.props.startAtividade(resp.data)
                    if (atividade.Planejado) {

                        this.props.toggleHideTask(task, false)
                    }

                }
            })
            .catch(err => {
                this.props.requestInit(false)
                console.log(err.response.data.code)
                if (err.response.data.code === 403) {
                    this.props.toggleTrackingModal()
                } else {
                    ToastError(err.response.data.Message)
                }
            })
    }

    acoesAtividadesMenuEsquerda = (atividade, acao) => {
        let action = Querys.Activity.Start
        if (acao === 'stop')  {
            action = Querys.Activity.Stop
        }
        return action(atividade, null) // Axios.post(`${url.ApiURL}/activity/${atividade}/${acao}`)
    }

    pausarAtividade() {
        const { task } = this.state
        task.Iniciada = false
        clearInterval(task.running)
        this.setState({ task: { ...task } })
    }

    sincronizaTempo() {

    }

    arquivarDesarquivar = (task, type) => {
        console.log(type)
        this.props.requestInit(task.Codigo)
        Axios.put(`${url.RouterUrl}/api/todo/${task.CodigoTarefa}/${type && type === 'desarquivar' ? 'unarchive' : 'archive'}`)
            .then(resp => {
                if (resp.status === 200) {
                    if (task.Planejado && !task.Iniciada) {
                        this.props.toggleHideTask(task, !task.TarefaArquivada)
                    }
                    this.props.notifyFinalizado(task)
                }
                this.props.requestInit(null)
            })
            .catch(resp => { ToastError(resp.response.data.message) })
    }

    toggleTerminarTarefa = (task) => {
        this.arquivarDesarquivar(task, task.TarefaArquivada ? 'desarquivar' : 'arquivar')

        if (task.Iniciada) {
            this.startStopAction(task)
        }
        if (task.Planejado && !task.Iniciada) {
            console.log(!this.props.disableDone)

            //this.props.toggleHideTask(task, !task.TarefaArquivada)
        }

    }

    toggleTooltip = (tooltip) => {
        this.setState(prevState => ({
            tooltipOpen: prevState.tooltipOpen === tooltip ? null : tooltip
        }))
    }

    startStopAction = async (task) => {
        this.setState({ actionStartStop: true })
        const { allowedTimezone } = this.props
        task.DataInicio = task.DataInicio ?? task.DataInclusao
        if (task.Planejado && task.TarefaArquivada && task.DataInicio.split('T')[0] !== moment().tz(moment.tz.guess()).format().split('T')[0]) {
            this.props.toggleDoneTask()
            return
        }


        if (moment(task.DataInicio.split('T')[0].toString()).diff(moment(moment().format().split('T')[0].toString()), 'minutes') !== 0 && !task.Iniciada) {
            this.props.editarTarefa({ ...task }, true)
            this.props.requestInit(false)
            return
        }
        const running = task.Planejado ? await this.checkTaskIsRuning(task.CodigoTarefa) : false

        if (task && task.Tarefa && task.Tarefa.AtribuidoA > 0 && !task.Iniciada) {
            this.props.toggleRunningModal(running)
            //return
        }

        if (!task.Iniciada && !running && allowedTimezone !== task.Timezone) {
            ToastError(`Essa atividade foi iniciada em uma região diferente da que você se encontra no momento: ${task && task.Timezone}. Por favor, crie uma nova atividade.`)
            this.props.requestInit(false)
            return
        }

        setTimeout(() => {
            if (task.Iniciada) {
                this.pararAtividade(task)
            } else {
                this.iniciarAtividade(task)
            }
        }, 10);
    }

    checkTaskIsRuning = async (codigo) => {
        return await Axios.get(`${url.RouterUrl}/api/todo/${codigo}/running`)
            .then(resp => {
                return resp && resp.data && resp.data.Running ? resp.data.NomeAtrubuidoA : null
            }).catch(err => {

            })
    }

    adicionarTempo = (task) => {
        const { adicionarTempo } = this.state
        const tempo = TimerUtils.tempoToMinutos(adicionarTempo)
        if (tempo === -1) {
            ToastError('Valor inválido')
            return
        }

        Axios.post(`${url.RouterUrl}/api/management/addTime/` + task.CodigoTarefa, {
            tempo: tempo
        }).then(res => {
            this.props.notifyTempoRestante(task, tempo)
            this.props.adicionarTempo(task, tempo)
        }).catch(resp => {
            ToastError(resp.response.data.message)
        })

    }

    toggleStartStop = () => {
        if (this.state.timerOn) {
            this.resetTimer()
        } else {
            this.startTimer()
        }
    }

    render() {
        const { task, totalTime, isDisconnected, requesting } = this.props
        task.Reprovados = task.Reprovados ?? []
        const percentual = (task.TempoUtilizado / (task.TempoPlanejado + task.TempoAdicionado)) * 100
        return (
            <div className="d-flex time-timers">

                <div className="d-flex flex-column align-items-center justify-content-center mr-1">
                    <div className="d-inline-block d-none align-middle">
                        <h1 style={{ position: 'relative', top: 0, fontSize: '30px', marginBottom: 0 }}>
                            {secondsToTime(totalTime)}
                        </h1>
                    </div>
                    {/* {task.Planejado && (task.TempoPlanejado + task.TempoAdicionado) > 0 &&
                        <div className="d-flex align-middle">
                            <Badge id="tooltip_1" style={{ marginRight: 3 }} color={percentual >= 80 && percentual <= 90 ? 'warning' : percentual > 90 ? 'danger' : 'light'}>
                                {<span>{task.TempoUtilizado > 0 ? TimerUtils.HoraMinuto.MinutosToHoraMinuto(task.TempoUtilizado) : '0:00'}</span>}
                            </Badge>
                            <Tooltip2

                                placement="left"
                                autohide={true}
                                isOpen={this.state.tooltipOpen === `tooltip_1`}
                                target={`tooltip_1`}
                                toggle={() => this.toggleTooltip(`tooltip_1`)}
                            >
                                Total gasto
                            </Tooltip2>
                            <OverlayTrigger trigger="click" placement="top" overlay={(
                                <Popover id={'ppover3'}>
                                    <Popover.Title as="h3">Adicionar tempo</Popover.Title>
                                    <Popover.Content>
                                        <div className="d-flex align-items-end">
                                            <div className="d-inline-block d-none align-middle">
                                                <small>Planejado: </small>
                                                <Input type='text'
                                                    value={TimerUtils.HoraMinuto.MinutosToHoraMinuto(task.TempoPlanejado)}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="mb-1 ml-50">+</div>
                                            <div className="d-inline-block d-none align-middle ml-50">
                                                <small>Adicionado: </small>
                                                <InputMask type='text'
                                                    className="form-control"
                                                    mask="99:99"
                                                    value={this.state.adicionarTempo}
                                                    onFocus={e => e.target.select()}
                                                    disabled={this.props.ViewGestorPessoa !== null || isDisconnected}
                                                    onChange={e => {
                                                        this.setState({ adicionarTempo: e.target.value })
                                                    }}
                                                />
                                            </div>
                                            <div className="d-inline-block d-none align-middle ml-50">
                                                <Button.Ripple
                                                    color='success'
                                                    className='btn-ok'
                                                    disabled={this.props.ViewGestorPessoa !== null || isDisconnected}
                                                    style={{ padding: 11 }}
                                                    onClick={() => this.adicionarTempo(task)}>
                                                    <Icon.Check size={14} />
                                                </Button.Ripple>
                                            </div>
                                        </div>

                                    </Popover.Content>
                                </Popover>
                            )}>
                                <Badge id="tooltip_2" color={task.TempoAdicionado > 0 ? "primary" : "info"} className='badge-planejado cursor-pointer'>
                                    {TimerUtils.HoraMinuto.MinutosToHoraMinuto(task.TempoPlanejado + task.TempoAdicionado)}
                                </Badge>
                            </OverlayTrigger>

                            <Tooltip2

                                placement="right"
                                autohide={true}
                                isOpen={this.state.tooltipOpen === `tooltip_2`}
                                target={`tooltip_2`}
                                toggle={() => this.toggleTooltip(`tooltip_2`)}
                            >
                                {task.TempoAdicionado > 0 ? 'Planejado + adicionado' : 'Planejado'}
                            </Tooltip2>
                        </div>
                    } */}
                </div>

                <div className="d-inline-block d-none align-middle">
                    <If visivel={task.StatusAtividade === 'A'}>
                        {/*<Button.Ripple outline color={'light'} className="btn-clock mr-1 flat" disabled>
                            <Icon.Lock size={22} />
                        </Button.Ripple>*/}
                        <div className={`timer-custom-button finished`}>
                            <div className="d-flex">

                                <div className="timer-custom-button-cta"
                                >
                                    <div className="timer-custom-button-icon">
                                        <Icon.Lock size={22} />
                                    </div>

                                </div>
                                {task.Planejado === true &&
                                    <div className="d-flex align-items-center">
                                        <div className={`timer-custom-button-check ${task.TarefaArquivada ? 'checked' : ''} disabled`}
                                            id='btnDone'
                                        >
                                            {task.TarefaArquivada &&
                                                <Icon.Check className="vx-icon" size={16} />
                                            }

                                        </div>
                                        <UncontrolledTooltip placement="top" target="btnDone">
                                            Finalizado
                                        </UncontrolledTooltip>
                                    </div>
                                }
                            </div>
                        </div>
                    </If>
                    {task.StatusAtividade !== 'A' &&
                        <div>
                            <ButtonAtl
                                isDisabled={this.state.actionStartStop}
                                className='atl-button'
                                style={{ width: '130px', height: "54px" }}
                                appearance={task.Iniciada ? 'primary' : 'default'}
                                iconBefore={(
                                    <div>
                                        <If visivel={task.Iniciada}>
                                            <Lottie options={homeLottie} height={22} width={22} />
                                        </If>
                                        <If visivel={!task.Iniciada}>
                                            <Icon.Clock size={22} />
                                        </If>
                                    </div>
                                )}
                                onClick={() => {
                                    !(this.props.ViewGestorPessoa !== null || isDisconnected) && this.startStopAction(task)
                                }}>
                                <div style={{ paddingTop: '9px' }}>
                                    <b className="font-medium-2">
                                        <If visivel={!task.Iniciada}><FormattedMessage id='Start' /></If>
                                        <If visivel={task.Iniciada}><FormattedMessage id='Stop' /></If>
                                    </b>
                                </div>
                            </ButtonAtl>
                            {/* <div className="d-flex">
                                {requesting === task.Codigo && <div className={`custom-buttom-loading`}><Spinner color="white" size="sm" /></div>}
                                <div className="timer-custom-button-cta"
                                    onClick={() => {
                                        !(this.props.ViewGestorPessoa !== null || isDisconnected) && this.startStopAction(task)

                                    }}>
                                    <div className="timer-custom-button-icon">
                                        <If visivel={task.Iniciada}>
                                            <Lottie options={homeLottie} height={22} width={22} />
                                        </If>
                                        <If visivel={!task.Iniciada}>
                                            <Icon.Clock size={22} />
                                        </If>
                                    </div>
                                    <div className="timer-custom-button-status">
                                        <b className="font-medium-2">
                                            <If visivel={!task.Iniciada}><FormattedMessage id='Start' /></If>
                                            <If visivel={task.Iniciada}><FormattedMessage id='Stop' /></If>
                                        </b>
                                    </div>
                                </div>

                            </div> */}
                        </div>
                    }
                </div>

            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        tempoUpdate: state.clock.atualizacao.tempo
    }
}
export default connect(mapStateToProps, {
    onStartActivityAfterTodoID,
    onStopActivityAfterTodoID
})(TimerTarefa)
