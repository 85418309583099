import React, { useEffect, useState } from 'react';
import './task-inactivity-justification.scss';
import { X } from 'react-feather';
import { Spinner } from 'reactstrap';
import { onlyUnique } from '../../../../utility/onlyUnique';
import moment from 'moment';
import url from '../../../../Config';
import Axios from 'axios';

const defaultInactivityJustifications = {
    loading: false,
    show: false,
    data: []
}

const formatTableData = (data) => {
    const types = data.map((item) => item.time).filter(onlyUnique);
    return data.map((item) => {
        return {
            date: item.datetime ? moment(item.datetime).format('DD/MM/YYYY HH:mm') : moment().format('DD/MM/YYYY HH:mm'),
            ...types.reduce((a, v) => ({ ...a, [v]: item.time === v ? <X size={12} /> : null }), {})
        }
    });
}

function TaskInactivityJustification({ item, type, toggleShow, user }) {

    const [inactivityJustifications, setInactivityJustifications] = useState(defaultInactivityJustifications);

    useEffect(() => {
        if (item) {
            const requestAddress = type === 'day' ? `${item}/${user}/all` :
                                                    `${item.code}`;
            setInactivityJustifications({ ...inactivityJustifications, loading: true });
            Axios.get(`${url.RouterUrl}/api/activity/inactivity/${requestAddress}`)
                .then(response => {
                    if(response.data){
                        const data = formatTableData(response.data);
                        setInactivityJustifications({ ...inactivityJustifications, loading: false, data: data });
                    } else {
                        setInactivityJustifications({ ...inactivityJustifications, loading: false, data: [] });
                    }
                })
                .catch(error => {
                    setInactivityJustifications({ ...inactivityJustifications, loading: false, data: [] });
                })
        }
    }, [item, type]);


    return (
        <div className={`task-inactivity-justification-ui ${item ? 'show' : ''} ${type === 'time' ? 'position-absolute' : 'position-relative' }`} onMouseLeave={toggleShow}>
            {type === 'time' &&
                <div className="task-inactivity-justification-ui__title">
                    <h3>Justificativas de inatividade</h3><div className='close' onClick={toggleShow}><X size={12} /></div>
                </div>
            }
            <div className="task-inactivity-justification-ui__content">
                {inactivityJustifications.loading && <Spinner color="primary" className="mt-2" size="sm" />}
                {!inactivityJustifications.loading && inactivityJustifications.data.length > 0 ?
                    <table className={`table ${type === 'time' ? 'position-absolute' : 'position-relative mb-0' }`}>
                        <thead>
                            <tr>
                                <th>Data</th>
                                {Object.keys(inactivityJustifications.data[0]).filter(item => !item.includes('date')).map((item, index) => (
                                    <th key={index} className="text-center">{item} min</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {inactivityJustifications.data.map((item, index) => (
                                <tr key={index}>
                                    {Object.entries(item).filter(item => item.includes('date')).map(([k, v]) =>
                                        <td key={`${index}-${k}`}>{v}</td>
                                    )}
                                    {Object.entries(item).filter(item => !item.includes('date')).map(([k, v]) =>
                                        <td key={`${index}-${k}`} className="text-center">{v}</td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table> : 
                    <div className="text-center mt-2"  onMouseLeave={toggleShow}>Não há dados para exibir</div>
                } 

            </div>
            {/* <div className="task-inactivity-justification-ui__footer">
                footer
            </div> */}
        </div>
    );
}

export default TaskInactivityJustification;