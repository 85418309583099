const moneyFormat = (n) => {
    console.log(n)
    const value = n.toString().replace(/\D/g, '')
    const number = parseInt(value.toString().replace(/\D/g, '')).toString()
    const length = number.length
    switch (length) {
        case 1:
            return `R$ 0,0${number}`
        case 2:
            return `R$ 0,${number}`
        case 3:
            return `R$ ${number.substring(0, 1)},${number.substring(1, 3)}`
        case 4:
            return `R$ ${number.substring(0, 2)},${number.substring(2, 4)}`
        case 5:
            return `R$ ${number.substring(0, 3)},${number.substring(3, 5)}`
        case 6:
            return `R$ ${number.substring(0, 1)}.${number.substring(1, 4)},${number.substring(4, 6)}`
        case 7:
            return `R$ ${number.substring(0, 2)}.${number.substring(2, 5)},${number.substring(5, 7)}`
        case 8:
            return `R$ ${number.substring(0, 3)}.${number.substring(3, 6)},${number.substring(6, 8)}`
        case 9:
            return `R$ ${number.substring(0, 1)}.${number.substring(1, 4)}.${number.substring(4, 7)},${number.substring(7, 9)}`
        case 10:
            return `R$ ${number.substring(0, 2)}.${number.substring(2, 5)}.${number.substring(5, 8)},${number.substring(8, 10)}`
        case 11:
            return `R$ ${number.substring(0, 3)}.${number.substring(3, 6)}.${number.substring(6, 9)},${number.substring(9, 11)}`
        case 12:
            return `R$ ${number.substring(0, 1)}.${number.substring(1, 4)}.${number.substring(4, 7)}.${number.substring(7, 10)},${number.substring(10, 12)}`
        default:
            return 0
    }
}

const moneyFormat2 = (n) => {
    const number = parseFloat(n)
    return number.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}


const floatFormat = (n) => {
    const number = n.replace(/\D/g, '')
    const cents = number.slice(0, -2)
    const decimal = number.slice(-2)
    return `${cents}.${decimal}`
}

export {
    moneyFormat,
    moneyFormat2,
    floatFormat
}