import {del, get, post, put} from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/notification`
}

export const List = () => {
    return get(`${getUrl()}/list`)
}

export const Description = () => {
    return get(`${getUrl()}/description`)
}

export const Read = (id) => {
    return post(`${getUrl()}/${id}/read`)
}

export const ReadAll = () => {
    return post(`${getUrl()}/readAll`)
}

export const RemoveAll = () => {
    return del(`${getUrl()}/removeAll`)
}