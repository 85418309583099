import React, { Component } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Field } from '@atlaskit/form';
import ButtonAtl from '@atlaskit/button';
import Select from '@atlaskit/select';
import { Querys } from '../../../../../database/base';
import ModalSuricatoo from '../../../../../utility/ModalSuricatoo';
import Textfield from '@atlaskit/textfield';
import ValidationMessage from '../../../../../utility/Validation';
import { DatePicker } from '@atlaskit/datetime-picker';

const defaultValue = {
    id: 0,
    name: '',
    start: new Date().toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0]
}

export default class SprintFinish extends Component {
    state = {
        ...defaultValue,
        duration: { label: '2 semana', value: 2 },
        dirty: {},
        required: ['name', 'key', 'companyID', 'start'],
        errors: {},
        selected: null,
        resume: {
            doneCount: 0,
            pendingCount: 0
        }
    }

    componentDidMount() {
        var date = new Date()
        date.setDate(date.getDate() + this.state.duration.value * 7)
        this.setState({ end: date.toISOString().split('T')[0], open: this.props.open })
    }

    componentDidUpdate() {
        if (this.state.open !== this.props.open) {
            this.setState({ open: this.props.open })
            if (this.props.open) {
                Querys.Sprint.Resume(this.props.id).then(({ data }) => this.setState({ resume: data }))
            }
        }
    }

    udpateValue = (name, values) => {
        const value = {
            ...values,
            dirty: { ...this.state.dirty, [name]: true },
            errors: { ...this.state.errors, [name]: { error: false } }
        }
        this.setState({ ...value })
    }

    finish = () => {
        let { sprintsOpen } = this.props
        let optionsSprint = sprintsOpen.map(x => { return { label: x.name, value: x.boardID } })
        optionsSprint = [...optionsSprint, ...[
            { label: 'Nova sprint', value: 'new' },
            { label: 'Backlog', value: 'backlog' },
        ]]
        let selected = this.state.selected
        if (this.state.selected === null) {
            selected = optionsSprint[0]
        }
        let typeMove = selected.value
        let boardID = selected.value
        if (typeMove !== 'new' && typeMove !== 'backlog') {
            typeMove = 'specific'
        } else {
            boardID = null
        }
        var date = new Date()
        date.setDate(date.getDate() + 14)
        Querys.Sprint.Finish(this.props.id, {
            sprint: {
                name: this.state.name,
                start: this.state.start,
                end: this.state.end,
                projectID: this.props.projectID
            }, typeMove, boardID
        }).then(this.props.onSprintFinished)
    }
    render() {
        let { sprintsOpen } = this.props
        let optionsSprint = sprintsOpen.map(x => { return { label: x.name, value: x.boardID } })
        optionsSprint = [...optionsSprint, ...[
            { label: 'Nova sprint', value: 'new' },
            { label: 'Backlog', value: 'backlog' },
        ]]
        let selected = this.state.selected
        if (selected === null) {
            selected = optionsSprint[0]
        }
        return (
            <ModalSuricatoo open={this.props.open} onClose={this.props.toggleModal}
                header={`Finalizar sprint: ${this.props.sprint?.name}`}
                footer={(
                    <div className='d-flex justify-content-between mt-2 w-100'>
                        <div></div>
                        <div>
                            <ButtonAtl
                                //isDisabled={this.props.sprint?.responsableID !== user.codigo} 
                                appearance='primary' className='atl-button mr-1' onClick={this.finish}>Finalizar sprint</ButtonAtl>
                            <ButtonAtl appearance='default' className='atl-button' onClick={this.props.toggleModal}>Cancelar</ButtonAtl>
                        </div>
                    </div>
                )}>
                {(this.props.doneCount > 0 || this.props.pendingCount > 0) &&
                    <div>
                        <p>Este sprint contém:</p>
                        <ul>
                            <li><b>{this.props.doneCount}</b> item concluído</li>
                            <li><b>{this.props.pendingCount}</b> item aberto</li>
                        </ul>
                    </div>}
                <Field label="Mover itens abertos para" name="start">
                    {({ fieldProps }) => (
                        <React.Fragment>
                            <Select
                                isSearchable={false}
                                value={selected}
                                className="w-100"
                                defaultValue={optionsSprint[0]}
                                options={optionsSprint}
                                onChange={e => {
                                    this.setState({ selected: e })
                                }}
                            />
                        </React.Fragment>
                    )}
                </Field>
                {selected.value === 'new' &&
                    <div className='border-sprint-new'>
                        <Field label="Nome" name="name" isRequired>
                            {({ fieldProps }) => (
                                <React.Fragment>
                                    <Textfield
                                        {...fieldProps}
                                        value={this.state.name === null ? '' : this.state.name}
                                        onChange={e => {
                                            const name = e.target.value
                                            this.udpateValue(fieldProps.name, { name })
                                        }}
                                        placeholder="nome da sprint" />
                                    <ValidationMessage
                                        {...this.state}
                                        {...fieldProps}
                                        state={this.state}
                                        defaultValue={defaultValue}
                                        message='Nome é obrigatório' />
                                </React.Fragment>
                            )}
                        </Field>
                        <Field label="Duração" name="start" isRequired>
                            {({ fieldProps }) => (
                                <React.Fragment>
                                    <Select
                                        value={this.state.duration}
                                        defaultValue={this.state.duration}
                                        className="w-100"
                                        options={[
                                            { label: '1 semana', value: 1 },
                                            { label: '2 semana', value: 2 },
                                            { label: '3 semana', value: 3 },
                                            { label: '4 semana', value: 4 },
                                            { label: 'personalizado', value: 0 },
                                        ]}
                                        onChange={e => {
                                            var date = new Date()
                                            date.setDate(date.getDate() + e.value * 7)
                                            this.setState({ duration: e, end: date.toISOString().split('T')[0] })
                                        }}
                                    />
                                </React.Fragment>
                            )}
                        </Field>
                        <Field label="Data de início" name="start" isRequired>
                            {({ fieldProps }) => (
                                <React.Fragment>
                                    <DatePicker
                                        {...fieldProps}
                                        locale='pt-BR'
                                        value={this.state.start}
                                        onChange={e =>
                                            this.udpateValue(fieldProps.name, { start: e })
                                        } />
                                    <ValidationMessage
                                        {...this.state}
                                        {...fieldProps}
                                        state={this.state}
                                        defaultValue={defaultValue}
                                        message='Data de inicio é obrigatório' />
                                </React.Fragment>
                            )}
                        </Field>
                        <Field label="Data de fim" name="end" isRequired isDisabled={this.state.duration.value !== 0}>
                            {({ fieldProps }) => (
                                <React.Fragment>
                                    <DatePicker
                                        {...fieldProps}
                                        locale='pt-BR'
                                        value={this.state.end}
                                        onChange={e =>
                                            this.udpateValue(fieldProps.name, { end: e })
                                        } />
                                    <ValidationMessage
                                        {...this.state}
                                        {...fieldProps}
                                        state={this.state}
                                        defaultValue={defaultValue}
                                        message='Data de fim é obrigatório' />
                                </React.Fragment>
                            )}
                        </Field>
                    </div>}
            </ModalSuricatoo>
        )
    }
}
