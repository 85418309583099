import { el, faker } from '@faker-js/faker';
import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2';
import { BuildOptions, Filter, InsightsPlugins } from '../../../insights-chart/tools';
import ButtonAtl from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross'
import Tooltip from '@atlaskit/tooltip';
import Actions from '../../../insights-chart/Actions';
import ModalSuricatoo from '../../../../../utility/ModalSuricatoo';

const options = {
    plugins: {
        ...InsightsPlugins
    },
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};


export default class InsightPeopleTag extends Component {
    state = {
        open: false,
        data: null
    }

    filter = (sprintID, data) => {
        const originalFilter = {
            labels: this.labels(),
            datasets: this.datasets()
        }
        originalFilter.options = BuildOptions(originalFilter)
        return Filter(originalFilter, data)
    }

    labels() {
        const { data } = this.props
        const people = [...new Set(data.map(x => x.assigened ?? { id: 0, name: 'Não atribuído' }).map((item) => item.name))];
        return people
    }

    datasets() {
        const { data } = this.props
        const people = [...new Set(data.map(x => x.assigened ?? { id: 0, name: 'Não atribuído' }).map((item) => item.name))];
        const allTags = {}
        data.map(x => x.tags ?? []).forEach(element => {
            element.forEach(x => allTags[x.tag] = { label: x.tag, data: [], backgroundColor: x.color })
        });
        allTags['Sem tag'] = { label: 'Sem tag', data: [], backgroundColor: '#ddd' }
        allTags['Desvio'] = { label: 'Desvio', data: [], backgroundColor: '#ddd', borderColor: '#FF8C00', borderWidth: 2 }
        const datasets = []
        Object.keys(allTags).forEach(t => {
            const dtSet = allTags[t]
            people.forEach(person => {
                let allData
                if (person === 'Não atribuído')
                    allData = data.filter(z => !z.assigenedToID || z.assigenedToID === 0)
                else
                    allData = data.filter(z => z.assigened && z.assigened.name === person)
                var total = 0
                allData.forEach(x => {
                    x.tags = x.tags ?? []
                    if (x.tags.length === 0 && dtSet.label === 'Sem tag' && x.cardType !== 'impediment') {
                        total += (x.plannedTime ?? 0)
                    }
                    if (x.tags.length === 0 && dtSet.label === 'Desvio' && x.cardType === 'impediment') {
                        total += (x.plannedTime ?? 0)
                    }
                    x.tags.forEach(t => {
                        if (t.tag === dtSet.label) {
                            total += (x.plannedTime ?? 0)
                        }
                    })
                })
                dtSet.data.push(total / 60)
            })
            datasets.push(dtSet)
        })
        return datasets
    }

    render() {
        const dataObj = this.state.data ?? {
            labels: this.labels(),
            datasets: this.datasets(),
            options: null
        };
        if (dataObj.options === null) {
            dataObj.options = BuildOptions(dataObj)
        }
        return (
            <div className='sprint-capacity'>
                {this.props.onClose &&
                    <div className='d-flex justify-content-between' style={{ marginBottom: '3px' }}>
                        <b style={{ paddingLeft: '5px' }}>
                            Horas planejadas por pessoa por tag
                        </b>
                        <Tooltip content='Fechar'>
                            <ButtonAtl
                                appearance='default'
                                className='atl-button'
                                iconAfter={<CrossIcon />}
                                onClick={this.props.onClose} />
                        </Tooltip>
                    </div>}
                <Actions
                    data={dataObj}
                    noTitle={this.props.onClose}
                    title='Horas planejadas por pessoa por tag'
                    onExpand={() => this.setState({ open: true })}
                    onChange={(data) => this.setState({ data })}
                    onFilter={this.filter}
                    expand
                    filter
                    order />
                <div>
                    <Bar options={options} data={dataObj} />
                    <ModalSuricatoo
                        width='1200px'
                        autoFocus={false}
                        open={this.state.open}
                        onClose={() => this.setState({ open: false })}>
                        <div className='mb-2 mt-2'>
                            <Bar options={options} data={dataObj} />
                        </div>
                    </ModalSuricatoo>
                </div>
            </div>
        )
    }
}
