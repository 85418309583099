import React, { Component } from 'react'
import { Querys } from '../../../../database/base'
import AvatarPessoa from '../../../../utility/AvatarPessoa'
import Comment from '@atlaskit/comment';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right'
import TextColor from '../../../../utility/Color';
import Lozenge from '@atlaskit/lozenge';
import Task16Icon from '@atlaskit/icon-object/glyph/task/16'
import Subtask16Icon from '@atlaskit/icon-object/glyph/subtask/16'
import Bug16Icon from '@atlaskit/icon-object/glyph/bug/16'
import Story16Icon from '@atlaskit/icon-object/glyph/story/16'
import Epic16Icon from '@atlaskit/icon-object/glyph/epic/16'
import Changes16Icon from '@atlaskit/icon-object/glyph/changes/16'
import { Link } from 'react-router-dom';

const fieldsNotProcess = {
    archived: {},
    companyCode: {},
    createdAt: {},
    id: {},
    updatedAt: {}
}

const fieldConfig = {
    responsibleID: (item) => {
        if (item.responsibleID === 0 || item.responsibleID === undefined) return 'Nenhum'
        return (
            <div className='d-flex'>
                <AvatarPessoa codigo={item.responsibleID} />
                <span style={{ marginLeft: '3px' }}>{item.responsible.name}</span>
            </div>
        )
    },
    assigenedToID: (item) => {
        if (item.assigenedToID === 0 || item.assigenedToID === undefined) return 'Nenhum'
        return (
            <div className='d-flex'>
                <AvatarPessoa codigo={item.assigenedToID} />
                <span style={{ marginLeft: '3px' }}>{item.assigened.name}</span>
            </div>
        )
    },
    projectID: (item) => {
        if (item.projectID === 0 || item.projectID === undefined) return 'Nenhum'
        return (
            <Lozenge
                style={{
                    backgroundColor: item.project.color,
                    color: TextColor(item.project.color)
                }}>{item.project.name}</Lozenge>
        )
    },
    cardType: (item) => {
        const icon = item.cardType === 'task' ? <><Task16Icon /> Tarefa</> :
            item.cardType === 'subtask' ? <><Subtask16Icon /> Sub tarefa</> :
                item.cardType === 'bug' ? <><Bug16Icon /> Bug</> :
                    item.cardType === 'history' ? <><Story16Icon /> História</> :
                        item.cardType === 'impediment' ? <><Changes16Icon /> Desvio</> : <><Epic16Icon /> Épico</>
        return icon
    },
    parentCardID: (item) => {
        if (item.parentCardID === 0 || item.parentCardID === undefined) return 'Nenhum'
        return (
            <a href={`/card/${item.parent.cardKey}`}><b>{item.parent.cardKey}</b></a>
        )
    },
    workPlanStatusType: (item, workPlanStatus) => {
        const w = workPlanStatus?.find(x => x.name === item.workPlanStatusType)
        if (w) {
            return <Lozenge appearance={w.color === 'todo' ? 'default' : w.color === 'in progress' ? 'inprogress' : w.color === 'done' ? 'success' : 'default'}>{w.workPlanColumn.name}</Lozenge>
        }
        return item.status
    }
}

export default class CardHistory extends Component {
    state = {
        id: 0,
        cardKey: null,
        history: [],
        description: {},
        fields: {},
        workPlanStatus: []
    }

    componentDidMount() {
        this.setState({ ...this.props })
        Querys.Card.History(this.props.id).then(({ data }) => this.setState({ history: data ?? [] }))
        Querys.Card.HistoryDescription().then(({ data }) => this.setState({ description: { ...data } }))
        Querys.Card.FieldName().then(({ data }) => this.setState({ fields: { ...data } }))
        Querys.WorkPlan.Status().then(({ data }) => this.setState({ workPlanStatus: data }))
    }

    content = (before, after) => {
        return Object
            .keys(after)
            .filter(x => fieldsNotProcess[x] === undefined && typeof after[x] !== 'object' && after[x] !== null)
            .map(item => {
                if (fieldConfig[item]) {
                    return (
                        <div className='d-flex' key={`content-edit-${item}`}>
                            <div>{fieldConfig[item](before, this.state.workPlanStatus)}</div>
                            <div className='ml-1 mr-1'><ArrowRightIcon size='small' /></div>
                            <div>{fieldConfig[item](after, this.state.workPlanStatus)}</div>
                        </div>)
                }
                return (
                    <div className='d-flex' key={`content-edit-${item}`}>
                        <div>{before[item] || 'Nenhum'}</div>
                        <div className='ml-1 mr-1'><ArrowRightIcon size='small' /></div>
                        <div>{after[item] || 'Nenhum'}</div>
                    </div>)
            })
    }

    verb = (after) => {
        return Object.keys(after)
            .filter(x => fieldsNotProcess[x] === undefined && typeof after[x] !== 'object' && after[x] !== null)
            .map(item => this.state.fields[item]).join(', ')
    }

    render() {
        return (
            <div className='mb-3'>
                {this.state.history.map(item =>
                    <div key={`history-${item.id}`}>
                        <Comment
                            avatar={<AvatarPessoa size='md' noTooltip codigo={item.ownerID} />}
                            content={<div>
                                <div className='d-flex card-comment-info'>
                                    <p><b>{item.owner.name}</b></p>
                                    <p>{this.state.description[item.type]}</p>
                                    <p><b>{item.verb || this.verb(item.newContent)}</b></p>
                                    <p>{new Date(item.createdAt).toLocaleDateString() + " " + new Date(item.createdAt).toLocaleTimeString()}</p>
                                </div>
                                {item.type === 'editCard' && this.content(item.lastContent, item.newContent)}
                            </div>}
                        />
                    </div>)}
            </div>
        )
    }
}
