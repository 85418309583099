import React, { Component } from 'react'
import Button from '@atlaskit/button';
import ShareIcon from '@atlaskit/icon/glyph/share'
import Tooltip from '@atlaskit/tooltip';
import InlineDialog from '@atlaskit/inline-dialog';
import { Querys } from '../../../../../database/base';
import Textfield from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search'
import AvatarPessoa from '../../../../../utility/AvatarPessoa';
import CheckBoxesVuexy from '../../../../../components/@vuexy/checkbox/CheckboxesVuexy';
import * as Icon from "react-feather"
import { Modal, ModalBody } from 'reactstrap';
import TextArea from '@atlaskit/textarea';
import ModalSuricatoo from '../../../../../utility/ModalSuricatoo';

export default class ShareCard extends Component {
    state = {
        data: [],
        message: ''
    }
    componentDidMount() {
        Querys.Person.List().then(({ data }) => { this.setState({ data }) })
    }
    render() {
        let { data } = this.props
        if (!data || data.length === 0) {
            data = this.state.data
        }
        return (
            <ModalSuricatoo
                open={this.props.open}
                onClose={this.props.onToogle}
                header='Compartilhar card'
                footer={(
                    <Button className='atl-button' appearance='primary'
                        shouldFitContainer
                        onClick={() => {
                            Querys.Card
                                .Share(this.props.id, { mensions: this.props.data.filter(x => x.selected === true), message: this.state.message })
                                .then(this.props.onShared)
                        }}
                    >Compartilhar</Button>
                )}>
                <div className='w-100'>
                    <Textfield
                        autoFocus={true}
                        className='w-100 mb-1'
                        value={this.props.search}
                        onChange={this.props.onChange}
                        elemBeforeInput={
                            <div className='search-icon'>
                                <SearchIcon size='small'></SearchIcon>
                            </div>
                        } />
                    <div className='share-grid'>
                        {data
                            .filter(x =>
                                x.name.toLowerCase().indexOf(this.props.search.toLowerCase()) >= 0 ||
                                x.email.toLowerCase().indexOf(this.props.search.toLowerCase()) >= 0)
                            .map((item, index) => (
                                <div key={`share-itens-${index}`}>
                                    <div className='d-flex' onClick={() => this.props.onCheck(item)}>
                                        <CheckBoxesVuexy
                                            color="info"
                                            checked={item.selected}
                                            onChange={() => {
                                                item.selected = !item.selected
                                                this.props.onCheck(item)
                                            }}
                                            icon={<Icon.Check className="vx-icon" size={16} />}
                                        />
                                        <div className='avatar-person-dropdown'>
                                            <AvatarPessoa size='md' noTooltip codigo={item.id} />
                                        </div>
                                        <div>
                                            <b>{item.name}</b>
                                            <br />
                                            <small>{item.email}</small>
                                        </div>
                                    </div>

                                </div>
                            ))}
                    </div>
                    <TextArea
                        value={this.state.message}
                        onChange={e => {
                            const message = e.target.value
                            this.setState({ message })
                        }}
                        style={{ width: '100%', marginTop: '1rem' }}
                        placeholder="envie uma mensagem para a(s) pessoas selecionadas"
                        resize="auto"
                        maxHeight="40vh" />
                </div>
            </ModalSuricatoo>
        )
    }
}
