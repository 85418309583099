import React, { Component } from 'react'
import InlineDialog from '@atlaskit/inline-dialog';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import * as Icon from "react-feather"
import { Querys } from '../../../database/base';
import AvatarPessoa from '../../../utility/AvatarPessoa';
import Button from '@atlaskit/button';
import { Link } from 'react-router-dom';
import CheckIcon from '@atlaskit/icon/glyph/check'
import TrashIcon from '@atlaskit/icon/glyph/trash'

export default class Notifications extends Component {
    state = {
        notificationOpen: false,
        notifications: [],
        description: {}
    }
    componentDidMount() {
        Querys.Notification.List().then(({ data }) => this.setState({ notifications: data ?? [] }))
        Querys.Notification.Description().then(({ data }) => this.setState({ description: { ...data } }))
    }
    toogleNotifications = () => {
        this.setState({ notificationOpen: !this.state.notificationOpen })
    }
    render() {
        return (
            <UncontrolledDropdown tag="li" className="nav-item notifications-suricatoo">
                <DropdownToggle tag="a">
                    <div className="nav-item"
                        style={{ paddingTop: '1.4rem' }}>
                        <div className='position-relative'>
                            {this.state.notifications.filter(x => x.read === false).length > 0 &&
                                <Badge pill color='danger' className='badge-up position-absolute position-notification'>
                                    {this.state.notifications.filter(x => x.read === false).length}
                                </Badge>}
                            <Icon.Bell size={21} />
                        </div>
                    </div>
                </DropdownToggle>
                <DropdownMenu right>
                    <div className='notification-body'>
                        <div className='w-100 header-notification'>
                            <b>Notificações</b>
                        </div>
                        <div className='notification-items'>
                            {this.state.notifications.length > 0 && this.state.notifications.map((item, index) => (
                                <React.Fragment key={`notification-${index}`}>
                                    <hr />
                                    <DropdownItem href="#">
                                        <div style={{ position: 'relative' }}>
                                            {item.read === false && <div className='notification-notread'></div>}
                                            <div key={`notification-${item.id}`} className={`notification-line d-flex`}
                                                onClick={() => {
                                                    Querys.Notification.Read(item.id)
                                                        .then(() => {
                                                            Querys.Notification.List()
                                                                .then(({ data }) => this.setState({ notifications: data ?? [] }))
                                                            if (item.url) window.location.href = item.url
                                                        })
                                                }}>
                                                <div className='notification-avatar'>
                                                    <AvatarPessoa size='md' nome={item.writer?.name ?? ''} codigo={item.writerID} noTooltip />
                                                </div>
                                                <div>
                                                    <div>
                                                        {new Date(item.createdAt).toLocaleString()}<br /><br /><br />
                                                    </div>
                                                    <div>
                                                        <span className='notification-name'>{item.writer?.name ?? ''}</span>
                                                        <b className='notification-type'>{this.state.description[item.typeNotification]}</b>
                                                    </div>
                                                    <br />
                                                    <span className='notification-description'>{item.description}</span>
                                                    <br /><br /><br />
                                                    {item.message && (
                                                        <React.Fragment>
                                                            <span className='notification-description'>{item.message}</span>
                                                            <br /><br /><br />
                                                        </React.Fragment>
                                                    )}
                                                    <div>
                                                        {item.url && <Link to='#' onClick={() => window.location.href = item.url}>Visualizar alteração</Link>}
                                                    </div>
                                                </div>
                                                {item.cardKey && <div className='notification-card'>Card: {item.cardKey}</div>}
                                            </div>
                                        </div>
                                    </DropdownItem>
                                </React.Fragment>
                            ))}
                        </div>
                        {this.state.notifications.length === 0 && (<div className='header-notification'>
                            Nenhuma notificação no momento...
                        </div>)}
                        {this.state.notifications.length > 0 &&
                            <div className='d-flex'>
                                <div className='w-50 p-1'>
                                    <Button
                                        iconAfter={<CheckIcon />}
                                        className='atl-button' shouldFitContainer onClick={() =>
                                            Querys.Notification.ReadAll()
                                                .then(() =>
                                                    Querys.Notification.List()
                                                        .then(({ data }) => this.setState({ notifications: data ?? [] }))
                                                )}>Marcar todos como lido</Button>
                                </div>
                                <div className='w-50 p-1'>
                                    <Button
                                        iconAfter={<TrashIcon />}
                                        className='atl-button' shouldFitContainer onClick={() =>
                                            Querys.Notification.RemoveAll()
                                                .then(() =>
                                                    Querys.Notification.List()
                                                        .then(({ data }) => this.setState({ notifications: data ?? [] }))
                                                )}>Remover lidas</Button>
                                </div>
                            </div>}
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }
}
