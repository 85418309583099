import React, { Component, useState } from 'react'
import AvatarGroup from '@atlaskit/avatar-group';
import Avatar from '@atlaskit/avatar';
import Tooltip from '@atlaskit/tooltip';
import { Querys } from "../../../../../database/base";
import AvatarPessoa from '../../../../../utility/AvatarPessoa';
import InviteTeamIcon from '@atlaskit/icon/glyph/invite-team'
import Button from '@atlaskit/button';
import InlineDialog from '@atlaskit/inline-dialog';
import Textfield from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search'
import Checkbox from "../../../../../components/@vuexy/checkbox/CheckboxesVuexy"
import * as Icon from "react-feather"
import Skeleton from 'react-loading-skeleton';
import {
    SpotlightTarget,
} from '@atlaskit/onboarding';

export default class PeopleProject extends Component {
    state = {
        dataCount: 0,
        selected: {},
        data: [],
        add: false,
        search: '',
        load: false
    }

    componentWillMount() {
        if (this.props.saveCacheFilter) {
            let filters = JSON.parse(localStorage.getItem(this.props.saveCacheFilter) ?? '{}')
            if (filters.selected) {
                this.setState({ selected: filters.selected })
            }
        }
        if (!this.props.defaultData)
            Querys.Person.List().then(({ data }) => this.updateData(data))
        else
            this.updateData(this.props.defaultData)
    }

    updateData(data) {
        let newData = data.map(x => { return { ...x } })
        const checked = this.props.value?.map(item => item.id)
        if (newData)
            newData.forEach(item => {
                if (checked)
                    checked.forEach(c => {
                        if (item.id === c) item.checked = true
                    })
            })
        const count = this.props.value?.length
        this.setState({ data: newData, load: true, dataCount: count ?? 0 })
    }

    updateDataDefault(data) {
        let newData = data.map(x => { return { ...x } })
        if (data) {
            newData = newData.map(x => { return { ...x, checked: false } })
            newData.forEach(item => {
                if (this.state.data)
                    this.state.data.forEach(c => {
                        if (item.id === c.id && c.checked) item.checked = true
                    })
            })
        }
        const count = this.props.defaultData?.length
        this.setState({ data: newData, load: true, dataCount: count ?? 0 })
    }

    componentDidUpdate() {
        const count = this.props.value?.length
        if (this.state.load && this.state.dataCount !== (count ?? 0)) this.updateData(this.state.data)
        if (this.props.defaultData && this.props.defaultData.length !== this.state.data.length) this.updateDataDefault(this.props.defaultData)
    }

    toogleAdd = () => {
        this.setState({ add: !this.state.add })
    }

    updateSelected = () => {
        const selected = this.state.data.filter(x => x.checked)
        if (this.props.updateSelected) this.props.updateSelected(selected.map(item => item.id), selected)
        this.setState({ add: !this.state.add })
    }

    avatarClick(index, props) {
        if (this.props.onAvatarClick) {
            const selected = this.state.selected
            let itemSelected = this.state.selected[index]
            if (itemSelected) {
                itemSelected.selected = !itemSelected.selected
                itemSelected.id = props.id
            } else {
                itemSelected = { selected: true, id: props.id }
            }
            selected[index] = itemSelected
            const item = { ...this.state.selected }
            Object.keys(item).forEach(x => {
                const idx = parseInt(x)
                if (idx !== index && item[idx].id === props.id) {
                    delete item[idx]
                }
            })
            this.setState({ selected: { ...item } })
            const array = []
            Object.keys(item).forEach(key => {
                if (item[key].selected) array.push(item[key].id)
            })
            if (this.props.saveCacheFilter) {
                let filters = JSON.parse(localStorage.getItem(this.props.saveCacheFilter) ?? '{}')
                localStorage.setItem(this.props.saveCacheFilter, JSON.stringify({ ...filters, selected: { ...item } }))
            }
            this.props.onAvatarClick(array)
        }
    }

    render() {
        let { data } = this.state
        if (this.props.showUnassigned)
            data = [{ id: null, name: 'Não atribuído', checked: true }, ...data]
        return (
            <SpotlightTarget name={this.props.spotlightTarget ?? 'people-project'}>

                <div className='avatar-group d-flex'>
                    {!this.state.load ? <Skeleton height={40} width={250} /> :
                        <>
                            {data.filter(x => x.checked).length > 0 ?
                                <AvatarGroup
                                    appearance="stack"
                                    data={data.filter(x => x.checked)}
                                    maxCount='11'
                                    overrides={{
                                        Avatar: {
                                            render: (Component, props, index) => {
                                                let selected = false
                                                Object.keys(this.state.selected).forEach(item => {
                                                    if (this.state.selected[item].id === props.id) {
                                                        selected = this.state.selected[item].selected
                                                    }
                                                })
                                                return (<div style={{ cursor: 'pointer' }} onClick={() => {
                                                    this.avatarClick(index, props)
                                                }}>
                                                    <AvatarPessoa {...props} size="md" codigo={props.id} nome={props.name}
                                                        borderColor={selected === true ? '#0747A6' : 'white'} key={index} />
                                                </div>)
                                            }
                                        },
                                        AvatarGroupItem: {
                                            render: (Component, props, index) => {
                                                let selected = false
                                                Object.keys(this.state.selected).forEach(item => {
                                                    if (this.state.selected[item].id === props.avatar.id) {
                                                        selected = this.state.selected[item].selected
                                                    }
                                                })
                                                return (
                                                    <div key={`${index}-overridden`} className='d-flex avatar-menu'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.avatarClick(index, { id: props.avatar.id })
                                                        }}>
                                                        <AvatarPessoa {...props} size="sm" codigo={props.avatar.id} nome={props.avatar.name} noTooltip
                                                            borderColor={selected === true ? '#0747A6' : 'white'} />
                                                        <span>{props.avatar.name}</span>
                                                    </div>
                                                );
                                            },
                                        },
                                    }} /> : this.state.data.length > 0 ? <span className='avatar-group-empty'>{this.props.label ?? 'Nenhum participante adicionado'}</span> : null}
                        </>}
                    {!this.props.disableAdd ?
                        <InlineDialog
                            placement='bottom'
                            onClose={this.updateSelected}
                            content={
                                <div>
                                    <div>
                                        <Textfield
                                            placeholder='Pesquisar'
                                            value={this.state.search}
                                            onChange={e => this.setState({ search: e.target.value })}
                                            elemBeforeInput={
                                                <div className='search-icon'>
                                                    <SearchIcon size='small'></SearchIcon>
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className='avatar-select mt-1'>
                                        {this.state.data
                                            .filter(x => x.name.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0)
                                            .map((item, index) => (
                                                <Checkbox
                                                    key={`${item.id}-checkbox-person`}
                                                    color="primary"
                                                    icon={<Icon.Check className="vx-icon" size={16} />}
                                                    label={
                                                        <div key={`${index}-avatar-select`} className='d-flex'>
                                                            <AvatarPessoa codigo={item.id} nome={item.name} noTooltip />
                                                            <span>{item.name}</span>
                                                        </div>}
                                                    checked={item.checked}
                                                    onChange={e => {
                                                        item.checked = e.target.checked
                                                        this.setState({ data: [...this.state.data] })
                                                    }}
                                                />

                                            ))}
                                    </div>
                                </div>
                            }
                            isOpen={this.state.add}>
                            <Tooltip content={this.props.labelAdd ?? 'Adicionar participante'}>
                                <Button
                                    onClick={this.toogleAdd}
                                    className='ml-1 botton-add-person'
                                    iconBefore={<InviteTeamIcon />}
                                    appearance="default">
                                </Button>
                            </Tooltip>
                        </InlineDialog>
                        : null}
                </div>
            </SpotlightTarget>
        )
    }
}