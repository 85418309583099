import React, { Component } from 'react'
import { TimerUtils } from '../../../../../utility/TimerConvertUtils'
import InlineDialog from '@atlaskit/inline-dialog';
import Textfield from '@atlaskit/textfield';
import { Querys } from '../../../../../database/base';
import ButtonAtl from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross'
import CapacityLine from './CapacityLine';
import { Field } from '@atlaskit/form';
import AvatarPessoa from '../../../../../utility/AvatarPessoa';

export default class Capacity extends Component {
    state = {
        daysOffOpen: false,
        daysOff: null,
        sprintParticipants: []
    }

    dateDifference = (start, end) => {
        // Copy date objects so don't modify originals
        var s = new Date(+start);
        var e = new Date(+end);

        // Set time to midday to avoid dalight saving and browser quirks
        s.setHours(12, 0, 0, 0);
        e.setHours(12, 0, 0, 0);

        // Get the difference in whole days
        var totalDays = Math.round((e - s) / 8.64e7);

        // Get the difference in whole weeks
        var wholeWeeks = totalDays / 7 | 0;

        // Estimate business days as number of whole weeks * 5
        var days = wholeWeeks * 5;

        // If not even number of weeks, calc remaining weekend days
        if (totalDays % 7) {
            s.setDate(s.getDate() + wholeWeeks * 7);

            while (s < e) {
                s.setDate(s.getDate() + 1);

                // If day isn't a Sunday or Saturday, add to business days
                if (s.getDay() !== 0 && s.getDay() !== 6) {
                    ++days;
                }
            }
        }
        return days;
    }

    renderTotalCapacity = () => {
        let { data, sprint, participants } = this.props
        let { sprintParticipants } = this.state
        let plannedTime = 0
        data.forEach(x => {
            plannedTime += x.plannedTime
            if (x.plannedTime > 0) {
                if ((x.todo?.time ?? 0) < x.plannedTime) {
                    plannedTime -= (x.todo?.time ?? 0)
                }
            }
        })
        const start = new Date(sprint.start + " ")
        const end = new Date(sprint.end + " ")
        const daysOff = this.state.daysOff ?? sprint.daysOff
        const days = this.dateDifference(start, end) - daysOff
        let capacity = 0
        participants.forEach(item => {
            const sp = sprintParticipants?.find(x => x.personCodigo === item.id)
            let lcapacity = item.capacity * (((days - (sp?.dayOff ?? 0)) / 5) * ((sp?.percentage ?? 100) / 100))
            capacity += (lcapacity ?? 0)
        })
        const sprintCapacity = days * 8
        let mSprintTotal100 = sprintCapacity * 60
        let mUserTotal100 = capacity * 60
        let mUserPlannedTime = plannedTime
        let mUserNotUsed = mUserTotal100 - mUserPlannedTime
        let mUserExceded = 0
        if (mUserNotUsed < 0) {
            mUserNotUsed = 0
            mUserExceded = mUserPlannedTime - mUserTotal100
        }
        let percentUser = mUserTotal100 / mSprintTotal100 * 100
        return (<CapacityLine noAvatar id={0} name={'Não atribuído'}
            mUserPlannedTime={mUserPlannedTime}
            mUserTotal100={mUserTotal100}
            percentUser={percentUser}
            mUserExceded={mUserExceded}
            mUserNotUsed={mUserNotUsed}
            capacity={capacity}
        />)
    }

    renderNotAssigned = () => {
        let { data, sprint } = this.props
        let plannedTime = 0
        data.forEach(x => {
            if (!x.assigenedToID || x.assigenedToID === 0) {
                plannedTime += x.plannedTime
            }
        })

        const start = new Date(sprint.start + " ")
        const end = new Date(sprint.end + " ")

        const daysOff = this.state.daysOff ?? sprint.daysOff
        const days = this.dateDifference(start, end) - daysOff

        const capacity = 40 * (days / 5)
        const sprintCapacity = days * 8

        let mSprintTotal100 = sprintCapacity * 60
        let mUserTotal100 = capacity * 60
        let mUserPlannedTime = plannedTime
        let mUserNotUsed = mUserTotal100 - mUserPlannedTime
        let mUserExceded = 0
        if (mUserNotUsed < 0) {
            mUserNotUsed = 0
            mUserExceded = mUserPlannedTime - mUserTotal100
        }
        let percentUser = mUserTotal100 / mSprintTotal100 * 100

        return (<CapacityLine id={0} name={'Não atribuído'}
            mUserPlannedTime={mUserPlannedTime}
            mUserTotal100={mUserTotal100}
            percentUser={percentUser}
            mUserExceded={mUserExceded}
            mUserNotUsed={mUserNotUsed}
            capacity={capacity}
        />)
    }

    componentDidMount() {
        this.setState({ sprintParticipants: this.props.sprintParticipants })
    }

    render() {
        let { data, sprint, participants } = this.props
        let { sprintParticipants } = this.state
        const start = new Date(sprint.start + " ")
        const end = new Date(sprint.end + " ")
        const daysOff = this.state.daysOff ?? sprint.daysOff
        const days = this.dateDifference(start, end) - daysOff
        const sprintCapacity = days * 8

        data = (data ?? []).filter(x => x.cardType !== 'epic' && x.cardType !== 'history')

        return (<div className='sprint-capacity'>
            <div className='d-flex justify-content-between'>
                <div className='w-100'>
                    <b style={{ paddingLeft: '5px' }}>
                        Capacidade total do time
                        {daysOff > 0 && ` (${daysOff} ${daysOff > 1 ? 'dias' : 'dia'} de folga)`}
                    </b>
                    {this.renderTotalCapacity()}
                </div>
                <div className='w-100 text-right'>
                    <InlineDialog
                        placement='bottom-end'
                        onClose={() => this.setState({ daysOffOpen: false })}
                        content={
                            <React.Fragment>
                                <Field label="Dias de folga todos os participantes" isRequired name="Geral">
                                    {({ fieldProps }) => (
                                        <div></div>
                                    )}
                                </Field>
                                <Textfield
                                    type='number'
                                    placeholder='Quantidade de dias de folga'
                                    autoFocus
                                    value={daysOff}
                                    onChange={e => {
                                        let value = parseInt(e.target.value)
                                        if (isNaN(value)) value = 0
                                        this.setState({ daysOff: value })
                                        Querys.Sprint.Update(sprint.id, { ...sprint, daysOff: value })
                                    }}
                                />

                                <Field label="Dias de folga / Percentual" isRequired name="Participantes">
                                    {({ fieldProps }) => (
                                        <div></div>
                                    )}
                                </Field>
                                <div className='capacity-overflow'>
                                    {participants.map(item =>
                                        <div key={`capacity-${item.id}`} className='d-flex' style={{ marginTop: '3px' }}>
                                            <AvatarPessoa codigo={item.id} nome={item.name} key={`participant-${item.id}`} />
                                            <Textfield
                                                className='config-daysoff-text'
                                                type='number'
                                                placeholder='Quantidade de dias de folga'
                                                value={sprintParticipants?.find(x => x.personCodigo === item.id)?.dayOff ?? 0}
                                                onChange={e => {
                                                    let { sprintParticipants } = this.state
                                                    const sp = sprintParticipants.find(x => x.personCodigo === item.id)
                                                    let value = parseInt(e.target.value)
                                                    if (isNaN(value)) value = 0
                                                    if (!sp) {
                                                        sprintParticipants = [...sprintParticipants, {
                                                            personCodigo: item.id,
                                                            dayOff: value
                                                        }]
                                                    } else {
                                                        sp.dayOff = value
                                                    }
                                                    Querys.Sprint.UpdateParticipantDayOff(sprint.id, {
                                                        participant: item.id,
                                                        dayOff: value,
                                                        percentage: sprintParticipants?.find(x => x.personCodigo === item.id)?.percentage ?? 100
                                                    })
                                                    this.setState({ sprintParticipants })
                                                }}
                                            />
                                            <Textfield
                                                className='ml-1 config-daysoff-text'
                                                type='number'
                                                placeholder='Percentual'
                                                min={1} max={100}
                                                elemAfterInput={<b style={{ paddingRight: '5px' }}>%</b>}
                                                value={sprintParticipants?.find(x => x.personCodigo === item.id)?.percentage ?? 100}
                                                onChange={e => {
                                                    let { sprintParticipants } = this.state
                                                    const sp = sprintParticipants.find(x => x.personCodigo === item.id)
                                                    let value = parseInt(e.target.value)
                                                    if (isNaN(value)) value = 100
                                                    if (!sp) {
                                                        sprintParticipants = [...sprintParticipants, {
                                                            personCodigo: item.id,
                                                            percentage: value
                                                        }]
                                                    } else {
                                                        sp.percentage = value
                                                    }
                                                    Querys.Sprint.UpdateParticipantDayOff(sprint.id, {
                                                        participant: item.id,
                                                        percentage: value,
                                                        dayOff: sprintParticipants?.find(x => x.personCodigo === item.id)?.dayOff ?? 0
                                                    })
                                                    this.setState({ sprintParticipants })
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        }
                        isOpen={this.state.daysOffOpen}>
                        <ButtonAtl
                            onClick={() => this.setState({ daysOffOpen: true })}
                            className='atl-button mb-1 mr-1'
                            appearance="default">
                            Configurar capacidade
                        </ButtonAtl>
                    </InlineDialog>
                    <ButtonAtl
                        appearance='default'
                        className='atl-button mb-1'
                        iconAfter={<CrossIcon />}
                        onClick={this.props.onClose} />
                </div>
            </div>
            {participants?.map((item) => {
                const sp = sprintParticipants?.find(x => x.personCodigo === item.id)
                const capacity = item.capacity * (((days - (sp?.dayOff ?? 0)) / 5) * (sp?.percentage ?? 100) / 100)
                let plannedTime = 0
                data.filter(x => x.assigenedToID === item.id).forEach(x => {
                    plannedTime += x.plannedTime
                    if (x.plannedTime > 0) {
                        plannedTime -= (x.todo?.timeAfterSprint ?? 0)
                    }
                })
                let mSprintTotal100 = sprintCapacity * 60
                let mUserTotal100 = capacity * 60
                let mUserPlannedTime = plannedTime
                let mUserNotUsed = mUserTotal100 - mUserPlannedTime
                let mUserExceded = 0
                if (mUserNotUsed < 0) {
                    mUserNotUsed = 0
                    mUserExceded = mUserPlannedTime - mUserTotal100
                }
                let percentUser = mUserTotal100 / mSprintTotal100 * 100
                return (
                    <CapacityLine key={`capacity-line-${item.id}`} id={item.id} name={item.name}
                        mUserPlannedTime={mUserPlannedTime}
                        mUserTotal100={mUserTotal100}
                        percentUser={percentUser}
                        mUserExceded={mUserExceded}
                        mUserNotUsed={mUserNotUsed}
                        capacity={capacity}
                    />
                )
            })}
            {this.renderNotAssigned()}
        </div>)
    }
}
