const initialState = {
  chats: [],
  contacts: [],
  chatContacts: [],
  pinned: [],
  status: "active",
  filteredContacts: [],
  filteredChats: [],
  messagesNotSeen: 0,
  query: ""
}

const chats = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTACTS":
      return { ...state, chats: action.chats ?? [], contacts: action.contacts ?? [] }
    case "GET_CHAT_CONTACTS":
      return { ...state, chatContacts: action.chats ?? [] }
    case "CHAT_SEND_MESSAGE":
      let receiver = state.chatContacts.find(f => f.uid === action.data.personReceivedID)
      console.log(state.chatContacts)
      console.log(state.contacts)
      console.log(receiver)
      console.log(action)
      if (typeof receiver === 'undefined') {
        console.log('add contact')
        let getContact = state.contacts.find(f => f.uid === action.data.personReceivedID)
        //Adiciona os demias campos e insere o usuário na lista
        getContact.uid = action.data.personReceivedID
        getContact.lastMsgDate = new Date()
        getContact.system = false
        getContact.pendingMsg = 1
        const chatItem = {
          isPinned: false,
          msg: [{
            isSeen: false,
            isSent: true,
            textContent: action.data.textContent,
            time: new Date(),
          }]
        }
        let sendMsg;

        sendMsg = {
          ...state.chats,
          [action.data.personReceivedID]: chatItem
        }
        return { ...state, chatContacts: [getContact, ...state.chatContacts], chats: sendMsg }
      } else {
        const newMessage = {
          isSeen: false,
          isSent: true,
          textContent: action.data.textContent,
          time: new Date(),
          chatId: action.data.personReceivedID
        }
        const updateMessageList = {
          ...state.chats,
          [action.data.personReceivedID]: {
            ...state.chats[action.data.personReceivedID],
            msg: [...state.chats[action.data.personReceivedID].msg, newMessage]
          }
        }
        return { ...state, chats: updateMessageList }
      }
    case "ADD_CHAT_MESSAGE":
      return { ...state }
    case "CHAT_MESSAGE":
      return { ...state }
    case "CHANGE_STATUS":
      return { ...state, status: action.status }
    case "MARK_AS_SEEN":
      if (state.chats && state.chats[action.id]) {
        let countSeen = 0
        let marked = state.chats[action.id]
        console.log(action.id)
        marked !== undefined &&
          marked.msg.forEach(msg => {
            if (!msg.isSeen && !msg.isSent) {
              countSeen++
            }
            msg.isSeen = true
          })
        Object.keys(state.chats).forEach(x => {
          state.chats[x].isPinned = false
        })
        marked.isPinned = true
        state.messagesNotSeen -= countSeen
        setTimeout(() => {
          let title = document.getElementById("titleHeader")
          if (state.messagesNotSeen === 0)
            title.innerText = `Minzi`
          else if (state.messagesNotSeen > 0)
            title.innerText = `(${state.messagesNotSeen}) Minzi`
          else title.innerText = `Minzi`
        }, 50);

      }
      return { ...state }
    case "MESSAGES_NOT_SEEN":
      setTimeout(() => {
        let title = document.getElementById("titleHeader")
        if (state.messagesNotSeen === 0)
          title.innerText = `Minzi`
        else if (state.messagesNotSeen === 0 > 0)
          title.innerText = `(${state.messagesNotSeen}) Minzi`
        else title.innerText = `Minzi`
      }, 50);
      return { ...state, messagesNotSeen: action.count }
    case "SEARCH_CONTACTS":
      if (action.query.length) {
        let filteredContacts = state.contacts.filter(contact => {
          return contact.displayName
            .toLowerCase()
            .includes(action.query.toLowerCase())
        })
        let filteredChats = state.chatContacts.filter(chat => {
          return chat.displayName
            .toLowerCase()
            .includes(action.query.toLowerCase())
        })
        return { ...state, filteredContacts, filteredChats, query: action.query }
      } else {
        return { ...state }
      }
    case "SET_PINNED":
      let pinned = state.chats[action.id]
      if (pinned) {
        pinned.isPinned = action.value
        state.chatContacts.sort((a, b) => b.uid - a.uid)
        return { ...state }
      } else {
        return { ...state }
      }
    case "UPDATE_MESSAGE_COUNT":
        return { ...state, messagesNotSeen: state.messagesNotSeen + 1 }
    default:
      return { ...state }
  }
}

export default chats
