import { toast } from "react-toastify"

let lastError = ''

const showMessage = () => {
    if (lastError !== '') {
        toast.error(lastError, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000
        })
        lastError = ''
    }
    setTimeout(showMessage, 250)
}

setTimeout(showMessage, 1000)

export const ToastError = (message) => {
    if (message === " Detalhe(s): Pessoa não encontrada") {
        return
    }
    if (lastError === '') {
        lastError = message
    }
}

export const ToastSuccess = (message) => {
    /*setTimeout(() => {
        toast.success(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000
        })
    }, 50);*/
}

export const ToastWarning = (message) => {
    setTimeout(() => {
        toast.warning(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000
        })
    }, 50);
}


export const ToastSuccessTime = (message, time) => {
    setTimeout(() => {
        toast.success(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: time
        })
    }, 50);
}
