import React, { Component } from 'react'
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { toDate } from '../views/pages/tempo/Utils'
import { TimerUtils } from './TimerConvertUtils'
import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import url from '../Config'
import Axios from 'axios'
import { ToastError, ToastSuccess } from './ToastMessages'
import If from './If'

export default class ModalObservacoes extends Component {
    state = {
        prevReprovarTempo: false,
        Justificativa: '',
        aprovar: false,
        tempoSelecionado: { CodigoAtividade: 0 },
        reload: false
    }
    aprovarTempo(tempo) {
        Axios.put(`${url.RouterUrl}/api/back/tempo/alterarstatus`, {
            Codigos: [tempo.Codigo],
            Status: 'T',
            JustificativaGestor: ''
        }).then(resp => {
            if (resp.status === 200 && resp.data.status === "success") {
                this.state.observacao.forEach(x => {
                    if (x.Codigo === tempo.Codigo) {
                        x.Status = 'T'
                    }
                })
                ToastSuccess('Tempo aprovado com sucesso')
                this.setState({ ...this.state, reload: true })
            }
        }).catch(resp => {
            ToastError(resp.response.data.message)
        })
    }

    reprovarTempo() {
        const tempo = this.state.tempoSelecionadoReprovar
        Axios.put(`${url.RouterUrl}/api/back/tempo/alterarstatus`, {
            Codigos: [tempo.Codigo],
            Status: 'R',
            JustificativaGestor: this.state.Justificativa
        }).then(resp => {
            if (resp.status === 200 && resp.data.status === "success") {
                this.state.observacao.forEach(x => {
                    if (x.Codigo === tempo.Codigo) {
                        x.Status = 'R'
                        x.JustificativaGestor = this.state.Justificativa
                    }
                })
                this.setState({ ...this.state, prevReprovarTempo: false, reload: true })
                ToastSuccess('Tempo reprovador com sucesso')
            }
        }).catch(resp => {
            ToastError(resp.response.data.message)
        })
    }
    reprovarTempoPrev(tempo) {
        this.setState({ tempoSelecionadoReprovar: tempo, prevReprovarTempo: true })
    }

    componentDidUpdate = (prevProps) => {
        const newData = this.props.tempoSelecionado
        const oldData = prevProps.tempoSelecionado
        if(newData !== oldData ){
            this.setState({ tempoSelecionado: this.props.tempoSelecionado, observacao: this.props.observacao })
        }
    }



    render() {
        /*if (this.props.tempoSelecionado &&
            this.state.tempoSelecionado.CodigoAtividade !== this.props.tempoSelecionado.CodigoAtividade &&
            this.props.observacao.length === 0) {
            setTimeout(() => {
                this.setState({ observacao: [], reload: false })
            }, 0);
        }
        if (this.props.tempoSelecionado &&
            this.state.tempoSelecionado.CodigoAtividade !== this.props.tempoSelecionado.CodigoAtividade &&
            this.props.observacao.length > 0) {
            setTimeout(() => {
                this.setState({ tempoSelecionado: this.props.tempoSelecionado, observacao: this.props.observacao })
            }, 0);
        }*/
        let { observacao } = this.state
        observacao = observacao ?? []
        observacao.sort((a, b) => a.Codigo - b.Codigo)

        const itens = []
        let somaTempo = 0
        observacao.forEach(x => {
            const tempo = TimerUtils.HoraMinuto.MinutosToHoraMinuto2(
                x.TempoDe > x.TempoAte ?
                    x.TempoDe - x.TempoAte :
                    x.TempoAte - x.TempoDe)

            somaTempo -= x.TempoDe > x.TempoAte ? x.TempoDe - x.TempoAte : 0
            somaTempo += x.TempoDe <= x.TempoAte ? x.TempoAte - x.TempoDe : 0

            if (x.Tipo === "E") {
                let observacao =
                    (x.TempoDe > x.TempoAte ? 'Reduziu ' : 'Aumentou ') + tempo
                    + ` (${TimerUtils.HoraMinuto.MinutosToHoraMinuto2(x.TempoDe)} -> ${TimerUtils.HoraMinuto.MinutosToHoraMinuto2(x.TempoAte)})`

                itens.push({
                    Codigo: x.Codigo,
                    DataInicio: x.DataFim,
                    Status: x.Status,
                    Justificativa: x.Justificativa,
                    Observacao: observacao,
                    Tipo: x.TempoDe > x.TempoAte ? 'R' : 'A',
                    tempo: somaTempo
                })
            } else {
                itens.push({
                    Codigo: x.Codigo,
                    DataInicio: x.DataInicio,
                    Status: x.Status,
                    Justificativa: x.Justificativa,
                    Observacao: 'Iniciou',
                    Tipo: 'I',
                    tempo: somaTempo - x.TempoAte
                })
                if (x.DataFim) {
                    itens.push({
                        Codigo: x.Codigo,
                        DataInicio: x.DataFim,
                        Status: x.Status,
                        Justificativa: x.Justificativa,
                        Observacao: 'Parou ' + `(${tempo})`,
                        Tipo: 'P',
                        tempo: somaTempo
                    })

                }
            }
        });

        let f = function (tipo, status) {
            if (status === 'A' || status === 'S' || status === 'T') {
                status = 'light'
            } else if (status === 'O') {
                status = 'info'
            } else if (status === 'P') {
                status = 'warning'
            } else {
                status = 'danger'
            }

            if (tipo === 'R')
                return (<div className={`timeline-icon bg-${status}`}>
                    <Icon.ArrowDown size={16} />
                </div>)
            if (tipo === 'A')
                return (<div className={`timeline-icon bg-${status}`}>
                    <Icon.ArrowUp size={16} />
                </div>)
            if (tipo === 'I')
                return (<div className={`timeline-icon bg-${status}`}>
                    <Icon.Play size={16} />
                </div>)
            if (tipo === 'P')
                return (<div className={`timeline-icon bg-${status}`}>
                    <Icon.StopCircle size={16} />
                </div>)
        }

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal}
                    toggle={() => {
                        if (this.props.reload && this.state.reload) {
                            setTimeout(() => {
                                this.props.reload()
                            }, 10);
                        }
                        this.props.toggleModal()
                    }}
                    className="modal-dialog-centered">
                    <ModalHeader toggle={() => {
                        if (this.props.reload && this.state.reload) {
                            setTimeout(() => {
                                this.props.reload()
                            }, 10);
                        }
                        this.props.toggleModal()
                    }}>
                        Detalhe completo da atividade
                    </ModalHeader>
                    <ModalBody className="mh-60 p-2">
                        <If visivel={this.state.prevReprovarTempo}>
                            <Row>
                                <Col md="12">
                                    <Row>
                                        <Col md="12">
                                            <Input type="textarea" rows='5'
                                                value={this.state.Justificativa}
                                                onChange={e => this.setState({ Justificativa: e.target.value })} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="12" className="mb-1 mt-1">
                                    <Button.Ripple color="success" onClick={() => this.reprovarTempo()}>
                                        <Icon.Save size={18} /> Salvar
                                </Button.Ripple>
                                    <Button.Ripple color="flat-primary" className='ml-1' onClick={() => this.setState({ prevReprovarTempo: false })}>
                                        <Icon.X size={18} /> Cancelar
                                </Button.Ripple>
                                </Col>
                            </Row>
                        </If>
                        <If visivel={!this.state.prevReprovarTempo}>
                            <PerfectScrollbar
                                className="content-rules"
                                options={{
                                    wheelPropagation: false
                                }}>
                                <ul className="activity-timeline timeline-left list-unstyled">
                                    {itens.map((x, index) => {
                                        let data = toDate(x.DataInicio).toISOString().split('T')[0].split('-')
                                        let time = toDate(x.DataInicio).toLocaleTimeString().split(':')
                                        time = time[0] + ":" + time[1] + ":" + time[2]
                                        data = data[2] + "/" + data[1]
                                        return (
                                            <React.Fragment key={`reprovacoes-${index}-${new Date().getTime()}`}>
                                                <li>
                                                    <div className="timeline-info-left">
                                                        {TimerUtils.HoraMinuto.MinutosToHoraMinuto(x.tempo)}
                                                    </div>
                                                    {f(x.Tipo, x.Status)}
                                                    <div className="timeline-info">
                                                        <p className="font-weight-bold mb-0">
                                                            {x.Observacao.split('\n').map((item, i) => {
                                                                return <span className='m-0 p-0' key={i + new Date().getTime()}>
                                                                    {item.length > 50 ? item.substr(0, 50) + '...' : item}<br />
                                                                </span>;
                                                            })}
                                                        </p>
                                                        {x.Justificativa ? (
                                                            <span className="font-small-3">
                                                                Justificativa: {x.Justificativa.split('\n').map((item, i) => {
                                                                return <span className='m-0 p-0' key={i + new Date().getTime()}>
                                                                    {item.length > 50 ? item.substr(0, 50) + '...' : item}<br />
                                                                </span>
                                                            })}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                    <small className="text-muted" style={{ marginRight: '0.5rem' }}>
                                                        <span style={{ marginRight: '0.5rem' }}> {data + ' ' + time}</span>
                                                        {(x.Status === 'A' || x.Status === 'S' || x.Status === 'T')
                                                            ? (<span>(Aprovado)</span>)
                                                            : x.Status === 'P'
                                                                ? (<b className='info'>(Pendente)</b>)
                                                                : x.Status === 'O' ?
                                                                    (<b className='info'>(Melhorar Justificativa)</b>)
                                                                    : (<b className='danger'>(Reprovado)</b>)}
                                                    </small>
                                                    <If visivel={x.Status === 'P' && this.props.aprovar}>
                                                        <Button style={{ marginRight: '0.5rem' }} size='sm' color='success' outline className='round' onClick={() => this.aprovarTempo(x)}>
                                                            <Icon.Check size={12} />
                                                        </Button>
                                                        <Button size='sm' color='danger' outline className='round' onClick={() => this.reprovarTempoPrev(x)}>
                                                            <Icon.X size={12} />
                                                        </Button>
                                                    </If>
                                                </li>
                                            </React.Fragment>
                                        )
                                    })}
                                </ul>
                            </PerfectScrollbar>
                        </If>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}
