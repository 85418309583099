const getHoraMinutoTempo = (tempo) => {
    tempo = Math.round(tempo)
    let hora = Math.floor(tempo / 60)
    let minuto = tempo % 60
    return { hora, minuto }
}

const MinutosToHoraMinuto = (tempo) => {
    
    if (tempo < 0)
        return '0:00'
    let c = getHoraMinutoTempo(tempo)
    c.minuto = ('00' + c.minuto)
    if (c.hora === 0 && c.minuto === 0)
        return '0:00'
    return `${c.hora}:${c.minuto.substr(c.minuto.length - 2, c.minuto.length)}`
}

const MinutosToHoraMinuto2 = (tempo) => {
    if (tempo < 0)
        return '0h00m'
    let c = getHoraMinutoTempo(tempo)
    c.minuto = ('00' + c.minuto)
    if (c.hora === 0 && c.minuto === 0)
        return '0h00m'
    return `${c.hora}h${c.minuto.substr(c.minuto.length - 2, c.minuto.length)}m`
}


const DataToHoraMinuto = (data) => {
    let hora = data.getHours()
    let minuto = ('00' + data.getMinutes())
    return `${hora}:${minuto.substr(minuto.length - 2, minuto.length)}`
}

const AtualizaHoraMinuto = (data, tempo) => {
    const c = getHoraMinutoTempo(tempo)
    data.setHours(c.hora)
    data.setMinutes(c.minuto)
    data.setSeconds(0)
    return data
}

const tempoToMinutos = (tempo) => {
    let hora, minuto
    if (tempo.indexOf(':') >= 0) {
        hora = tempo.split(':')[0]
        minuto = tempo.split(':')[1]
        if (minuto === '') {
            minuto = '0'
        }
    } else {
        hora = tempo
        minuto = '0'
    }
    hora = parseInt(hora)
    minuto = parseInt(minuto)
    if (!isNaN(hora) && !isNaN(minuto)) {
        return (hora * 60) + minuto
    } else {
        return -1
    }
}

export const TimerUtils = {
    tempoToMinutos,
    getHoraMinutoTempo,
    Data: {
        AtualizaHoraMinuto
    },
    HoraMinuto: {
        MinutosToHoraMinuto,
        MinutosToHoraMinuto2,
        DataToHoraMinuto,
        HoraToHoraMinuto: function (value) {
            const vl = value.toFixed(2).toString()
            const h = vl.split('.')[0]
            const m = parseInt(vl.split('.')[1])
            return `${h}h ${parseInt((60 * m / 100))}m`
        }
    },
}