import React, { Component } from 'react'
import Tooltip from '@atlaskit/tooltip';
import ButtonAtl from '@atlaskit/button';
import VidFullScreenOnIcon from '@atlaskit/icon/glyph/vid-full-screen-on'
import FilterIcon from '@atlaskit/icon/glyph/filter'
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down'
import HipchatChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import InlineDialog from '@atlaskit/inline-dialog';
import IndeterminateCheckbox2 from './IndeterminateCheckbox2';

const defaultFilter = {
    headersFilter: [],
    dataFilter: [],
    order: null,
    groupByProjectGroup: false,
    groupByCenterCost: false,
    groupByCenterCostGroup: false
}

export default class Actions extends Component {
    state = {
        edit: false,
        ...defaultFilter
    }

    filterHeader(headersFilter) {
        const excludeHeader = headersFilter.filter(x => !x.checked).map(x => x.label);
        const excludeData = this.state.dataFilter.filter(x => !x.checked).map(x => x.label);
        const { order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup } = this.state
        this.filter(excludeData, excludeHeader, order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup)
        this.setState({ headersFilter })
    }

    filterData(dataFilter) {
        const excludeData = dataFilter.filter(x => !x.checked).map(x => x.label);
        const excludeHeader = this.state.headersFilter.filter(x => !x.checked).map(x => x.label);
        const { order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup } = this.state
        this.filter(excludeData, excludeHeader, order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup)
        this.setState({ dataFilter })
    }

    resetFilters() {
        this.setState({ ...defaultFilter })
        this.props.onFilter(this.props.sprintID, {}).then(({ data }) => {
            this.props.onChange(data)
        })
    }

    sortAsc() {
        const { headersFilter, dataFilter, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup } = this.state
        const excludeData = dataFilter.filter(x => !x.checked).map(x => x.label);
        const excludeHeader = headersFilter.filter(x => !x.checked).map(x => x.label);
        const order = { asc: true }
        this.filter(excludeData, excludeHeader, order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup)
        this.setState({ order })
    }

    sortDesc() {
        const { headersFilter, dataFilter, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup } = this.state
        const excludeData = dataFilter.filter(x => !x.checked).map(x => x.label);
        const excludeHeader = headersFilter.filter(x => !x.checked).map(x => x.label);
        const order = { asc: false }
        this.filter(excludeData, excludeHeader, order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup)
        this.setState({ order })
    }

    filter(excludeData, excludeHeader, order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup) {
        this.props.onFilter(this.props.sprintID, { filter: { excludeHeader, excludeData }, order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup }).then(({ data }) => {
            this.props.onChange(data)
        })
    }

    changeGroup(group, value) {
        this.state.groupByProjectGroup = false
        this.state.groupByCenterCost = false
        this.state.groupByCenterCostGroup = false
        this.state[group] = value
        const { dataFilter, headersFilter, order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup } = this.state
        const excludeData = dataFilter.filter(x => !x.checked).map(x => x.label);
        const excludeHeader = headersFilter.filter(x => !x.checked).map(x => x.label);
        this.filter(excludeData, excludeHeader, order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup)
        this.setState({ ...this.state })
    }

    render() {
        let { dataFilter, headersFilter, order, groupByProjectGroup, groupByCenterCost, groupByCenterCostGroup } = this.state
        if (headersFilter.length === 0) {
            headersFilter = this.props.data?.options?.x ?? []
        }
        if (dataFilter.length === 0) {
            dataFilter = this.props.data?.options?.y ?? []
        }
        return (
            <div>

                <div className='d-flex justify-content-between'>
                    {this.props.title && !this.props.noTitle ?
                        (this.props.smallTitle ?
                            <b style={{ paddingLeft: '5px' }}>
                                {this.props.title}
                            </b> :
                            <h2>{this.props.title}</h2>) :
                        <div></div>}
                    <div className='d-flex'>
                        {(dataFilter.filter(x => !x.checked).length > 0 || headersFilter.filter(x => !x.checked).length > 0 || order !== null ||
                            groupByProjectGroup || groupByCenterCost || groupByCenterCostGroup) &&
                            <Tooltip content='Resetar filtros'>
                                <ButtonAtl
                                    style={{ marginRight: '3px' }}
                                    className='atl-button'
                                    iconAfter={<CrossIcon label="more" />}
                                    appearance='default'
                                    onClick={() => this.resetFilters()} />
                            </Tooltip>}
                        {this.props.order &&
                            <React.Fragment>
                                <Tooltip content='Ordenar ascendente'>
                                    <ButtonAtl
                                        isSelected={(this.state.order?.asc ?? null) === true}
                                        style={{ marginRight: '3px' }}
                                        className='atl-button'
                                        iconAfter={<HipchatChevronUpIcon label="more" />}
                                        appearance='default'
                                        onClick={() => this.sortAsc()} />
                                </Tooltip>
                                <Tooltip content='Ordenar descendente'>
                                    <ButtonAtl
                                        isSelected={(this.state.order?.asc ?? null) === false}
                                        style={{ marginRight: '3px' }}
                                        className='atl-button'
                                        iconAfter={<HipchatChevronDownIcon label="more" />}
                                        appearance='default'
                                        onClick={() => this.sortDesc()} />
                                </Tooltip>
                            </React.Fragment>}
                        {this.props.filter && <React.Fragment>
                            <Tooltip content='Filtrar'>
                                <InlineDialog
                                    isOpen={this.state.edit}
                                    placement='bottom-end'
                                    onClose={() => this.setState({ edit: false })}
                                    content={
                                        <div className='d-flex filter-insights-dialog'>
                                            <div>
                                                <IndeterminateCheckbox2 data={headersFilter} onChange={(data) => {
                                                    this.filterHeader(data)
                                                }} />
                                            </div>
                                            <div>
                                                <IndeterminateCheckbox2 data={dataFilter} onChange={(data) => {
                                                    this.filterData(data)
                                                }} />
                                            </div>
                                        </div>
                                    }>
                                    <ButtonAtl
                                        style={{ marginRight: '3px' }}
                                        className='atl-button'
                                        iconAfter={<FilterIcon label="more" />}
                                        appearance='default'
                                        onClick={() => this.setState({ edit: true })} />
                                </InlineDialog>

                            </Tooltip>
                        </React.Fragment>}
                        {this.props.expand && <Tooltip content='Expandir'>
                            <ButtonAtl
                                className='atl-button'
                                iconAfter={<VidFullScreenOnIcon label="more" />}
                                appearance='default'
                                onClick={this.props.onExpand} />
                        </Tooltip>}
                    </div>
                </div>
                {this.props.groupBy &&
                    <div className='d-flex justify-content-between'>
                        <div></div>
                        <div className='d-flex mb-1 mt-1'>
                            <b className='m-auto-top mr-1'>Agrupar por:</b>
                            <ButtonAtl
                                isSelected={this.state.groupByProjectGroup}
                                style={{ marginRight: '3px' }}
                                className='atl-button'
                                appearance='default'
                                onClick={() => this.changeGroup('groupByProjectGroup', !this.state.groupByProjectGroup)}>
                                Grupo de Projeto
                            </ButtonAtl>
                            <ButtonAtl
                                isSelected={this.state.groupByCenterCost}
                                style={{ marginRight: '3px' }}
                                className='atl-button'
                                appearance='default'
                                onClick={() => this.changeGroup('groupByCenterCost', !this.state.groupByCenterCost)}>
                                Centro de Custo
                            </ButtonAtl>
                            <ButtonAtl
                                isSelected={this.state.groupByCenterCostGroup}
                                className='atl-button'
                                appearance='default'
                                onClick={() => this.changeGroup('groupByCenterCostGroup', !this.state.groupByCenterCostGroup)}>
                                Grupo de Centro de Custo
                            </ButtonAtl>
                        </div>
                    </div>}
            </div>
        )
    }
}
