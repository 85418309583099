import React, { Component } from 'react'
import BaseComponentSuricatoo, { Translate } from './BaseComponentSuricatoo'
import { injectIntl } from 'react-intl'
class If extends BaseComponentSuricatoo {
    render() {
        if (!this.props.visivel) return (null)
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )
    }
}
export default If