import React from 'react'
import moment from 'moment-timezone'
import { calcTime, formatHoursMinutes } from "./timeLegend"

const paymentsConfigs = (s, preferencesConfig) => {
    return  s.status === "A" || s.status === "S" ||
    (s.status === "P" && s.Justificativas.filter( j => preferencesConfig && preferencesConfig.DONT_PAY_CONTESTEDS ? j.Status !== "J" && j.Status !== "R" : j.Status !== "R")
       ).length > 0 
}

export {
    paymentsConfigs
}