import React, { Component } from 'react'
import ButtonAtl from '@atlaskit/button';
import StarLargeIcon from '@atlaskit/icon/glyph/star-large'
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled'
import Tooltip from '@atlaskit/tooltip';
import InlineDialog from '@atlaskit/inline-dialog';
import Textfield from '@atlaskit/textfield';
import { Querys } from '../../../../database/base';

export default class FavLink extends Component {
    state = {
        fav: false,
        open: false,
        data: [],
        link: '',
        name: '',
        tm: null
    }

    toogle = () => {
        this.setState({ open: !this.state.open })
    }

    verify = () => {
        const link = window.location.pathname
        if (this.state.link !== link) {
            const indexItem = this.state.data.findIndex(x => x.link === link)
            const fav = indexItem >= 0
            let name = ''
            if (indexItem >= 0) {
                name = this.state.data[indexItem].name
            }
            this.setState({ link, fav, name })
        }
    }

    componentDidMount() {
        Querys.Person.FavLinkList().then(({ data }) => {
            this.load(data)
        })
    }

    load(data) {
        const link = window.location.pathname
        const newData = data ?? []
        const indexItem = newData.findIndex(x => x.link === link)
        const fav = indexItem >= 0
        let name = ''
        if (indexItem >= 0) {
            name = data[indexItem].name
        }
        clearInterval(this.state.tm)
        this.setState({ data: newData, link, fav, name, tm: setInterval(this.verify, 1000), open: false })
    }

    render() {
        return (
            <div style={{ marginRight: '3px' }}>
                <InlineDialog isOpen={this.state.open}
                    onClose={this.toogle}
                    content={<div>
                        <Textfield style={{ width: '200px' }} autoFocus value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                        <div className='d-flex justify-content-between mt-1'>
                            <ButtonAtl appearance='subtle' className='atl-button' isDisabled={!this.state.fav}
                                onClick={() => {
                                    Querys.Person.FavLinkUpdate({
                                        name: this.state.name,
                                        link: window.location.pathname,
                                        action: 'remove'
                                    }).then(({ data }) => {
                                        this.load(data)
                                    })
                                }}>
                                Remover
                            </ButtonAtl>
                            <ButtonAtl appearance='primary' className='atl-button'
                                onClick={() => {
                                    Querys.Person.FavLinkUpdate({
                                        name: this.state.name,
                                        link: window.location.pathname,
                                        action: 'add'
                                    }).then(({ data }) => {
                                        this.load(data)
                                    })
                                }}>
                                Salvar
                            </ButtonAtl>
                        </div>
                    </div>}>
                    <Tooltip content='Favoritar tela'>
                        <ButtonAtl iconAfter={this.state.fav ? <StarFilledIcon size='large' primaryColor='orange' /> : <StarLargeIcon />}
                            appearance='subtle'
                            className='atl-button'
                            style={{ height: '40px', width: '40px', borderRadius: '5px', marginLeft: '3px' }}
                            onClick={this.toogle} />
                    </Tooltip>
                </InlineDialog>
            </div>
        )
    }
}
