import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import cache from "./cache/index"
import clock from "./tempo/index"
import chatReducer from "./chat/"
import trackingReducer from "./tracking/"
import kanban from "./kanban/"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  cache: cache,
  clock: clock,
  chatApp: chatReducer,
  trackingEvent: trackingReducer,
  painel: kanban
})

export default rootReducer
