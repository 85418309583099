import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import Button from '@atlaskit/button/standard-button';
import moment from 'moment'
import url from '../../../Config'
import { Querys } from '../../../database/base';
import axios from 'axios';
import async from 'react-select/async';
const avatarToken = localStorage.getItem('avatarToken')
const Font = Quill.import('formats/font');
Font.whitelist = ['Sans-Serif', 'serif', 'monospace'];
Quill.register(Font, true);

const QuillTextbox = function (props) {

  const { atValues, onSave, onCancel, value, placeholder, onChange, theme, hideBorder, focus, onOpen, cleanOnSave, source, setReference } = props;
  const reference = useRef(null);
  const [editorValue, setEditorValue] = useState(value)
  const [values, setValues] = useState(atValues)

  let { showToolbar } = props;

  const onChangeValue = () => {
    const content = reference.current?.getEditor().getContents();
    const text = reference.current?.getEditor().getText();
    if (!showToolbar) setEditorValue(editorValue)
    if (content)
      setEditorValue(content, text)
    if (onChange)
      onChange(content, text)
  };

  if (focus) {
    showToolbar = true
  }

  if (!showToolbar && reference && reference.current) reference.current.blur()

  useEffect(() => {
    if (focus) {
      if (reference.current) reference.current.focus()
    }
  }, [focus])

  useEffect(() => {
    document.addEventListener("keydown", keyPress, false)
    if (setReference) setReference(reference)
  }, []);

  function keyPress(e) {
    if (e.code === 'Escape') {
      if (onCancel) onCancel()
      setEditorValue(value)
      if (reference.current) reference.current.blur()
    }
    if (e.code === 'Enter' && e.ctrlKey) {
      const content = reference.current?.getEditor().getContents();
      if (content) onSave(content)
      else if (onCancel) onCancel()
      if (cleanOnSave) {
        setEditorValue(null)
      }
    }
  }

  const modules = {
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@', '#'],
      onSelect: useCallback((item, insertItem) => {
        insertItem(item)
      }, []),
      renderItem: useCallback((item, searchTerm) => {
        const urlSrc = `${url.RouterUrl}/api/back/pessoa/${item.id}/foto?${moment(avatarToken).format('YYYYMMDDHHmmss')}`
        return `<div class='d-flex'>
          <span>
            <img src="${urlSrc}" alt="" style="border-radius: 50%; height: 32px; width: 32px;">
          </span>
          <span style="margin-left: 3px">${item.name}</span>          
        </div>`
      }, []),
      source: useCallback((searchTerm, renderItem, mentionChar) => {
        function render(values) {
          values = values?.map(item => { return { ...item, value: item.name } })
          if (searchTerm.length === 0) {
            renderItem(values, searchTerm);
          } else if (values) {
            const matches = [];
            for (let i = 0; i < values.length; i += 1)
              if (
                values[i].name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
              )
                matches.push(values[i]);
            renderItem(matches, searchTerm);
          }
        }
        if (mentionChar === '@') {
          console.log(values)
          if (!values || values.length === 0) {
            if (source) {
              const data = source()
              if (data.length > 0) {
                render(data)
                return
              }
            }
            Querys.Person.List().then(({ data }) => {
              setValues(data)
              render(data)
            })
          } else {
            render(values)
          }
        }
      }, [])
    },

    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['link', 'image', 'video'],
      ['code-block', 'blockquote'],
      ['clean']
    ],
  };

  return (
    <>
      <div onClick={() => { if (onOpen) onOpen() }}>
        <ReactQuill
          className={'ql-editor-card' +
            (showToolbar ? '' : ' ql-hidden-toolbar') +
            (hideBorder && !showToolbar ? ' ql-hidden-borer' : '') +
            (!hideBorder && !showToolbar ? ' ql-border-top' : '')}
          theme={theme ?? 'snow'}
          value={editorValue}
          ref={reference}
          modules={modules}
          onChange={onChangeValue}
          placeholder={placeholder}
        />
      </div>
      {onSave && showToolbar &&
        <div className='mt-1'>
          <Button
            className='atl-button'
            appearance='primary'
            onClick={() => {
              onSave(editorValue)
              if (cleanOnSave) {
                setEditorValue(null)
              }
            }}
            style={{ marginRight: '5px' }}>
            Salvar
          </Button>
          <Button
            className='atl-button'
            appearance='default'
            onClick={() => {
              onCancel()
              setEditorValue(value)
              reference.current.blur()
            }}>
            Cancelar
          </Button>
        </div>}
    </>
  );
};

QuillTextbox.defaultProps = {
  showToolbar: true,
};
export default React.memo(QuillTextbox);