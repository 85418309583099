import React, { useState, useEffect } from "react"
import Tour from 'reactour';

import './styles.scss'
const  TourComponent = ({open = false}) => {
    const [isTourOpen, setIsTourOpen] = useState(open)

    useEffect(() => {
        setIsTourOpen(open)
        return () => { }
    }, [open])
    const steps = [
        {
            selector: '.main-menu',
            content: 'Este é o texto que deverá ir no menu',
        },
        {
            selector: '#project',
            content: 'Este é o texto que deverá falar do projeto',
        },
        {
            selector: '#tasks',
            content: 'Este é o texto que deverá falar da Tarefa',
        },
        {
            selector: '#time',
            content: 'Este é o texto que deverá falar da equipe',
        },
        {
            selector: '#suricatoo-helper',
            content: 'Este é o texto que deverá falar da ajuda',
        },
        {
            selector: '#suricatoo-settings',
            content: 'Este é o texto que deverá falar do menu Settings',
        },
        {
            selector: '.dropdown-user',
            content: 'Este é o texto que deverá falar do menu deslogar',
            action: () => {
                // const header = document.querySelector('.dropdown-user')
                // header.classList.add('show');
                // const menu = document.querySelector('.dropdown-menu')
                // menu.classList.add('blur');
            }
        },
       

       

       

       
        
    ]

    return(<Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        border-radius={'5px'}
    />)

}

export default TourComponent