import React, { Component } from 'react'
import { Cache } from '../cache/cache';
import {
    Spotlight,
    SpotlightTransition,
} from '@atlaskit/onboarding';
import Onboarding from './onboarding';
import { Querys } from '../../../database/base';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { N0 } from '@atlaskit/theme/colors';

export default class OnboardingManager extends Component {
    state = {
        data: null
    }
    start = () => this.props.onChange(0);
    next = () => this.props.onChange((this.props.spotlight || 0) + 1);
    back = () => this.props.onChange((this.props.spotlight || 1) - 1);
    end = () => {
        const { omboarding } = this.props
        this.props.onChange(null);
        Cache.omboarding.set({ [omboarding]: true })
    }
    componentDidMount() {
        Querys.Spotlight.Get(this.props.omboarding).then(({ data }) => {
            this.setState({ data })
        })
    }

    BuildOmboarding = (onboarding, active, next, back, end, filter) => {
        if (active === null) {
            return null;
        }
        const spotlights = onboarding.order.filter(element => {
            const s = onboarding.content[element]
            if (filter) {
                return filter(s)
            }
            return true
        }).map((element, index) => {
            const s = onboarding.content[element]
            let options = []
            if ((onboarding.order.length - 1) === index) {
                options.push({ onClick: () => end(), text: 'OK' })
                if (onboarding.order.length > 1)
                    options.push({ onClick: () => back(), text: 'Voltar' })
            } else if (index === 0) {
                options.push({ onClick: () => next(), text: 'Próximo' })
                options.push({ onClick: () => end(), text: 'Fechar' })
            } else {
                options.push({ onClick: () => next(), text: 'Próximo' })
                options.push({ onClick: () => back(), text: 'Voltar' })
                options.push({ onClick: () => end(), text: 'Fechar' })
            }

            return (<Spotlight
                testId='suricatoo-spotlight'
                css='suricatoo-spotlight'
                targetRadius={(s.radius ?? 0) || 3}
                actions={options}
                heading={s.header}
                target={element}
                key={element}
                targetBgColor={N0}>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{s.content}</ReactMarkdown>
            </Spotlight>)
        });
        return spotlights[active];
    }

    render() {
        return <>
            {this.props.suricatooButton &&
                <Onboarding
                    pulse={this.props.pulse}
                    spotlightTarget={this.props.omboarding}
                    onStart={() => this.props.onChange(0)} />}
            <SpotlightTransition>
                {this.state.data &&
                    this.BuildOmboarding(this.state.data, this.props.spotlight, this.next, this.back, this.end, this.props.filter)}
            </SpotlightTransition>
        </>

    }
}
