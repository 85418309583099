import React, { Component } from 'react'
import Button from '@atlaskit/button/standard-button';
import { Label } from 'reactstrap';
import Lozenge from '@atlaskit/lozenge';
import { Link } from 'react-router-dom';
import { Querys } from '../../../../database/base';
import TextColor from '../../../../utility/Color';
import Select from '@atlaskit/select';
import DropdownPeopleSelect from '../../projects/backlog/components/DropdownPeopleSelect';
import InlineEdit from '@atlaskit/inline-edit';
import Textfield from '@atlaskit/textfield';
import { TimerUtils } from '../../../../utility/TimerConvertUtils';
import { Field } from '@atlaskit/form';
import Tooltip from '@atlaskit/tooltip';
import { Cache } from '../../cache/cache';

export default class CardInfo extends Component {
    state = { projects: [], plannedTime: 0, sprints: [], boardID: null, tags: [], people: [], costCenters: [] }
    componentDidMount() {
        const user = Cache.user().get()
        Querys.Project.ProjectsPerson(user.codigo).then(({ data }) => this.setState({ projects: data.data ?? [] }))
        this.setState({
            plannedTime: this.props.plannedTime,
            projectID: this.props.projectID,
            boardID: this.props.boardID,
            costCenterID: this.props.costCenterID
        })
        Querys.Sprint.Me().then(({ data }) => this.setState({ sprints: data ?? [] }))
        Querys.CostCenter.List().then(({ data }) => {
            this.setState({
                costCenters: data?.filter(x => x.id > 0).map(item => ({ label: item.name, value: item.id })) ?? [],
            })
        })
    }
    loadAllProject = () => {
        Querys.Project.ListAll().then(({ data }) => this.setState({ projects: data }))
    }
    render() {
        let projectSelect = null
        let project = this.state.projects.find(x => x.Codigo === this.state.projectID)
        if (project) {
            projectSelect = {
                label: (<Lozenge
                    style={{
                        backgroundColor: project.Color,
                        color: TextColor(project.Color)
                    }}>{project.Nome}</Lozenge>),
                value: project.Codigo
            }
        }
        let sprintSelected = this.state.sprints.find(x => x.boardID === this.state.boardID)
        if (sprintSelected) {
            sprintSelected = {
                label: sprintSelected.name,
                value: sprintSelected.boardID
            }
        }
        let totalSubcards = 0
        let totalPlannedTime = 0
        if (this.props.subCards?.length > 0) {
            this.props.subCards.forEach(x => {
                if (x.todo) totalSubcards += x.todo.time
                totalPlannedTime += x.plannedTime
            })
        }
        let tags = this.props.project?.tags ?? []
        if (this.state.tags.length > 0) {
            tags = this.state.tags
        }
        return (
            <div className='card-right-info'>
                <div className='card-right-info-header'>
                    <Label><b className='card-title-area'>Informações</b></Label>
                </div>
                <div className='card-right-info-body'>
                    <table className='w-100'>
                        <tr>
                            <td><Label><b className='card-title-area'>Sprint</b></Label></td>
                            <td>
                                <div className='mt-1'>
                                    <Select
                                        isClearable
                                        className="w-100"
                                        options={this.state.sprints.map(item => {
                                            return {
                                                label: item.name,
                                                value: item.boardID,
                                            }
                                        })}
                                        value={sprintSelected}
                                        placeholder="Escolha uma sprint"
                                        onChange={e => {
                                            this.setState({ boardID: e?.value ?? null })
                                            if ((e?.value ?? null) === null)
                                                Querys.Card.UpdateFields(this.props.id, { removeSprint: true })
                                            else
                                                Querys.Card.UpdateFields(this.props.id, { boardID: e?.value ?? null })
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Label><b className='card-title-area'>Projeto</b></Label></td>
                            <td>
                                <div className='d-flex justify-content-between'>
                                    <div></div>
                                    <Field name="project-select-load">
                                        {({ fieldProps }) => <Link to='#' className='font-load-all-project' onClick={this.loadAllProject}>Carregar todos os projetos</Link>}
                                    </Field>
                                </div>
                                <div>
                                    <Select
                                        className="w-100"
                                        options={this.state.projects.map(item => {
                                            return {
                                                label: (
                                                    <Tooltip content={`${item.Nome} (${item.NomeCliente})`}>
                                                        <div className='project-select-item' style={{ backgroundColor: item.Color, color: TextColor(item.Color) }}>{item.Nome} ({item.NomeCliente})</div>
                                                    </Tooltip>
                                                ),
                                                value: item.Codigo,
                                                name: item.Nome
                                            }
                                        })}
                                        filterOption={(option, rawInput) => {
                                            return option.data.name.toLowerCase().includes(rawInput.toLowerCase())
                                        }}
                                        value={projectSelect}
                                        placeholder="Escolha o projeto"
                                        onChange={e => {
                                            this.setState({ projectID: e.value })
                                            Querys.Card.UpdateFields(this.props.id, { projectID: e.value })
                                            Querys.Project.Tags(e.value).then(({ data }) => {
                                                this.setState({ tags: data ?? [] })
                                            })
                                            Querys.Project.People(e.value).then(({ data }) => {
                                                this.setState({ people: data ?? [] })
                                            })
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Label><b className='card-title-area'>Centro de Custo</b></Label></td>
                            <td>
                                <div className='mt-1'>
                                    <Select
                                        className="w-100"
                                        options={this.state.costCenters}
                                        value={this.state.costCenters.find(x => x.value === this.state.costCenterID)}
                                        placeholder="Escolha um centro de custo"
                                        onChange={e => {
                                            this.setState({ costCenterID: e.value })
                                            Querys.Card.UpdateFields(this.props.id, { costCenterID: e.value })
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Label><b className='card-title-area'>Responsável</b></Label></td>
                            <td>
                                <div className='mt-1 cursor-pointer'>
                                    {this.props.id > 0 && <DropdownPeopleSelect noTooltip
                                        placement='bottom-start'
                                        removeResponsable
                                        id={this.props.assigenedToID}
                                        name={this.props.assigened?.name ?? 'Não atribuido'}
                                        data={this.props.projectID > 0 ? (this.state.people.length > 0 ? this.state.people : this.props.project.people) : this.props.allPeople}
                                        onUserSelected={user => {
                                            if (user === null)
                                                Querys.Card.UpdateFields(this.props.id, { removeAssignedTo: true })
                                            else
                                                Querys.Card.UpdateFields(this.props.id, { assigenedToID: user })
                                        }} />}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Label><b className='card-title-area'>Tags</b></Label></td>
                            <td>
                                <div className='mt-1'>
                                    <Select
                                        className="w-100"
                                        options={tags.map(item => {
                                            return {
                                                label: (<Lozenge style={{ backgroundColor: item.color, color: TextColor(item.color) }}>{item.tag}</Lozenge>),
                                                value: item,
                                                name: item.tag
                                            }
                                        })}
                                        filterOption={(option, rawInput) => {
                                            return option.data.name.toLowerCase().includes(rawInput.toLowerCase())
                                        }}
                                        value={this.props.tags?.map(item => {
                                            return {
                                                label: (<Lozenge style={{ backgroundColor: item.color, color: TextColor(item.color) }}>{item.tag}</Lozenge>),
                                                value: item
                                            }
                                        })}
                                        isMulti
                                        placeholder="Escolha as tags"
                                        onChange={e => {
                                            Querys.Card.UpdateTags(this.props.id, e?.map(x => x.value))
                                            this.props.onChange(e?.map(x => x.value))
                                        }}

                                    />
                                </div>
                            </td>
                        </tr>
                        {this.props.sprint && <>
                            <tr>
                                <td><Label><b className='card-title-area'>Sprint</b></Label></td>
                                <td>
                                    <Button
                                        shouldFitContainer
                                        appearance="subtle"
                                        className='atl-button'>
                                        <div style={{ textAlign: 'start' }}>
                                            <Link href='#'>Sprint 2</Link>
                                        </div>
                                    </Button>
                                </td>
                            </tr>
                        </>}
                        <tr>
                            <td><Label><b className='card-title-area'>Tempo planejado</b></Label></td>
                            <td>
                                {((this.props.subCards?.length ?? 0) === 0 || this.props.cardType === 'impediment') &&
                                    <InlineEdit
                                        defaultValue={TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.plannedTime)}
                                        editView={({ errorMessage, ...fieldProps }) => (
                                            <Textfield {...fieldProps} autoFocus />
                                        )}
                                        readView={() => (
                                            <div>
                                                <Label><b className='card-title-area'>{TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.plannedTime ?? 0)}</b></Label>
                                            </div>
                                        )}
                                        onConfirm={(value) => {
                                            Querys.Card.UpdateFields(this.props.id, { plannedTime: parseInt(TimerUtils.tempoToMinutos(value)) })
                                            this.setState({ plannedTime: parseInt(TimerUtils.tempoToMinutos(value)) })
                                        }}
                                    />}
                                {((this.props.subCards?.length ?? 0) > 0 && this.props.cardType !== 'impediment') &&
                                    <InlineEdit
                                        isEditing={false}
                                        defaultValue={TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.plannedTime)}
                                        editView={({ errorMessage, ...fieldProps }) => (
                                            <Textfield {...fieldProps} autoFocus />
                                        )}
                                        readView={() => (
                                            <div>
                                                <Label><b className='card-title-area'>{TimerUtils.HoraMinuto.MinutosToHoraMinuto(totalPlannedTime)}</b></Label>
                                            </div>
                                        )}
                                        onConfirm={(value) => {
                                            Querys.Card.UpdateFields(this.props.id, { plannedTime: parseInt(TimerUtils.tempoToMinutos(value)) })
                                            this.setState({ plannedTime: parseInt(TimerUtils.tempoToMinutos(value)) })
                                        }}
                                    />}
                            </td>
                        </tr>
                        <tr>
                            <td><Label><b className='card-title-area'>Tempo executado</b></Label></td>
                            <td>
                                <div style={{ textAlign: 'start', paddingLeft: "2px" }} className='mt-1'>
                                    <Label><b className='card-title-area'>{
                                        totalSubcards > 0 ? TimerUtils.HoraMinuto.MinutosToHoraMinuto(totalSubcards) :
                                            TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.props.todo?.time ?? 0)
                                    }</b></Label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><Label><b className='card-title-area'>Relator</b></Label></td>
                            <td>
                                <div className='mt-1 cursor-pointer'>
                                    {this.props.id > 0 && <DropdownPeopleSelect noTooltip
                                        placement='bottom-start'
                                        id={this.props.responsibleID}
                                        name={this.props.responsible?.name ?? 'Não atribuido'}
                                        data={this.props.projectID > 0 ? this.props.project.people : this.props.allPeople}
                                        onUserSelected={user => {
                                            if (user === null)
                                                Querys.Card.UpdateFields(this.props.id, { removeAssignedTo: true })
                                            else
                                                Querys.Card.UpdateFields(this.props.id, { responsibleID: user })
                                        }} />}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        )
    }
}
