import React, { Component } from 'react'
import { FormGroup, Input } from 'reactstrap';
import * as Icon from "react-feather"

import BaseComponentSuricatoo, { Translate } from '../../../utility/BaseComponentSuricatoo'
import { injectIntl } from 'react-intl';

class InputSearch extends BaseComponentSuricatoo {
    render() {
        return (
            <React.Fragment>
                <FormGroup className="position-relative input-divider-right mb-0">
                    <Input type="text" placeholder="Pesquisar"
                        value={this.props.value}
                        onChange={this.props.onChange}
                        onFocus={e => e.target.select()} />
                    <div className={`form-control-position ${this.props.searchClick ? 'cursor-pointer' :  ''}`} onClick={() => this.props.searchClick()}>
                        <Icon.Search />
                    </div>
                </FormGroup>
            </React.Fragment>
        )
    }
}
export default InputSearch