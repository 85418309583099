import React, { Component } from 'react'
import Lottie from 'react-lottie';
import { Modal } from 'reactstrap'
import * as loading from "./16640-working.json";
import './loading.css'

const homeLottie = {
    loop: true,
    autoplay: true,
    animationData: loading.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

class LoadingPost extends Component {
    render() {
        const { modal } = this.props
        return (
            <Modal
                isOpen={modal}
                className="modal-dialog-centered loading-post">
                <Lottie options={homeLottie} height={150} width={150} />
            </Modal>
        )
    }
}
export default LoadingPost