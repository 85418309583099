import axios from "axios"
import { Querys } from "../../../database/base"

export const loadKanban = (onLoaded) => {
    return dispatch => {
        Querys.Card.Kanban().then(({ data }) => {
            dispatch({ type: "LOAD", kanban: data, onLoaded })
        })
    }
}

export const newMessage = (message) => {
    return dispatch => {
        dispatch({ type: message.type, message })
    }
}

export const onChangeBoard = (message) => {
    return dispatch => {
        dispatch({ type: "ON_CHAGE_BOARD", message })
    }
}

export const onOpdateField = (id, message) => {
    return dispatch => {
        dispatch({ type: "ON_UPDATE_FIELD", id, message })
    }
}

export const onStartActivity = (cardKey, data) => {
    return dispatch => {
        Querys.Activity.Start(cardKey, data, dispatch).then(() => {
            dispatch({ type: "ON_START_ACTIVITY", cardKey })
        })
    }
}

export const onStartActivityAfter = (cardKey) => {
    return dispatch => {
        dispatch({ type: "ON_START_ACTIVITY", cardKey })
    }
}

export const onStartActivityAfterTodoID = (todoID) => {
    return dispatch => {
        dispatch({ type: "ON_START_ACTIVITY", todoID })
    }
}


export const onRemoveCard = (id, cardKey) => {
    return dispatch => {
        Querys.Card.Delete(id).then(() => {
            dispatch({ type: "ON_DELETE_CARD", cardKey })
        })
    }
}

export const onStopActivityAfterTodoID = (todoID) => {
    return dispatch => {
        dispatch({ type: "ON_STOP_ACTIVITY", todoID })
    }
}

export const onStopActivity = (cardKey) => {
    return dispatch => {
        Querys.Activity.Stop(cardKey).then(() => {
            dispatch({ type: "ON_STOP_ACTIVITY", cardKey })
        })
    }
}