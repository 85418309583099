import {del, get, post, put} from "./base"
import config from '../Config'

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/costCenter`
}

export const List = () => {
    return get(`${getUrl()}`)
}

export const Create = (data) => {
    return post(`${getUrl()}`, data)
}

export const Update = (id, data) => {
    return put(`${getUrl()}/${id}`, data)
}

export const GetByID = (id) => {
    return get(`${getUrl()}/${id}`)
}

export const Delete = (id) => {
    return del(`${getUrl()}/${id}`)
}