import React, { Component } from 'react'
import ReactDOM from 'react-dom'

export default class BaseComponentSuricatoo extends Component {
    componentDidMount() {
        this.translate()
    }
    componentDidUpdate() {
        this.translate()
    }
    translate() {
        try {
            this.translateElement('span')
            this.translateElement('th')
            this.translateElement('h1')
            this.translateElement('h2')
            this.translateElement('h3')
            this.translateElement('h4')
            this.translateElement('h5')
            this.translateElement('h5')
            this.translateElement('b')
        } catch (error) {

        }
    }
    translateElement(type) {
        const { intl } = this.props
        const node = ReactDOM.findDOMNode(this)
        const elements = node.getElementsByTagName(type)
        for (let index = 0; index < elements.length; index++) {
            try {
                const element = elements[index];
                if (element.innerText === "")
                    continue
                if (!element.attributes.messageid) {
                    element.attributes.messageid = { value: element.innerText }
                }
                element.innerText = intl.formatMessage({
                    id: element.attributes.messageid.value,
                    defaultMessage: element.innerText
                })
            } catch (error) {

            }
        }
    }
}

export function Translate(props) {
    return (<span className='translate' messageid={props.id ?? props.children}>{props.children}</span>)
}