import React, { Component } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import * as Icon from "react-feather"

import BaseComponentSuricatoo, { Translate } from '../../../utility/BaseComponentSuricatoo'
import { injectIntl } from 'react-intl'

class ButtonTooltip extends BaseComponentSuricatoo {
    render() {
        return (
            <React.Fragment>
                <Button.Ripple className={this.props.className} outline={this.props.outline} id={this.props.id} color={this.props.color} size="sm" onClick={this.props.onClick}>
                    {this.props.icon}
                </Button.Ripple>
                {/* <UncontrolledTooltip placement="bottom" target={this.props.id} >{this.props.title}</UncontrolledTooltip> */}
            </React.Fragment>
        )
    }
}
export default ButtonTooltip