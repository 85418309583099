import React, { Component } from 'react'
import Form, { Field, FormFooter, HelperMessage } from '@atlaskit/form';
import Select from '@atlaskit/select';
import Task16Icon from '@atlaskit/icon-object/glyph/task/16'
import Subtask16Icon from '@atlaskit/icon-object/glyph/subtask/16'
import Bug16Icon from '@atlaskit/icon-object/glyph/bug/16'
import Story16Icon from '@atlaskit/icon-object/glyph/story/16'
import Epic16Icon from '@atlaskit/icon-object/glyph/epic/16'
import Changes16Icon from '@atlaskit/icon-object/glyph/changes/16'

// https://www.npmjs.com/package/emoji-picker-react
export default class SelectItemType extends Component {
    render() {
        return (
            <Field label="Tipo do item" name="example-text">
                {({ fieldProps }) => (
                    <Select
                        className="w-100"
                        defaultValue={{ label: <><Task16Icon /> Tarefa</>, value: 'task' }}
                        options={[
                            { label: <><Task16Icon /> Tarefa</>, value: 'task' },
                            { label: <><Subtask16Icon /> Sub tarefa</>, value: 'subtask' },
                            { label: <><Bug16Icon /> Bug</>, value: 'bug' },
                            { label: <><Changes16Icon /> Desvio</>, value: 'impediment' },
                            { label: <><Story16Icon /> História</>, value: 'history' },
                            { label: <><Epic16Icon /> Épico</>, value: 'epic' },
                        ]}
                        onChange={e => this.props.onChange(e.value)}
                    />
                )}
            </Field>
        )
    }
}
