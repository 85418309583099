import { del, get, post, put } from "./base"
import config from '../Config'


const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `${config.ApiURL}/report/template`
}

export const List = () => {
    return get(`${getUrl()}`)
}

export const ListUser = () => {
    return get(`${getUrl()}/list/user`)
}

export const Favorite = (id, type) => {
    return put(`${getUrl()}/${id}/${type}/favorite`)
}

export const ListFav = () => {
    return get(`${getUrl()}/list/fav`)
}

export const GetByID = (id) => {
    return get(`${getUrl()}/${id}`)
}

export const Create = (data) => {
    return post(`${getUrl()}`, data)
}

export const Update = (id, data) => {
    return put(`${getUrl()}/${id}`, data)
}

export const Copy = (id) => {
    return post(`${getUrl()}/${id}/copy`)
}

export const Delete = (id) => {
    return del(`${getUrl()}/${id}`)
}

export const Filter = (data) => {
    return post(`${getUrl()}/filter`, data)
}
