import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Col, Row, Collapse } from 'reactstrap'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ButtonAtl from '@atlaskit/button';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CreateItem from './CreateItem';
import ItemLine from './ItemLine';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down'
import HipchatChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up'
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large'
import PeopleProject from './PeopleProject';
import Lozenge from '@atlaskit/lozenge';
import ShareCard from './ShareCard';
import Toggle from '@atlaskit/toggle';
import Tooltip from '@atlaskit/tooltip';
import Select from '@atlaskit/select';
import Task16Icon from '@atlaskit/icon-object/glyph/task/16'
import Subtask16Icon from '@atlaskit/icon-object/glyph/subtask/16'
import Bug16Icon from '@atlaskit/icon-object/glyph/bug/16'
import Story16Icon from '@atlaskit/icon-object/glyph/story/16'
import Epic16Icon from '@atlaskit/icon-object/glyph/epic/16'
import Changes16Icon from '@atlaskit/icon-object/glyph/changes/16'
import SprintCreateUpdate from './SprintCreateUpdate';
import { Querys } from '../../../../../database/base';
import { history } from '../../../../../history';
import SprintFinish from './SprintFinish';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import InlineDialog from '@atlaskit/inline-dialog';
import Textfield from '@atlaskit/textfield';
import { TimerUtils } from '../../../../../utility/TimerConvertUtils';
import Capacity from './Capacity';
import Checkbox from "../../../../../components/@vuexy/checkbox/CheckboxesVuexy";
import * as Icon from "react-feather"
import DownloadIcon from '@atlaskit/icon/glyph/download'
import Config from '../../../../../Config';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line'
import InsightPeopleTag from './InsightPeopleTag';
import InsightProjectPeople from './InsightProjectPeople';
import NewFeature16Icon from '@atlaskit/icon-object/glyph/new-feature/16'
import { Field } from '@atlaskit/form';
import SelectPeople from '../../../create-card/components/SelectPeople';
import ConfirmStartSprint from './ConfirmStartSprint'
import UploadIcon from '@atlaskit/icon/glyph/upload'
import ImportCards from '../../../import/importCards';
import RefreshIcon from '@atlaskit/icon/glyph/refresh'
import ModalSuricatoo from '../../../../../utility/ModalSuricatoo';
import { Cache } from '../../../cache/cache';

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? '#d7f5ff' : 'white',
    border: isDragging ? '3px dashed lightblue' : 'none',
    borderRadius: isDragging ? '5px' : '0px',


    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
});

export default class ListItem extends Component {
    state = {
        open: false,
        capacityOpen: true,
        itemShare: {},
        search: '',
        share: false,
        allPeople: [],
        toogleContent: false,
        epicFilter: 0,
        typeFilter: null,
        createSprint: false,
        finishSprint: false,
        doneItens: 0,
        pendingItens: 0,
        sprintsOpen: [],
        openCreateItem: false,
        openAddPersonCard: false,
        searchPersonCard: '',
        statusChecked: [],
        participants: null,
        peopleSearch: [],
        multiSelect: false,
        cardsSelected: [],
        report: null,
        reportGenerating: false,
        insightsProject: this.props.disableGraph ? false : true,
        insightsTag: this.props.disableGraph ? false : true,
        createDeviation: false,
        planningCreate: {
            name: '',
            plannedTime: 0,
            assigenedToID: null,
            cardKey: ''
        },
        emptyGroup: { id: 0, cardType: null, name: 'Nenhum card pai associado', noGroup: false, cardKey: '', open: true },
        groupAll: true,
        confirmOpenSprint: false,
        importCards: false
    }

    componentWillMount() {
        this.setState({
            open: this.props.opened ?? false,
            allPeople: this.props.allPeople.map(x => {
                x.selected = false
                return x
            })
        })
    }

    createSprint = () => {
        this.setState({ createSprint: true })
    }

    finishSprint = () => {
        const { data, workPlanStatus } = this.props
        let done = 0
        let pending = 0
        workPlanStatus.forEach(item => {
            (data.droppableOrder ?? []).forEach(dropName => {
                done += data.droppable[dropName].data.filter(x => x.workPlanStatusType === item.type && item.done).length
                pending += data.droppable[dropName].data.filter(x => x.workPlanStatusType === item.type && !item.done).length
            })
        });
        Querys.Sprint.SprintsOpen(this.props.project?.id ?? 0).then(({ data }) =>
            this.setState({ finishSprint: true, doneItens: done, pendingItens: pending, sprintsOpen: data ?? [] }))
    }

    filterChanged = (data) => {
        const { droppableId } = this.props
        if (this.props.onFilterChanged) {
            this.props.onFilterChanged(droppableId, data)
        }
    }

    onGroupAll = () => {
        const groupAll = !this.state.groupAll
        this.setState({ groupAll, emptyGroup: { ...this.state.emptyGroup, open: groupAll } })
        if (this.props.onGroupAll) this.props.onGroupAll(groupAll)
    }

    render() {
        const { opened, droppableId, backlog, sprint, title, search, workPlanStatus } = this.props
        const { peopleSearch } = this.state

        let list = []
        let countCards = opened ? 0 : (sprint?.countCards ?? 0)

        const newData = JSON.parse(JSON.stringify(this.props.data))
        newData.droppableOrder = newData.droppableOrder ?? []
        newData.lengthStatus = newData.lengthStatus ?? {}
        newData.droppableOrder.forEach(droppableId => {
            list.push(...this.props.data.droppable[droppableId].data ?? [])
            let dataCards = newData.droppable[droppableId].data ?? []
            if (dataCards.length > 0 && opened) {
                countCards += dataCards.length
            }
            dataCards = dataCards?.filter(x => x.name.toLowerCase().indexOf(search) >= 0 || x.cardKey.toLowerCase().indexOf(search) >= 0)
            if (peopleSearch.length > 0) {
                dataCards = dataCards?.filter(x => peopleSearch.indexOf(x.assigenedToID) >= 0)
            }
            if (this.state.peopleSearch.length > 0) {
                dataCards = dataCards?.filter(x => this.state.peopleSearch.indexOf(x.assigenedToID) >= 0)
            }
            if (this.state.epicFilter > 0) {
                dataCards = dataCards?.filter(x => x.parentCardID === this.state.epicFilter)
            }
            if (this.state.typeFilter !== null) {
                dataCards = dataCards?.filter(x => x.cardType === this.state.typeFilter)
            }
            if (this.state.statusChecked.length > 0) {
                dataCards = dataCards?.filter(x => this.state.statusChecked.findIndex(z => z.type === x.workPlanStatusType) >= 0)
            }
            newData.droppable[droppableId].data = dataCards
        })

        if (this.props.toogleContent && !this.props.onToogleContent) {
            const newDroppableOrder = []
            newData.droppableOrder.forEach(dropName => {
                newData.droppable[dropName].data = newData.droppable[dropName].data.filter(card => {
                    let ok = false
                    this.props.workPlanStatus.forEach(item => {
                        ok = ok || (item.done === this.state.toogleContent && (card.workPlanStatusType === item.type || card.workPlanStatusType === undefined))
                    });
                    return ok
                })

                if (newData.droppable[dropName].data.length > 0) {
                    newDroppableOrder.push(dropName)
                }
            })
            newData.droppableOrder = newDroppableOrder
        }

        if (this.state.statusChecked.length > 0) {
            const newDroppableOrder = []
            newData.droppableOrder.forEach(dropName => {
                if (newData.droppable[dropName].data.length === 0) {
                    return
                }
                newDroppableOrder.push(dropName)
            })
            newData.droppableOrder = newDroppableOrder
        }

        if (peopleSearch.length > 0) {
            const newDroppableOrder = []
            newData.droppableOrder.forEach(dropName => {
                if (newData.droppable[dropName].data.length === 0) {
                    return
                }
                newDroppableOrder.push(dropName)
            })
            newData.droppableOrder = newDroppableOrder
        }

        return (
            <>
                <Accordion defaultActiveKey={opened ? '0' : '1'}>
                    <Col md='12' className={`accordion-item ${backlog ?? ''} ${sprint && sprint.finished ? 'accordion-sprint-finished' : ''}`}>
                        <div className='accordion-header'>
                            <div className="d-flex justify-content-between">
                                <div className='d-flex'>
                                    <Accordion.Toggle eventKey="0" className='toogle-empty' onClick={() => {
                                        this.setState({ open: !this.state.open })
                                        if (this.props.onOpen) this.props.onOpen()
                                    }}>
                                        <div className='d-flex accordion-header-toggle'>
                                            <div>
                                                {this.state.open ? <HipchatChevronUpIcon /> : <HipchatChevronDownIcon />}
                                            </div>
                                            <div>
                                                <b>{title}</b>
                                                {sprint ?
                                                    <small>{sprint.startFormated} - {sprint.endFormated} ({countCards} cards)</small> :
                                                    <small>({countCards} cards)</small>
                                                }
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <div>
                                        <Select
                                            isClearable
                                            placeholder='Filtrar por tipo'
                                            className="filtrar-type ml-1"
                                            options={
                                                [
                                                    { label: <><Task16Icon /> Tarefa</>, value: 'task' },
                                                    { label: <><Subtask16Icon /> Sub tarefa</>, value: 'subtask' },
                                                    { label: <><Bug16Icon /> Bug</>, value: 'bug' },
                                                    { label: <><Story16Icon /> História</>, value: 'history' },
                                                    { label: <><Changes16Icon /> Desvio</>, value: 'impediment' },
                                                    { label: <><Epic16Icon /> Épico</>, value: 'epic' },
                                                ]
                                            }
                                            onChange={e => {
                                                this.filterChanged({ typeFilter: e?.value ?? null })
                                                this.setState({ typeFilter: e?.value ?? null })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {this.props.enablePeopleProject && sprint.participants &&
                                        <div className='d-flex justify-content-between mb-1'>
                                            <div></div>
                                            <PeopleProject
                                                defaultData={this.props.people}
                                                value={sprint.participants ?? []}
                                                disableAdd={this.props.disableAddPeopleProject || sprint.finished}
                                                updateSelected={(peopleCode, people) => {
                                                    this.setState({ participants: people })
                                                    Querys.Sprint.Participants(sprint.id, peopleCode)
                                                }}
                                                onAvatarClick={(peopleSearch) => {
                                                    this.setState({ peopleSearch })
                                                }} />
                                        </div>}
                                    {this.props.enablePeopleProject && sprint.listeners &&
                                        <div className='d-flex justify-content-between mb-1'>
                                            <div></div>
                                            <PeopleProject
                                                labelAdd='Adicionar interessado'
                                                label='Nenhum interessado adicionado'
                                                defaultData={this.props.people}
                                                value={sprint.listeners ?? []}
                                                disableAdd={this.props.disableAddPeopleProject || sprint.finished}
                                                updateSelected={(peopleCode, people) => {
                                                    this.setState({ listeners: people })
                                                    Querys.Sprint.Listeners(sprint.id, peopleCode)
                                                }}
                                                onAvatarClick={(peopleSearch) => {
                                                    this.setState({ peopleSearch })
                                                }} />
                                        </div>}
                                    <div className='d-flex justify-content-between'>
                                        <div
                                            className={this.props.enablePeopleProject ? 'd-flex list-item-propleProject-enabled' : 'd-flex'}>
                                            <Row className='margin-workPlanStatus-none'>
                                                {workPlanStatus
                                                    .filter(item => {
                                                        if (!this.props.toogleContent) return true
                                                        if (this.state.toogleContent)
                                                            return item.done
                                                        return !item.done
                                                    })
                                                    .map((item, index) => {
                                                        let indexCheck = this.state.statusChecked.findIndex(x => x.id === item.id)
                                                        return (
                                                            <div
                                                                style={{
                                                                    marginLeft: '5px',
                                                                    marginTop: this.props.toogleContent ? '5px' : '0px',
                                                                    backgroundColor: indexCheck >= 0 ? '#0747A6' : 'transparent',
                                                                    borderRadius: '5px',
                                                                    paddingTop: `1px`,
                                                                    paddingLeft: `3px`,
                                                                    paddingRight: `3px`,
                                                                    cursor: 'pointer'
                                                                }}
                                                                key={`itens-count-${index}`}
                                                                onClick={() => {
                                                                    let index = this.state.statusChecked.findIndex(x => x.id === item.id)
                                                                    if (index >= 0)
                                                                        this.state.statusChecked.splice(index, 1)
                                                                    else
                                                                        this.state.statusChecked = [...this.state.statusChecked, item]
                                                                    this.filterChanged({ statusChecked: [...this.state.statusChecked] })
                                                                    this.setState({ statusChecked: [...this.state.statusChecked] })
                                                                }}>
                                                                <Lozenge
                                                                    appearance={
                                                                        item.color === 'in progress' ?
                                                                            'inprogress' : item.color === 'done' ? 'success' : 'default'
                                                                    }>{item.name}{(newData.lengthStatus[item.type] ?? 0) === 0 ? '' : ':'} {(newData.lengthStatus[item.type] ?? 0) === 0 ? '' : (newData.lengthStatus[item.type] ?? 0)}</Lozenge>
                                                            </div>
                                                        )
                                                    })}
                                            </Row>
                                            {this.props.toogleContent &&
                                                <Tooltip content={this.state.toogleContent ? 'Concluídos' : 'Pendentes'}>
                                                    <Toggle size="large" onChange={() => {
                                                        if (this.props.onToogleContent) this.props.onToogleContent(!this.state.toogleContent)
                                                        this.setState({ toogleContent: !this.state.toogleContent })
                                                    }} />
                                                </Tooltip>
                                            }
                                        </div>
                                        {sprint &&
                                            <React.Fragment>
                                                <div style={{ marginLeft: '5px' }}>
                                                    {!sprint.started && !sprint.finished && <ButtonAtl
                                                        appearance='primary'
                                                        className='atl-button'
                                                        isDisabled={newData.length === 0}
                                                        onClick={() => this.setState({ confirmOpenSprint: true })}
                                                    >Iniciar sprint</ButtonAtl>}
                                                    {sprint.started && !sprint.finished && <ButtonAtl
                                                        //isDisabled={sprint.responsableID !== user.codigo}
                                                        appearance='default'
                                                        className='atl-button'
                                                        onClick={this.finishSprint}
                                                    >Finalizar sprint</ButtonAtl>}
                                                </div>
                                            </React.Fragment>}

                                        {!this.props.disableInsight && sprint &&
                                            <Tooltip content='Insigts da sprint'>
                                                <ButtonAtl
                                                    style={{ marginLeft: '5px' }}
                                                    className='atl-button'
                                                    iconBefore={<GraphLineIcon />}
                                                    onClick={() => history.push(`/management/sprint/view/` + sprint.id)}
                                                    appearance="default">
                                                </ButtonAtl>
                                            </Tooltip>}

                                        {this.state.report &&
                                            <div>
                                                <Tooltip content='Clique para fazer download do relatório gerado, ele será deletado após 5m' position='bottom-end'>
                                                    <ButtonAtl
                                                        onClick={() => {
                                                            const user = Cache.user().get()
                                                            const url = `${Config.ApiURL}/card/attachment/${this.state.report.id}/${this.state.report.fileID}?token=${user.access_token}`
                                                            window.open(url, '_blank').focus();
                                                        }}
                                                        className='accordion-button-menu atl-button'
                                                        iconBefore={<DownloadIcon label="more" />}
                                                    />
                                                </Tooltip>
                                            </div>}

                                        {this.state.open && !this.props.noImport &&
                                            <Tooltip content='Importar cards'>
                                                <ButtonAtl
                                                    style={{ marginLeft: '5px' }}
                                                    className='atl-button'
                                                    iconBefore={<UploadIcon />}
                                                    appearance="default"
                                                    isSelected={this.state.importCards}
                                                    onClick={() => {
                                                        this.setState({ importCards: !this.state.importCards })
                                                    }}>
                                                </ButtonAtl>
                                            </Tooltip>}


                                        <DropdownMenu
                                            placement='bottom-end'
                                            trigger={({ triggerRef, testId, isSelected, ...props }) => (
                                                <ButtonAtl
                                                    className='accordion-button-menu atl-button'
                                                    {...props}
                                                    iconBefore={<MoreIcon label="more" />}
                                                    ref={triggerRef}
                                                />
                                            )}>
                                            <DropdownItemGroup>
                                                {sprint && !sprint.finished ?
                                                    <>
                                                        <DropdownItem onClick={() => this.setState({ createSprint: true })}>Editar</DropdownItem>
                                                        <DropdownItem onClick={() => this.setState({ capacityOpen: true })}>Capacidade</DropdownItem>
                                                        {!this.props.disableGraph && <DropdownItem onClick={() => this.setState({ insightsTag: true })}>Gráfico (tag)</DropdownItem>}
                                                        {!this.props.disableGraph && <DropdownItem onClick={() => this.setState({ insightsProject: true })}>Gráfico (projeto)</DropdownItem>}
                                                        <DropdownItem isDisabled={this.state.reportGenerating} onClick={() => {
                                                            this.setState({ reportGenerating: true })
                                                            const allData = []
                                                            this.props.data.droppableOrder.forEach(item => {
                                                                if (this.props.data.droppable[item].card.id > 0)
                                                                    allData.push(this.props.data.droppable[item].card)
                                                                allData.push(...this.props.data.droppable[item].data)
                                                            })
                                                            Querys.Card.GenerateReport(allData).then(({ data }) => {
                                                                this.setState({ report: data, reportGenerating: false })
                                                            }).catch(() => this.setState({ reportGenerating: false }))
                                                        }}>Gerar relatório</DropdownItem>
                                                        <DropdownItem onClick={() =>
                                                            Querys.Sprint
                                                                .Delete(sprint.id)
                                                                .then(this.props.onSprintCreated)
                                                        }>Excluir</DropdownItem>
                                                    </> :
                                                    <>
                                                        <DropdownItem isDisabled={this.state.reportGenerating} onClick={() => {
                                                            this.setState({ reportGenerating: true })
                                                            const allData = []
                                                            this.props.data.droppableOrder.forEach(item => {
                                                                if (this.props.data.droppable[item].card.id > 0)
                                                                    allData.push(this.props.data.droppable[item].card)
                                                                allData.push(...this.props.data.droppable[item].data)
                                                            })
                                                            Querys.Card.GenerateReport(allData).then(({ data }) => {
                                                                this.setState({ report: data, reportGenerating: false })
                                                            }).catch(() => this.setState({ reportGenerating: false }))
                                                        }
                                                        }>Gerar relatório</DropdownItem>
                                                    </>}
                                            </DropdownItemGroup>
                                        </DropdownMenu>

                                        {this.props.createPrint &&
                                            <ButtonAtl appearance='primary' className='atl-button' style={{ marginLeft: '3px' }} onClick={this.createSprint}>Criar sprint</ButtonAtl>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Accordion.Collapse eventKey="0">
                            <>
                                {this.state.importCards &&
                                    <>
                                        <ModalSuricatoo open={this.state.importCards}
                                            onClose={() => this.setState({ importCards: false })}
                                            width={document.body.clientWidth * .9}
                                            height={document.body.clientHeight * .87}
                                            autoFocus={false}
                                            header='Importar'>
                                            <ImportCards
                                                participants={this.state.participants ?? sprint?.participants ?? []}
                                                destiny={this.props.destinyImport ?? this.props.droppableId}
                                                onImported={(data) => {
                                                    this.props.onImported(data)
                                                    this.setState({ importCards: false })
                                                }} />
                                        </ModalSuricatoo>
                                    </>}
                                <Row>
                                    {!this.props.disableMultiSelect &&
                                        <Col md={(this.state.capacityOpen || this.state.insightsTag || this.state.insightsProject) && sprint ? '8' : '12'} className='d-flex justify-content-between'>
                                            <div className='d-flex mt-1 margin-select-itens'>
                                                <div className='multi-select-checkbox'>
                                                    <Tooltip content='Após o modo multiplos itens tiver habilitado, clique nos cards que deseja selecionar e clique em ENVIAR PARA.'
                                                        position='bottom-start'>
                                                        <Checkbox
                                                            color="info"
                                                            checked={this.state.multiSelect}
                                                            onChange={() => {
                                                                this.setState({ multiSelect: !this.state.multiSelect, cardsSelected: [] })
                                                                if (this.props.onDisabledDropdown) this.props.onDisabledDropdown(!this.state.multiSelect)
                                                            }}
                                                            label={`Selecionar multiplos itens ${this.state.multiSelect ? `(${this.state.cardsSelected.length} itens)` : ''}`}
                                                            icon={<Icon.Check className="vx-icon" size={16} />}
                                                        />
                                                    </Tooltip>
                                                </div>
                                                {this.state.cardsSelected.length > 0 &&
                                                    <DropdownMenu trigger={({ triggerRef, testId, isSelected, ...props }) => (
                                                        <ButtonAtl
                                                            appearance='primary'
                                                            className='atl-button ml-1'
                                                            {...props}
                                                            iconAfter={<HipchatChevronDownIcon label="more" />}
                                                            ref={triggerRef}
                                                        >
                                                            Enviar para
                                                        </ButtonAtl>
                                                    )}>
                                                        <DropdownItemGroup>
                                                            {this.props.sendTo?.filter(x => x.id !== droppableId).map((item) =>
                                                                <DropdownItem key={`dropdown-send-to-${item.id}`}
                                                                    onClick={() => {
                                                                        this.props.onSendCardsTo(droppableId, item.id, this.state.cardsSelected)
                                                                        this.setState({ multiSelect: !this.state.multiSelect, cardsSelected: [] })
                                                                    }}>{item.name}</DropdownItem>
                                                            )}
                                                        </DropdownItemGroup>
                                                    </DropdownMenu>
                                                }
                                            </div>
                                            <div className='mt-1 d-flex'>
                                                <ButtonAtl
                                                    className='atl-button'
                                                    onClick={this.onGroupAll}
                                                    iconBefore={<>
                                                        {!this.state.groupAll && <ChevronRightLargeIcon />}
                                                        {this.state.groupAll && <HipchatChevronDownIcon />}
                                                    </>}>{!this.state.groupAll ? 'Desagrupar tudo' : 'Agrupar tudo'}</ButtonAtl>
                                                {!this.props.disableReload &&
                                                    <Tooltip content='Recarregar cards'>
                                                        <ButtonAtl
                                                            className='atl-button ml-1'
                                                            onClick={this.props.onReload}
                                                            isDisabled={this.props.loading}
                                                            iconBefore={<RefreshIcon />}></ButtonAtl>
                                                    </Tooltip>
                                                }
                                            </div>
                                        </Col>}
                                    <Col md={(this.state.capacityOpen || this.state.insightsTag || this.state.insightsProject) && sprint ? '8' : '12'}>
                                        <div className='accordion-body'>
                                            {this.props.loading && <Skeleton height={40} width={'100%'} count={2} style={{ marginBottom: '3px' }} />}
                                            {!this.props.loading && <>
                                                {newData.droppableOrder.map((dropName, i) => {
                                                    const droppable = newData.droppable[dropName]
                                                    droppable.card = droppable.card.id ? droppable.card : this.state.emptyGroup
                                                    droppable.card.open = droppable.card.open ?? true
                                                    return (<div key={`dh-${dropName}-${i}`}>
                                                        <Droppable
                                                            droppableId={`${dropName}#${droppableId}`}
                                                            isDropDisabled={this.state.multiSelect}>
                                                            {(provider, snapshot) => (
                                                                <div
                                                                    key={`dh-div-${dropName}-${i}`}
                                                                    ref={provider.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                                    <>
                                                                        {!this.props.loading && newData.droppableOrder.length > 1 &&
                                                                            <ItemLine
                                                                                group
                                                                                key={`card-group-${dropName}`}
                                                                                item={droppable.card}
                                                                                {...this.props}
                                                                                onDeleteItem={null}
                                                                                onCloneItem={null}
                                                                                onRoolbackBacklog={() => this.props.onRoolbackBacklog(droppable.card, dropName)}
                                                                                onShare={() => this.setState({ share: true, itemShare: droppable.card })}
                                                                                sprint={{ ...sprint, participants: this.state.participants ?? sprint?.participants ?? [] }}
                                                                                toogle={() => {
                                                                                    droppable.card.open = !droppable.card.open
                                                                                    this.setState({ ...this.state })
                                                                                    if (this.props.onToogle) this.props.onToogle(droppable.card, dropName)
                                                                                }}
                                                                                onCloseAllTags={() => {

                                                                                }}
                                                                            />}
                                                                        {!this.props.loading && droppable.card.open && <>
                                                                            {droppable.data.length === 0 ? (
                                                                                <div className='sprint-droppable-noContent'>
                                                                                    {this.props.titleEmpty ?? 'Não há itens neste quadro.'}
                                                                                </div>
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    {droppable.data.map((item, index) => (
                                                                                        <Draggable
                                                                                            isDragDisabled={this.state.multiSelect}
                                                                                            key={`dg-item-line-${item.cardKey}`}
                                                                                            draggableId={`${item.cardKey}#${dropName}#${droppableId}`}
                                                                                            index={index}>
                                                                                            {(provided, snapshot) => (
                                                                                                <div
                                                                                                    key={`card-item-${item.cardKey}`}
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provided.draggableProps.style
                                                                                                    )}>
                                                                                                    <div>
                                                                                                        <ItemLine
                                                                                                            dropName={dropName}
                                                                                                            item={item}
                                                                                                            {...this.props}
                                                                                                            onDeleteItem={() => {
                                                                                                                this.props.onDeleteItem(item, index, dropName)
                                                                                                            }}
                                                                                                            onCloneItem={() => this.props.onCloneItem(item, dropName)}
                                                                                                            onRoolbackBacklog={() => this.props.onRoolbackBacklog(item, dropName)}
                                                                                                            onShare={() => this.setState({ share: true, itemShare: item })}
                                                                                                            sprint={{ ...sprint, participants: this.state.participants ?? sprint?.participants ?? [] }}
                                                                                                            multiSelect={this.state.multiSelect}
                                                                                                            onCardSelected={(selected, cardID) => {
                                                                                                                if (selected) {
                                                                                                                    this.setState({ cardsSelected: [...this.state.cardsSelected, `${cardID}#${dropName}`] })
                                                                                                                } else {
                                                                                                                    const index = this.state.cardsSelected.findIndex(x => x === `${cardID}#${dropName}`)
                                                                                                                    this.state.cardsSelected.splice(index, 1)
                                                                                                                    this.setState({ cardsSelected: [...this.state.cardsSelected] })
                                                                                                                }
                                                                                                            }}
                                                                                                            onCloseAllTags={() => {
                                                                                                                droppable.data.forEach(item => {
                                                                                                                    item.closeAllTags = item.closeAllTags ?? 0
                                                                                                                    item.closeAllTags++
                                                                                                                })
                                                                                                                this.setState({ ...this.state })
                                                                                                            }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </>}
                                                                    </>
                                                                    {provider.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                    )
                                                })}
                                            </>}
                                        </div>
                                        {!this.props.disableCreateItem && <CreateItem {...this.props} onChange={(value) => this.setState({ openCreateItem: value })} />}
                                        {sprint && !sprint.finished &&
                                            <div className='d-flex'>
                                                <div>
                                                    <InlineDialog
                                                        placement='top-start'
                                                        onClose={() => this.setState({ openAddPersonCard: false })}
                                                        content={
                                                            <div>
                                                                <Textfield
                                                                    className='find-card-key'
                                                                    placeholder='Pesquisar card (chave: USE-99)'
                                                                    value={this.state.searchPersonCard}
                                                                    onChange={e => this.setState({ searchPersonCard: e.target.value.toUpperCase() })}
                                                                />
                                                                <ButtonAtl
                                                                    className='atl-button mt-1 float-right'
                                                                    appearance="primary"
                                                                    onClick={() => {
                                                                        Querys.Card.GetByID(this.state.searchPersonCard)
                                                                            .then(({ data }) => {
                                                                                this.props.onCreateItem(data)
                                                                                Querys.Sprint.AddPersonalCard(sprint?.id, data.id)
                                                                            })
                                                                    }}>
                                                                    Adicionar
                                                                </ButtonAtl>
                                                            </div>
                                                        }
                                                        isOpen={this.state.openAddPersonCard}>
                                                        <ButtonAtl
                                                            onClick={() => this.setState({ openAddPersonCard: true })}
                                                            className='atl-button mt-1 mr-1'
                                                            iconBefore={<NewFeature16Icon />}
                                                            appearance="default">
                                                            Adicionar card de usuário
                                                        </ButtonAtl>
                                                    </InlineDialog>
                                                </div>
                                                <div>
                                                    <InlineDialog
                                                        placement='top-start'
                                                        onClose={() => this.setState({ createDeviation: false })}
                                                        content={
                                                            <div style={{ width: '300px' }}>
                                                                <Field label="Descrição do card" name="name">
                                                                    {({ fieldProps }) => (
                                                                        <Textfield
                                                                            {...fieldProps}
                                                                            value={this.state.planningCreate.name === null ? '' : this.state.planningCreate.name}
                                                                            onChange={e => {
                                                                                this.setState({ planningCreate: { ...this.state.planningCreate, name: e.target.value } })
                                                                            }}
                                                                            placeholder="Preencher com o descrição do card" />
                                                                    )}
                                                                </Field>
                                                                <Field label="Tempo planejado" name="example-tags">
                                                                    {({ fieldProps }) => (
                                                                        <Textfield
                                                                            appearance='standard'
                                                                            defaultValue={TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.planningCreate.plannedTime)}
                                                                            onChange={e => {
                                                                                this.setState({ planningCreate: { ...this.state.planningCreate, plannedTime: parseInt(TimerUtils.tempoToMinutos(e.target.value)) } })
                                                                            }} />
                                                                    )}
                                                                </Field>
                                                                <SelectPeople label='Responsável' isClearable
                                                                    defaultData={this.state.participants ?? sprint.participants}
                                                                    onChange={assigenedToID =>
                                                                        this.setState({ planningCreate: { ...this.state.planningCreate, assigenedToID } })
                                                                    } />
                                                                <ButtonAtl
                                                                    onClick={() => {
                                                                        Querys.Card.Create({ ...this.state.planningCreate, boardID: sprint.boardID, cardType: 'impediment', origin: 'backlog', backlog: true })
                                                                            .then(({ data }) => {
                                                                                this.props.onCreateItem(data)
                                                                                this.setState({ createDeviation: false })
                                                                            })
                                                                    }}
                                                                    className='atl-button mt-1 float-right'
                                                                    appearance="primary">
                                                                    Criar
                                                                </ButtonAtl>
                                                            </div>
                                                        }
                                                        isOpen={this.state.createDeviation}>
                                                        <ButtonAtl
                                                            onClick={() => this.setState({
                                                                createDeviation: true, planningCreate: {
                                                                    name: '',
                                                                    plannedTime: 0,
                                                                    assigenedToID: null
                                                                }
                                                            })}
                                                            className='atl-button mt-1'
                                                            iconBefore={<Changes16Icon />}
                                                            appearance="default">
                                                            Criar planejamento de desvio
                                                        </ButtonAtl>
                                                    </InlineDialog>
                                                </div>
                                            </div>}
                                    </Col>
                                    {(this.state.capacityOpen || this.state.insightsTag || this.state.insightsProject) &&
                                        <Col md='4'>
                                            <Row>
                                                <Col md='12'>
                                                    <Collapse isOpen={this.state.capacityOpen}>
                                                        {sprint && this.state.capacityOpen && <Capacity data={list} key={`capacity-${sprint.id}`}
                                                            participants={this.state.participants ?? sprint.participants}
                                                            sprint={sprint}
                                                            sprintParticipants={this.state.sprintParticipants ?? sprint.sprintParticipants}
                                                            onClose={() => this.setState({ capacityOpen: false })} />}
                                                    </Collapse>
                                                </Col>
                                                {!this.props.disableGraph &&
                                                    <React.Fragment>
                                                        <Col md='12'>
                                                            <Collapse isOpen={this.state.insightsTag}>
                                                                {sprint && this.state.insightsTag && <InsightPeopleTag data={list}
                                                                    sprint={sprint}
                                                                    onClose={() => this.setState({ insightsTag: false })} />}
                                                            </Collapse>
                                                        </Col>
                                                        <Col md='12'>
                                                            <Collapse isOpen={this.state.insightsProject}>
                                                                {sprint && this.state.insightsProject && <InsightProjectPeople data={list}
                                                                    sprint={sprint}
                                                                    onClose={() => this.setState({ insightsProject: false })} />}
                                                            </Collapse>
                                                        </Col>
                                                    </React.Fragment>}
                                            </Row>
                                        </Col>}

                                </Row>
                            </>
                        </Accordion.Collapse>
                    </Col>
                </Accordion>
                {this.state.share && <>
                    <ShareCard
                        id={this.state.itemShare.id}
                        open={this.state.share}
                        data={this.state.allPeople}
                        search={this.state.search}
                        onToogle={() => this.setState({ share: !this.state.share })}
                        onChange={e => this.setState({ search: e.target.value })}
                        onCheck={(item) => {
                            item.selected = !item.selected
                            this.setState({ ...this.state })
                        }}
                        onShared={() => this.setState({
                            share: false,
                            allPeople: this.state.allPeople.map(x => {
                                x.selected = false
                                return x
                            })
                        })} />
                </>}
                {this.state.createSprint && <>
                    <SprintCreateUpdate
                        id={sprint?.id}
                        projectID={this.props.project?.id ?? null}
                        open={this.state.createSprint}
                        toggleModal={() => this.setState({ createSprint: false })}
                        onSprintCreated={this.props.onSprintCreated}
                        sprint={sprint} />

                </>}
                {this.state.finishSprint && <>
                    <SprintFinish
                        doneCount={this.state.doneItens}
                        pendingCount={this.state.pendingItens}
                        id={sprint?.id}
                        projectID={this.props.project?.id ?? 0}
                        open={this.state.finishSprint}
                        toggleModal={() => this.setState({ finishSprint: false })}
                        onSprintFinished={() => {
                            this.setState({ finishSprint: false })
                            this.props.onSprintCreated()
                        }}
                        sprint={sprint}
                        sprintsOpen={this.state.sprintsOpen} />
                </>}

                {sprint && <ConfirmStartSprint sprint={sprint} open={this.state.confirmOpenSprint}
                    onCancel={() => {
                        this.setState({ confirmOpenSprint: false })
                    }}
                    onConfirm={() => {
                        Querys.Sprint.Start(sprint.id, sprint).then(() => history.push(`/`))
                    }} />}

            </>
        );
    }
}

export function actions(self, field) {
    return {
        onImport: (data) => {
            const droppable = self.state[field]
            const lastDroppable = droppable.droppableOrder[droppable.droppableOrder.length - 1]
            droppable.droppable[lastDroppable].data = [...droppable.droppable[lastDroppable].data, ...data]
            self.setState({ [field]: self.state[field] })
        },
        onChangeValue: (id, value, dropName) => {
            const data = self.state[field].droppable[dropName].data
            const indexCard = data.findIndex(x => x.id === id)
            data[indexCard] = { ...data[indexCard], ...value }
            self.setState({ [field]: self.state[field] })
        },
        onGroupAll: (group) => {
            const { droppableOrder, droppable } = self.state[field]
            droppableOrder.forEach(drop => {
                droppable[drop].card.open = group
            })
            self.setState({ [field]: self.state[field] })
        },
        onToogle: (card, dropName) => {
            const data = self.state[field].droppable[dropName]
            data.card.open = card.open
            self.setState({ [field]: self.state[field] })
        },
        onDeleteItem: (card, index, dropName) => {
            Querys.Card.Delete(card.id).then(() => {
                const data = self.state[field].droppable[dropName].data
                const indexCard = data.findIndex(x => x.id === card.id)
                data.splice(indexCard, 1)
                self.setState({ [field]: self.state[field] })
            })
        },
        onCloneItem: (card, dropName) => {
            Querys.Card.Clone(card.id).then(({ data }) => {
                const dropData = self.state[field].droppable[dropName].data
                dropData.push(data)
                self.setState({ [field]: self.state[field] })
            })
        },
        onUpdateAssignedTo: (id, assigenedTo, dropName) => {
            const data = self.state[field].droppable[dropName].data
            const card = data.find(x => x.id === id)
            card.assigenedToID = assigenedTo
            self.setState({ [field]: self.state[field] })
        },
        onUpdateWorkPlanStatusType: (id, workPlanStatusType, dropName) => {
            const data = self.state[field].droppable[dropName].data
            const card = data.find(x => x.id === id)
            card.workPlanStatusType = workPlanStatusType
            self.setState({ [field]: self.state[field] })
        },
        onSendCardsTo: (origin, destiny, cards) => {
            const cardsOrigin = self.state[origin]
            const cardsDestiny = self.state[destiny]

            let dropChange = []
            cards.forEach(card => {
                const cardID = parseInt(card.split('#')[0])
                const dropName = card.split('#')[1]
                dropChange.push(dropName)
                const originDrop = cardsOrigin.droppable[dropName]
                const cardIndex = originDrop.data.findIndex(x => x.id === cardID)
                const [removed] = originDrop.data.splice(cardIndex, 1)
                let dropDestiny = cardsDestiny.droppable[dropName]
                if (!dropDestiny) {
                    dropDestiny = {
                        droppableId: dropName,
                        card: originDrop.card,
                        data: []
                    }
                    cardsDestiny.droppable[dropName] = dropDestiny
                    cardsDestiny.droppableOrder.push(dropName)
                    cardsDestiny.droppableOrder.sort()
                }
                dropDestiny.data = dropDestiny.data ?? []
                dropDestiny.data.push(removed)
            })

            function onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }
            dropChange = dropChange.filter(onlyUnique)

            dropChange.forEach(dropName => {
                const items = self.state[destiny].droppable[dropName].data
                const ranks = []
                for (let index = 0; index < items.length; index++) {
                    const element = items[index];
                    element.index = index + 1
                    ranks.push({ id: element.id, index: index + 1 })
                }
                Querys.Card.Rankv2(ranks)
            })

            cards.forEach(card => {
                const cardID = parseInt(card.split('#')[0])
                Querys.Card.Board(cardID, { origin, destiny })
            })

            self.setState({ [origin]: cardsOrigin, [destiny]: cardsDestiny })
        },
        onCardCreate: (card) => {
            const droppable = self.state[field]
            let dropName = 'droppable_empty'
            let dropDestiny = droppable.droppable[dropName]
            if (!dropDestiny) {
                dropDestiny = {
                    droppableId: dropName,
                    card: {},
                    data: []
                }
                droppable.droppable[dropName] = dropDestiny
                droppable.droppableOrder.push(dropName)
                droppable.droppableOrder.sort()
            }
            dropDestiny.data[dropDestiny.data.length - 1] = card
            self.setState({ [field]: self.state[field] })
        },
        onCreateItem: (card) => {
            const droppable = self.state[field]
            let dropName = 'droppable_empty'
            let dropDestiny = droppable.droppable[dropName]
            if (!dropDestiny) {
                dropDestiny = {
                    droppableId: dropName,
                    card: {},
                    data: []
                }
                droppable.droppable[dropName] = dropDestiny
                droppable.droppableOrder.push(dropName)
                droppable.droppableOrder.sort()
            }
            if (card.id === undefined) {
                card.origin = 'card'
                card.backlog = true
                card.cardType = 'task'
                card.done = false
                card.parentCardID = self.state.parentCardID ?? null
            }
            dropDestiny.data = dropDestiny.data ?? []
            dropDestiny.data.push(card)
            self.setState({ [field]: self.state[field] })
        },
        onRoolbackBacklog: (item, dropName) => {
            Querys.Card.RoolbackToBacklog(item.id).then(() => {
                const index = self.state[field].droppable[dropName].data.findIndex(x => x.id === item.id)
                self.state[field].droppable[dropName].data.splice(index, 1)
                self.setState({ [field]: self.state[field] })
            })
        },
        onImported: (data) => {
            const dropName = 'droppable_empty'
            if (!self.state[field].droppable[dropName].data) {
                self.state[field].droppable[dropName].data = []
            }
            self.state[field].droppable[dropName].data.push(...data)
            self.setState({ [field]: self.state[field] })
        },
        onDragEnd: (result) => {
            const { source, destination, draggableId } = result;
            console.log('onDragEnd: ', source, destination, draggableId)
            if (!destination || (source.index === destination.index && source.droppableId === destination.droppableId)) {
                return;
            }

            const sourceField = source.droppableId.split('#')[1]
            const destinationField = destination.droppableId.split('#')[1]
            const sourceDropName = source.droppableId.split('#')[0]
            let destinationDropName = destination.droppableId.split('#')[0]

            const sourceData = self.state[sourceField].droppable[sourceDropName].data
            const cardIndex = sourceData.findIndex(x => x.cardKey === draggableId.split('#')[0])
            const card = sourceData[cardIndex]

            let parentCardID = null
            if (sourceDropName !== destinationDropName) {
                parentCardID = parseInt(destinationDropName.split('_')[1])
                if (sourceField === destinationField && isNaN(parentCardID))
                    parentCardID = null
            }

            if (parentCardID !== null) {
                if (parentCardID === 0) {
                    Querys.Card.UpdateFields(card.id, { removeParent: true })
                    card.parentCardID = null
                } else if (!isNaN(parentCardID)) {
                    Querys.Card.UpdateFields(card.id, { parentCardID })
                    card.parentCardID = parentCardID
                }
            }

            if (source.droppableId === destination.droppableId) {
                const items = reorder(
                    sourceData,
                    cardIndex,
                    destination.index
                );
                const ranks = []
                for (let index = 0; index < items.length; index++) {
                    const element = items[index];
                    element.index = index + 1
                    ranks.push({ id: element.id, index: index + 1 })
                }
                Querys.Card.Rankv2(ranks)
                self.state[sourceField].droppable[sourceDropName].data = items
                self.setState({ [sourceField]: self.state[sourceField] })
            } else {
                let dropDestiny = self.state[destinationField].droppable[destinationDropName]
                if (destinationDropName.indexOf('_empty') >= 0) {
                    if (sourceField !== destinationField) {
                        const dropName = "droppable_" + (card.parentCardID ?? 'empty')
                        dropDestiny = self.state[destinationField].droppable[dropName]
                        if (!dropDestiny) {
                            dropDestiny = {
                                droppableId: dropName,
                                card: card.parent ?? {},
                                data: []
                            }
                            self.state[destinationField].droppable[dropName] = dropDestiny
                            self.state[destinationField].droppableOrder.push(dropName)
                            self.state[destinationField].droppableOrder.sort()
                        }
                        destinationDropName = dropName
                        destination.droppableId = destinationDropName + "#" + destinationField
                    }
                }

                const destinyData = self.state[destinationField].droppable[destinationDropName].data ?? []
                const result = move(sourceData, destinyData, cardIndex, destination)
                self.state[destinationField].droppable[destinationDropName].data = result.destiny
                self.state[sourceField].droppable[sourceDropName].data = result.source

                self.setState({ [sourceField]: self.state[sourceField], [destinationField]: self.state[destinationField] })

                const ranks = []
                for (let index = 0; index < result.destiny.length; index++) {
                    const element = result.destiny[index];
                    ranks.push({ id: element.id, index: index + 1 })
                }
                Querys.Card.Rankv2(ranks)

                const ranks2 = []
                for (let index = 0; index < result.source.length; index++) {
                    const element = result.source[index];
                    ranks2.push({ id: element.id, index: index + 1 })
                }
                Querys.Card.Rankv2(ranks2)
                Querys.Card.Board(card.id, { origin: sourceField, destiny: destinationField })
            }
        }
    }
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, sourceIndex, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(sourceIndex, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    return { source: sourceClone, destiny: destClone };
};