import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, Col, Row, Label, Input, ModalFooter, Button, Badge, Tooltip, Alert } from 'reactstrap'
import If from '../../../utility/If'
import { FormattedMessage, injectIntl } from 'react-intl'
import BaseComponentSuricatoo, { Translate } from '../../../utility/BaseComponentSuricatoo'
import Axios from 'axios'
import url from '../../../Config'
import { ToastError } from '../../../utility/ToastMessages'
import * as Icon from "react-feather"
import Select from "react-select"
import AvatarPessoa from '../../../utility/AvatarPessoa'
import { TimerUtils } from '../../../utility/TimerConvertUtils'
import { mhora } from '../../../utility/CustomMasks'
import { timeround } from '../../../utility/timeround'
import ButtonTooltip from '../component/ButtonTooltipo'
import './time.css'
import PerfectScroll from 'react-perfect-scrollbar'
import { type } from 'jquery'
import { start } from '@popperjs/core'
import moment from 'moment-timezone'
import { replace } from 'formik'
import InputMask from "react-input-mask"

class ModalEditarTarefa extends BaseComponentSuricatoo {
    state = {
        ProjetosTarefas: [],
        ProjetoTarefaSelecionado: {
            Codigo: 0,
            Nome: 'Nenhum projeto vinculado',
            NomeCliente: '...',
            Tarefas: []
        },
        TarefaSelecionada: {
            Codigo: 0,
            Nome: 'Nenhuma tarefa para esse projeto',
        },
        Empresa: {
            Codigo: 0,
            Nome: '',
            JustificativaAprovacaoGestor: false,
            JustificativaObrigatoriaOperador: false
        },
        Tarefa: {
            Codigo: 0,
            Descricao: "",
            DataInclusao: new Date(),
            TempoTarefa: "00:00",
            TempoData: new Date(0, 0, 0, 0, 0, 0),
            Tempo: 0,
            OldTempo: 0,
            TempoAlterado: false,
            Status: 0,
            CodigoProjeto: 0,
            codigoTarefa: 0,
            codigoPessoa: 0,
            Justificativa: "",
            continueTask: false,
            Tempos: []
        },
        allProjects: [],
        modal: false,
        timesList: [{
            start: null,
            end: null,
            time: null
        }],
        showTaskTimes: false,
        timeError: [],
        checkRequired: false,
        justificatory: '',
        justificatoryIndex: null,
        running: false,
        createTask: false,
        newTaskName: '',
        newTaskTime: '',
        sleep: false
    }
    theOriginal = () => {
        return this.props.tarefa.Tempos ? this.props.tarefa.Tempos.sort((a, b) => moment(a.data_inicio) - moment(b.data_inicio)).map(m => {
            /*const startTime = m.data_inicio ? moment(m.data_inicio).format('HH:mm:ss').split(':') : null
            const endTime = m.data_fim ? moment(m.data_fim).format('HH:mm:ss').split(':') : null

            const datetime = moment(this.props.date).format('YYYY-MM-DD').toString()
            const fullDatetimeStart = startTime ? `${datetime} ${startTime[0]}:${startTime[1]}:${startTime[2]}` : null
            const fullDatetimeEnd = endTime ? `${datetime} ${endTime[0]}:${endTime[1]}:${endTime[2]}` : null
            let minutes = 0

            if(startTime && endTime){
                minutes =  Math.round(moment(fullDatetimeEnd).diff(fullDatetimeStart, 'seconds') / 60)
            } else {
                minutes =  Math.floor(moment().diff(fullDatetimeStart, 'seconds') / 60)
            }

            const reason = endTime && ((((endTime[0] * 60 * 60) + (endTime[1] * 60)) - ((startTime[0] * 60 * 60) + (startTime[1] * 60))) / 60)
            const adjustEndMinutes = minutes - reason*/
            const rounded = timeround(m.data_inicio, m.data_fim)

            return {
                id: m.Codigo,
                start: m.data_inicio ? moment.parseZone(rounded.start).format('HH:mm') : null,
                end: m.data_fim ? moment.parseZone(rounded.end).format('HH:mm') : null,
                status: m.status,
                tempo: m.tempo
            }
        }) : []

    }

    theOriginalInfo = () => {
        return {
            descricao: this.props.tarefa.Descricao,
            codigoTarefa: this.props.tarefa.CodigoTarefa,
            codigoProjeto: this.props.tarefa.CodigoProjeto
        }
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    UNSAFE_componentWillMount() {
        this.carregarProjetosTarefaUsuario()
        this.props.onRef(null)
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.modal !== this.props.modal && this.props.modal) {
            this.abrirModal()
        } else if (prevProps.modal !== this.props.modal && !this.props.modal) {
            this.fecharModal()
        }
    }
    toogleHidetask = (tarefa, hide) => {

        const findGroup = this.state.ProjetosTarefas.find(f => f.Codigo === tarefa.CodigoProjeto) &&
            this.state.ProjetosTarefas.find(f => f.Codigo === tarefa.CodigoProjeto).Tarefas &&
            this.state.ProjetosTarefas.find(f => f.Codigo === tarefa.CodigoProjeto).Tarefas.find(t => t.Codigo === tarefa.CodigoTarefa)

        console.log(`Hide: ${hide}`)
        if (typeof findGroup !== 'undefined') {
            //REMOVE A TAREFA DA LISTA CASO EXISTA
            const hideTask = this.state.ProjetosTarefas.map(p => {
                let projects = p
                projects.Tarefas = projects.Tarefas && projects.Tarefas.map(t => {
                    let task = t
                    if (task.Codigo === tarefa.CodigoTarefa) {

                        task.hide = hide
                    }
                    return task
                })
                return projects
            })
            this.setState({
                ProjetosTarefas: hideTask
            })


        } else {
            const newItem = [{
                Arquivado: tarefa.TarefaArquivada, //tarefa.Tarefa.Arquivado
                AtribuidoA: null, //PENDENTE
                AtribuidoACodigo: tarefa.Tarefa.AtribuidoA, //OK
                Codigo: tarefa.Codigo,
                CodigoLabel: tarefa.CodigoLabel,
                Executando: null, //não tem
                Nome: tarefa.NomeTarefa,
                TempoRestante: tarefa.TempoRestante,
                TempoUtilizado: tarefa.TempoUtilizado
            }]
            const addTask = this.state.ProjetosTarefas.map(p => {
                let projects = p
                if (projects.Codigo === tarefa.CodigoProjeto) {
                    projects.Tarefas = [...newItem.filter(f => projects.Tarefas.map(m => m.Codigo).includes(f.Codigo)), ...projects.Tarefas]
                }

                return projects
            })
            this.setState({
                ProjetosTarefas: addTask
            })

        }

        //ADICIONA A TAREFA NA LISTA CASO NÃO EXISTA

        /**/
    }


    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            showTaskTimes: false,
            justificatory: prevState.modal ? '' : prevState.justificatory
        }))
        this.props.fecharModal()
    }

    keyHandler = (e) => {
        if (e.key === "Enter") {
            if (e.shiftKey)
                this.handleSaveTask()
            //this.props.salvarAtividade(this.state.Tarefa, this.state.ProjetoTarefaSelecionado, this.state.TarefaSelecionada)
        }
    }

    carregarProjetosTarefaUsuario() {
        if (!this.props.user) {
            return
        }
        const { codigo } = this.props.user
        this.setState({ loading: true })
        Axios.get(`${url.RouterUrl}/api/project/user/all`)
            .then(resp => {
                const ProjetosTarefas = resp && resp.data && resp.data.length > 0 ? resp.data.map(m => {
                    return {
                        Codigo: m.Codigo,
                        CodigoCliente: m.CodigoCliente,
                        Nome: m.Nome,
                        NomeCliente: m.NomeCliente,
                        Projeto: m.Projeto,
                        Tarefas: m.Tarefas && m.Tarefas.length > 0 ? m.Tarefas : [],
                        belongsToUser: true,
                        loadtasks: true
                    }
                }) : [{
                    Codigo: 0,
                    Nome: 'Nenhum projeto vinculado',
                    NomeCliente: '...',
                    Tarefas: []
                }]

                const ProjetoTarefaSelecionado = ProjetosTarefas[0]
                const TarefaSelecionada = {
                    Codigo: 0,
                    Nome: 'Nenhuma tarefa para esse projeto'
                }

                const isProject = ProjetosTarefas && ProjetosTarefas.filter(f => f.Projeto) && ProjetosTarefas.filter(f => f.Projeto).length > 0 ? true : false
                const isSector = ProjetosTarefas && ProjetosTarefas.filter(f => !f.Projeto) && ProjetosTarefas.filter(f => !f.Projeto).length > 0 ? true : false
                const titulo = isProject && isSector ? 'Projeto / Setor' : isProject && !isSector ? 'Projeto' : 'Setor'

                this.setState({
                    ProjetosTarefas,
                    ProjetoTarefaSelecionado,
                    TarefaSelecionada,
                    titulo,
                    loading: false

                })
            }).catch(resp => {
                ToastError(resp.response.data.message)
            })
        /*Axios.get(`${url.RouterUrl}/api/back/pessoa/${codigo}/carregarprojetos`)
            .then(resp => {
                if (resp.status === 200 && resp.data.status === "success") {
                    resp.data.data = resp.data.data ?? []
                    const projeto = resp.data.data.length > 0 ? resp.data.data[0] : {
                        Codigo: 0,
                        Nome: 'Nenhum projeto vinculado',
                        NomeCliente: '...',
                        Tarefas: []
                    }
                    projeto.Tarefas = projeto.Tarefas ?? []
                    const tarefa = projeto.Tarefas.length > 0 ? projeto.Tarefas[0] : {
                        Codigo: 0,
                        Nome: 'Nenhuma tarefa para esse projeto',
                    }

                    let countProjeto = 0
                    let countSetor = 0
                    resp.data.data.forEach(x => {
                        if (x.Projeto) {
                            countProjeto++
                        } else {
                            countSetor++
                        }
                    });
                    let titulo = ''
                    if (countProjeto > 0 && countSetor > 0) {
                        titulo = 'Projeto / Setor'
                    } else if (countProjeto > 0) {
                        titulo = 'Projeto'
                    } else {
                        titulo = 'Setor'
                    }

                    this.setState({
                        ProjetosTarefas: resp.data.data,
                        ProjetoTarefaSelecionado: projeto,
                        TarefaSelecionada: tarefa,
                        titulo,
                        loading: false
                    })
                } else {
                    ToastError(resp.response.data.message)
                }
            })
            .catch(resp => {
                ToastError(resp.response.data.message)
            })*/
    }
    abrirModal() {
        let { tarefa } = this.props
        //USAREI O CÓDIGO ABAIXO PARA REFAZER O CÓDIGO ACIMA

        if (this.state.ProjetoTarefaSelecionado.Codigo === 0 && this.props.tarefa.Codigo === 0) {
            this.state.ProjetoTarefaSelecionado = this.state.ProjetoTarefaSelecionado
        } else if (this.props.tarefa.Codigo > 0 || this.props.tarefa.continueTask) {
            this.state.ProjetoTarefaSelecionado = this.props.projetoTarefaSelecionado
        }

        if (this.state.TarefaSelecionada.Codigo === 0 && this.props.tarefa.Codigo === 0) {
            this.state.TarefaSelecionada = this.state.TarefaSelecionada
        } else if (this.props.tarefa.Codigo > 0 || this.props.tarefa.continueTask) {
            this.state.TarefaSelecionada = this.props.tarefaSelecionada
        }



        tarefa.Tempos = tarefa.Tempos && tarefa.Tempos.length > 0 ? tarefa.Tempos.filter(s => s.status === "A" || s.status === "S" ||
            (s.status === "P" && s.Justificativas.filter(j => /*preferencesConfig && preferencesConfig.DONT_PAY_CONTESTEDS ? j.Status !== "J" && j.Status !== "R" :*/ j.Status !== "R")
            ).length > 0) : []


        const timesList = this.props.tarefa.Tempos ? this.props.tarefa.Tempos.sort((a, b) => moment(a.data_inicio) - moment(b.data_inicio)).map(m => {
            const rounded = timeround(m.data_inicio, m.data_fim)
            return {
                id: m.Codigo,
                start: m.data_inicio ? moment.parseZone(rounded.start).format('HH:mm') : null,
                end: m.data_fim ? moment.parseZone(rounded.end).format('HH:mm') : null,
                minutes: rounded.diff,
                date_start: moment.parseZone(rounded.start).format(),
                date_end: moment.parseZone(rounded.end).format(),
                status: m.status,
                tempo: rounded.diff,
                justifications: m.Justificativas
            }
        }) : []



        //VERIFICA SE A TAREFA ESTÁ PRESENTE NA SELECTBOX E SE NÃO ESTIVER, ADICIONA
        const newItem = tarefa && tarefa.Codigo ? [{
            Arquivado: false, //VER COM ALISSION
            AtribuidoA: tarefa && tarefa.Tarefa && tarefa.Tarefa.AtribuidoA, //VER COM ALISSON
            AtribuidoACodigo: null, //VER COM ALISSON
            Codigo: tarefa.CodigoTarefa,
            CodigoLabel: tarefa.CodigoLabel,
            Executando: null, //não tem
            Nome: tarefa.NomeTarefa,
            TempoRestante: tarefa.TempoRestante,
            TempoUtilizado: tarefa.TempoUtilizado,
            //provisory: true
        }] : []


        //VERIFICA SE O PROJETO ESTÁ NA LISTA, SE NÃO ESTIVER ADICIONA
        const projectExistis = this.state.ProjetosTarefas.find(f => f.Codigo === tarefa.CodigoProjeto)
        const criateProject = tarefa && tarefa.Codigo > 0 && typeof projectExistis === 'undefined' ? [{
            Codigo: tarefa.CodigoProjeto,
            CodigoCliente: tarefa.Clientes && tarefa.Clientes[0] && tarefa.Clientes[0].Codigo,
            Nome: tarefa.NomeProjeto,
            NomeCliente: tarefa.Clientes && tarefa.Clientes[0] && tarefa.Clientes[0].Nome,
            Projeto: true,
            Tarefas: [...newItem],
            provisory: true
        }] : []

        this.setState({
            ProjetosTarefas: [...criateProject, ...this.state.ProjetosTarefas.map(p => {
                let projects = p
                const old = p.Tarefas ? p.Tarefas : []
                const task = projects && projects.Tarefas && projects.Tarefas.find(t => t.Codigo === tarefa.CodigoTarefa)
                if (typeof task === 'undefined' && projects.Codigo === tarefa.CodigoProjeto) {
                    projects.Tarefas = [...newItem, ...old]
                } else if (!projects.Tarefas && projects.Codigo === tarefa.CodigoProjeto) {
                    projects.Tarefas = [...newItem]
                }
                return projects
            })]
        }, () => {
            const ProjetoTarefaSelecionado = this.state.ProjetosTarefas && this.state.ProjetosTarefas.length > 0 && this.state.ProjetosTarefas.find(f => f.Codigo === tarefa.CodigoProjeto) ? this.state.ProjetosTarefas.find(f => f.Codigo === tarefa.CodigoProjeto) : this.state.ProjetosTarefas && this.state.ProjetosTarefas.length > 0 ? this.state.ProjetosTarefas[0] : this.state.ProjetosTarefas
            const localStorageProject = typeof localStorage.getItem('timeModalProject') !== 'undefined' ? JSON.parse(localStorage.getItem('timeModalProject')) : null
            const PrevProjetoTarefaSelecionado = this.state.ProjetosTarefas.find(f => f.Codigo === (this.props.tarefa.Codigo > 0 && this.state.ProjetoTarefaSelecionado.Codigo ? this.state.ProjetoTarefaSelecionado.Codigo : localStorageProject && localStorageProject.Codigo))
            const selecionaProjeto = typeof PrevProjetoTarefaSelecionado !== 'undefined' ? PrevProjetoTarefaSelecionado : ProjetoTarefaSelecionado

            const TarefaSelecionada = ProjetoTarefaSelecionado && ProjetoTarefaSelecionado.Tarefas && ProjetoTarefaSelecionado.Tarefas.length > 0 && ProjetoTarefaSelecionado.Tarefas.find(f => f.Codigo === tarefa.CodigoTarefa) ? ProjetoTarefaSelecionado.Tarefas.find(f => f.Codigo === tarefa.CodigoTarefa) : ProjetoTarefaSelecionado && ProjetoTarefaSelecionado.Tarefas && ProjetoTarefaSelecionado.Tarefas.length > 0 ? ProjetoTarefaSelecionado.Tarefas[0] : this.state.TarefaSelecionada
            const localStorageTask = typeof localStorage.getItem('timeModalTask') !== 'undefined' ? JSON.parse(localStorage.getItem('timeModalTask')) : null
            const PrevTarefaSelecionada = selecionaProjeto.Tarefas.find(f => f.Codigo === (this.props.tarefa.Codigo > 0 && TarefaSelecionada.Codigo ? TarefaSelecionada.Codigo : localStorageTask && localStorageTask.Codigo))

            this.setState({
                errorMessage: '',
                timeError: [],
                modal: true,
                Tarefa: this.props.tarefa,
                timesList: timesList,
                originalTimesList: timesList,
                Empresa: this.props.empresa,
                ProjetoTarefaSelecionado: selecionaProjeto,
                TarefaSelecionada: typeof PrevTarefaSelecionada !== 'undefined' ? PrevTarefaSelecionada : TarefaSelecionada
            }, () => {
                //CASO A TAREFA A SER PRÉ-CARREGADA SEJA PLANEJADA
                if (PrevTarefaSelecionada && PrevTarefaSelecionada.Codigo > 0) {
                    // this.checkTaskIsRuning(this.state.TarefaSelecionada.Codigo)
                }
                if (typeof projectExistis === 'undefined' || (typeof projectExistis !== 'undefined' && projectExistis.loadtasks)) {
                    this.handleLoadProjectTasks(typeof PrevProjetoTarefaSelecionado !== 'undefined' ? PrevProjetoTarefaSelecionado : ProjetoTarefaSelecionado, this.state.TarefaSelecionada)
                }
            })
        })

        this.handleEditTask()

    }

    fecharModal() {
        const ProjetosTarefas = this.state.ProjetosTarefas.map(p => {
            let projects = p
            projects.Tarefas = projects.Tarefas.filter(f => !f.provisory)
            return projects
        })
        this.setState({
            modal: false,
            running: false,
            //ProjetosTarefas: ProjetosTarefas.filter( f => !f.provisory ),
            //TarefaSelecionada: this.state.TarefaSelecionada && this.state.TarefaSelecionada.provisoty ? ProjetosTarefas[0] : this.state.TarefaSelecionada
            ProjetosTarefas: ProjetosTarefas.filter(f => !f.provisory),
            TarefaSelecionada: this.state.TarefaSelecionada && this.state.TarefaSelecionada.provisoty ? ProjetosTarefas[0] : this.state.TarefaSelecionada
        })
    }

    setJustification(justifications, idx) {
        const justification = typeof justifications !== 'undefined' ? justifications && justifications.length > 0 && justifications.sort((a, b) => moment(a.DataCriacao) - moment(b.DataCriacao)).filter(f => !f.ManagerJustification) : []
        const justificatory = justification.length > 0 ? justification[justification.length - 1].Justification : ''
        this.setState({
            justificatory,
            justificatoryIndex: idx
        })
    }
    calculaTempo(tempo) {
        let hora, minuto
        if (tempo.indexOf(':') >= 0) {
            hora = tempo.split(':')[0]
            minuto = tempo.split(':')[1]
            if (minuto === '') {
                minuto = '0'
            }
        } else {
            hora = tempo
            minuto = '0'
        }
        hora = parseInt(hora)
        minuto = parseInt(minuto)
        if (!isNaN(hora) && !isNaN(minuto)) {
            let data = new Date()
            data.setHours(hora)
            data.setMinutes(minuto)
            this.setState({
                Tarefa: {
                    ...this.state.Tarefa,
                    TempoTarefa: tempo,
                    Tempo: (hora * 60) + minuto,
                    TempoAlterado: this.state.Tarefa.Codigo > 0 && this.state.Tarefa.OldTempo !== (hora * 60) + minuto
                }
            })
        } else {
            this.setState({
                Tarefa: {
                    ...this.state.Tarefa,
                    TempoTarefa: tempo
                }
            })
        }
    }

    /**
    * Update value in a list (CNAE LIST)
    * @param {*} idx
    * @param {*} e
    */
    onChangeList = (e, idx) => {
        let newArr = [...this.state.timesList]; // faz a cópias dos dados dos ultimos dados atualizados
        newArr[idx][e.target.name] = e.target.value; // coloca o valor atual digitado pelo usuário e coloca na posição x do array
        //ATUALIZA A HORA COMPLETA
        const timezone = timeround(newArr[idx].date_start, newArr[idx].date_end).gmt
        newArr[idx][`date_${e.target.name}`] = moment.parseZone(newArr[idx][`date_${e.target.name}`]).format(`YYYY-MM-DDT${e.target.value}:00${timezone}`)


        if (newArr[idx][`start`] && newArr[idx][`end`]) {
            const datetime = moment(this.props.date).format('YYYY-MM-DD').toString()
            const fullDatetimeStart = `${datetime} ${newArr[idx][`start`]}`
            const fullDatetimeEnd = `${datetime} ${newArr[idx][`end`]}`
            newArr[idx].tempo = Math.round(moment(fullDatetimeEnd).diff(fullDatetimeStart, 'seconds') / 60)
        } else {
            //newArr[idx].tempo =  newArr[idx].tempo
        }

        this.setState({ ...this.state, timesList: newArr }, () => this.validateTimeFields(e, idx, this.state.timesList[idx]))
    }

    handleToList = (idx) => {
        let newArr = [...this.state.timesList]; // faz a cópias dos dados dos ultimos dados atualizados
        //

        let GMT = moment.parseZone(this.props.date).toString().split('GMT')[1]
        GMT = `${GMT.substr(0, 3)}:${GMT.substr(GMT.length - 2)}`

        const currDate = this.props.date.split('T')[0]

        const startTime = this.state.timesList[idx] && this.state.timesList[idx].end ? this.state.timesList[idx].end : null
        const endTime = this.state.timesList[idx + 1] && this.state.timesList[idx + 1].start ? this.state.timesList[idx + 1].start : null
        const startDate = `${currDate}T${startTime ? startTime : '00:00'}:00${GMT}`
        const endDate = `${currDate}T${endTime ? endTime : '00:00'}:00${GMT}`



        const rounded = timeround(startDate, endDate)
        console.log(rounded)
        console.log(startDate)
        /*return {
            id: m.Codigo,
            start: m.data_inicio ? moment.parseZone(rounded.start).format('HH:mm') : null,
            end: m.data_fim ?  moment.parseZone(rounded.end).format('HH:mm') : null,
            minutes: rounded.diff,
            date_start:  moment.parseZone(rounded.start).format(),
            date_end:  moment.parseZone(rounded.end).format(),
            status: m.status ,
            tempo: rounded.diff,
            justifications: m.Justificativas
        }*/



        newArr.splice(idx + 1, 0, {
            start: startTime,
            newField: true,
            //start: null,
            end: endTime,
            tempo: rounded.diff,
            date_start: moment.parseZone(rounded.start).format(),
            date_end: moment.parseZone(rounded.end).format(),
        })




        this.setState({ timesList: newArr })
        //this.setState({ ...this.state, timesList: newArr })
    }
    /**
     * Remove CNAE from LIST by index
     * @param {*} idx
     */
    handleRemoveFromList = (idx) => {
        this.state.timesList.splice(idx, 1)
        this.setState({ ...this.state });

    }

    handleEditTask = () => {

        const timesList = this.props.tarefa.Tempos ? this.props.tarefa.Tempos.sort((a, b) => moment(a.data_inicio) - moment(b.data_inicio)).map(m => {
            const rounded = timeround(m.data_inicio, m.data_fim)
            return {
                id: m.Codigo,
                start: m.data_inicio ? moment.parseZone(rounded.start).format('HH:mm') : null,
                end: m.data_fim ? moment.parseZone(rounded.end).format('HH:mm') : null,
                minutes: rounded.diff,
                date_start: moment.parseZone(rounded.start).format(),
                date_end: moment.parseZone(rounded.end).format(),
                status: m.status,
                tempo: rounded.diff,
                justifications: m.Justificativas
            }
        }) : []

        this.setState(prevState => ({
            showTaskTimes: !prevState.showTaskTimes,
            checkRequired: false,
            justificatory: prevState.showTaskTimes ? '' : prevState.justificatory,
            timesList: prevState.showTaskTimes ? timesList : this.state.timesList
        }), () => {
            if (this.state.timesList.length === 0) {
                this.handleToList(-1)
            }
        })
    }

    autoOpenEditTask = (message) => {
        if (this.state.timesList.length === 0) {
            this.handleToList(-1)
        }
        this.setState(prevState => ({
            showTaskTimes: true,
            errorMessage: message,
            errorMessage: '',
            timeError: []
        }))
    }

    autoCloseEditTask = () => {
        this.setState({
            showTaskTimes: false,
            justificatory: '',
            errorMessage: '',
            timeError: []
        })

    }

    handleLoadAllProjects = () => {
        Axios.get(`${url.RouterUrl}/api/project/list/all`)
            .then(resp => {
                if (resp.status === 200) {
                    const allProjects = resp.data.map(m => {
                        return {
                            Codigo: m.Codigo,
                            CodigoCliente: m.CodigoCliente,
                            Nome: m.Nome,
                            NomeCliente: m.NomeCliente,
                            Projeto: m.Projeto,
                            Tarefas: [],
                            belongsToUser: false,
                            loadtasks: true
                        }
                    })

                    this.setState({
                        ProjetosTarefas: [...this.state.ProjetosTarefas, ...allProjects.filter(f => !this.state.ProjetosTarefas.map(m => m.Codigo).includes(f.Codigo))],
                        viewAll: true
                    })
                } else {
                    ToastError(`Erro ao consultar projetos`)
                }
            })
            .catch(resp => ToastError(resp.response.data.message))
    }

    handleLoadProjectTasks = (selecionado, tarefaSelecionada) => {
        this.setState({ loading: true })
        Axios.get(`${url.RouterUrl}/api/project/${selecionado.Codigo}/tasks/all`)
            .then(resp => {
                if (resp.status === 200) {
                    const index = this.state.ProjetosTarefas.findIndex(f => f.Codigo === selecionado.Codigo)
                    let ProjetosTarefas = [...this.state.ProjetosTarefas];
                    let tarefas = { ...ProjetosTarefas[index] };
                    const respData = resp && resp.data ? resp.data : []
                    tarefas.Tarefas = [...tarefas.Tarefas.filter(f => !resp.data.map(m => m.Codigo).includes(f.Codigo)), ...respData];
                    tarefas.loadtasks = false;
                    ProjetosTarefas[index] = tarefas;


                    const localStorageTask = typeof localStorage.getItem('timeModalTask') !== 'undefined' ? JSON.parse(localStorage.getItem('timeModalTask')) : null
                    const PrevTarefaSelecionada = tarefas.Tarefas.find(f => f.Codigo === (tarefaSelecionada && tarefaSelecionada.Codigo ? tarefaSelecionada.Codigo : localStorageTask && localStorageTask.Codigo))

                    this.setState({
                        ProjetosTarefas,
                        ProjetoTarefaSelecionado: tarefas,
                        TarefaSelecionada: PrevTarefaSelecionada && PrevTarefaSelecionada.Codigo > 0 ? PrevTarefaSelecionada : tarefas.Tarefas.length > 0 ? tarefas.Tarefas[0] : {
                            Codigo: 0,
                            Nome: 'Nenhuma tarefa para esse projeto',
                        },
                        loading: false
                    });

                } else {
                    ToastError(`Erro ao consultar projetos`)
                }
            })
            .catch(resp => /*ToastError(resp.response.data.message)*/ null)

    }

    handlehideAllProjects = () => {
        this.setState({
            ProjetosTarefas: this.state.ProjetosTarefas.filter(f => f.belongsToUser !== false),
            viewAll: false
        })
    }

    /*NOVAS FUNCIONALIDADES DE EDIÇÃO DE TEMPOS*/
    handleBlur = (e, i) => {
        if (e.key === "Tab" && !e.shiftKey || e.key === "Enter") {
            const fieldError = this.state.timeError.filter(f => f.index === i)
            const timeRow = this.state.timesList[i]
            const haveNextLine = this.state.timesList[i + 1]
            if (fieldError.length === 0 && timeRow.start && timeRow.end && typeof haveNextLine === 'undefined') {
                this.handleToList(i)
            }
        }
        //this.validateTimeFields(e,i)

    }

    validateTimeFields = (e, i, fields) => {
        const { start, end } = this.state.timesList[i]

        let lastLineStart = this.state.timesList[i - 1] && this.state.timesList[i - 1].start
        let lastLineEnd = this.state.timesList[i - 1] && this.state.timesList[i - 1].end
        lastLineStart = typeof lastLineStart !== 'undefined' ? parseInt(lastLineStart.split(":")[0] + lastLineStart.split(":")[1]) : null
        lastLineEnd = typeof lastLineEnd !== 'undefined' ? parseInt(lastLineEnd.split(":")[0] + lastLineEnd.split(":")[1]) : null

        let nextLineStart = this.state.timesList[i + 1] && this.state.timesList[i + 1].start
        let nextLineEnd = this.state.timesList[i + 1] && this.state.timesList[i + 1].end

        nextLineStart = typeof nextLineStart !== 'undefined' && nextLineStart ? parseInt(nextLineStart.split(":")[0] + nextLineStart.split(":")[1]) : null
        nextLineEnd = typeof nextLineEnd !== 'undefined' && nextLineEnd ? parseInt(nextLineEnd.split(":")[0] + nextLineEnd.split(":")[1]) : null

        let GMT = moment(this.props.date).tz(moment.tz.guess()).toString().split('GMT')[1]
        GMT = `${GMT.substr(0, 3)}:${GMT.substr(GMT.length - 2)}`

        const startTime = start ? parseInt(start.split(":")[0] + start.split(":")[1]) : null
        const endTime = end ? parseInt(end.split(":")[0] + end.split(":")[1]) : null

        const taskDay = this.state.Tarefa && moment(this.props.date).format('YYYY-MM-DD')
        const startDatetime = start ? `${taskDay}T${start}:${moment().format('ss')}${GMT}` : null
        const endDatetime = end ? `${taskDay}T${end}:${moment().format('ss')}${GMT}` : null
        const checkTime = this.validateEditionMaxtime(i)
        const round = timeround(fields.date_start, fields.date_end)
        console.log(fields)
        console.log(fields.date_start)
        console.log(fields.date_end)
        console.log(round)
        //VERIFICA SE O HORÁRIO INICIAL É MAIOR DO QUE O HORÁRIO FINAL
        if (!checkTime) { //VERIFICA SE TRATA-SE DE UMA EDIÇÃO, PARA MAIOR, DE UMA TAREFA APROVADA
            console.log('A hora editada não pode ser maior do que a hora original')
            const errorStart = this.state.timeError.filter(f => f.index === i && f.type === 'end')
            this.setState({
                timeError: [...this.state.timeError, { index: i, type: 'end' }],
            }, this.errorMessage('A hora editada não pode ser maior do que a hora original'))
        } else if (startTime && endTime && round.diff <= 0) {
            console.log(round)
            console.log('Horário inicial maior ou igual ao horário final')
            const errorEnd = this.state.timeError.filter(f => f.index === i && f.type === 'end')
            this.setState(prevState => ({
                timeError: [...prevState.timeError, ...[{ index: i, type: 'end' }]]
            }), () => this.errorMessage('Horário inicial maior ou igual ao horário final'))
        } else if (endTime && nextLineStart && (endTime > nextLineStart)) {
            console.log(endTime)
            console.log(nextLineStart)
            //VERIFICA SE O HORÁRIO FINAL ANTERIOR É MAIOR DO QUE O HORARIO INÍCIAL POSTERIOR
            console.log('O horário final que está tentando editar é maior do que o horário inicial da linha seguinte')
            const errorEnd = this.state.timeError.filter(f => f.index === i && f.type === 'end')
            if (errorEnd.length === 0) {
                this.setState({
                    timeError: [...this.state.timeError, { index: i, type: 'end' }]
                }, () => this.errorMessage('O horário final sobrepõe o horário inicial da linha seguinte'))
            }
        } /*else if(startTime && endTime && (startTime <= endTime)) {
            console.log('remove erro no campo end')
            this.setState({
                timeError: this.state.timeError.filter( f => !(f.index === i && f.type === 'end'))
            })
        } else if(endTime && nextLineStart && (endTime <= nextLineStart)) {
            console.log('remove erro no campo end')
            const hasItem = this.state.timeError.find( f => f.index === i && f.type === 'end')
            console.log(hasItem)
            this.setState( prevState => ({
                timeError: prevState.timeError.filter( f => f !== hasItem )
            }))
        } */else if (startTime && lastLineEnd && (startTime < lastLineEnd)) { //VERIFICA SE O HORÁRIO FINAL ANTERIOR É MAIOR DO QUE O HORÁRIO INICIAL ATUAL
            console.log('O horário final da linha anterior é maior do que o horário inicial que está tentando digitar')
            const errorStart = this.state.timeError.filter(f => f.index === i && f.type === 'start')
            if (errorStart.length === 0) {
                this.setState({
                    timeError: [...this.state.timeError, { index: i, type: 'start' }]
                }, () => this.errorMessage('O horário inicial sobrepõe o horário final da linha anterior'))
            }
        } /*else if(startTime && lastLineEnd && (startTime >= lastLineEnd)) {
            console.log('remove erro no campo start')
            const hasItem = this.state.timeError.find( f => f.index === i && f.type === 'start')
            console.log(hasItem)
            this.setState( prevState => ({
                timeError: prevState.timeError.filter( f => f !== hasItem )
            }))
        }*/ else if (startTime && nextLineStart && (startTime > nextLineStart)) { //VERIFICA SE A HORA INICIAL É MAIOR DO QUE A HORA INICIAL POSTERIOR
            console.log('A hora inicial que está tentando editar é maior do que a hora inicial da linha seguinte')
            const errorStart = this.state.timeError.filter(f => f.index === i && f.type === 'start')
            if (errorStart.length === 0) {
                this.setState({
                    timeError: [...this.state.timeError, { index: i, type: 'start' }]
                }, () => this.errorMessage('Este horário sobrepõe o horário inicial da linha seguinte'))
            }
        }/* else if(startTime && nextLineStart && (startTime <= nextLineStart)) {
            console.log('remove erro no campo start')
            const hasItem = this.state.timeError.find( f => f.index === i && f.type === 'start')
            //console.log(hasItem)
            this.setState( prevState => ({
                timeError: prevState.timeError.filter( f => f !== hasItem )
            }))
        }*/else if (endTime && nextLineStart && (endTime > nextLineStart)) { //VERIFICA SE A HORA FINAL QUE ESTÁ SENDO INSERIDA É MAIOR DO QUE A HORA INICIAR DA TAREFA POSTERIOR
            console.log('Hora final digitada é maior do que a hora inicial da linha seguinte')
            const errorStart = this.state.timeError.filter(f => f.index === i && f.type === 'end')
            if (errorStart.length === 0) {
                this.setState({
                    timeError: [...this.state.timeError, { index: i, type: 'end' }]
                }, () => this.errorMessage('Horário final maior do que o horário inicial da linha seguinte'))
            }
        }/* else if(startTime && nextLineStart && (startTime <= nextLineStart)) { //VERIFICA SE O HORÁRIO QUE ESTÁ SENDO INSERIDO É MAIOR DO QUE O DATETIME ATUAL
            console.log('Hora final digitada é compatível com a hora inicial da linha seguinte')
            const hasItem = this.state.timeError.find( f => f.index === i && f.type === 'end')
            //console.log(hasItem)
            this.setState( prevState => ({
                timeError: prevState.timeError.filter( f => f !== hasItem )
            }))
        }*/ else if (endDatetime && moment().diff(endDatetime, 'seconds') < 0) {
            console.log(endDatetime)
            console.log('O horário final está no futuro')
            this.setState({
                timeError: [...this.state.timeError, { index: i, type: 'end' }]
            }, () => this.errorMessage('Este horário está no futuro'))
        } else if (startDatetime && moment().diff(startDatetime, 'seconds') < 0) {
            console.log('O horário inicial está no futuro')
            this.setState({
                timeError: [...this.state.timeError, { index: i, type: 'start' }]
            }, () => this.errorMessage('Este horário está no futuro'))
        } else {
            console.log('Remove erro nos campos start e end')
            const errorEnd = this.state.timeError.find(f => f.index === i && f.type === 'end')
            const errorStart = this.state.timeError.find(f => f.index === i && f.type === 'start')
            this.setState(prevState => ({
                timeError: []
            }), () => this.errorMessage(null))

        }

    }

    errorMessage = (message) => {

        console.warn(message)
        this.setState({
            errorMessage: message
        })
    }

    validateEditionMaxtime = (i) => {
        const editedTime = this.state.timesList[i]
        const originalTime = editedTime && this.props.tarefa && this.props.tarefa.Tempos && this.props.tarefa.Tempos.find(f => f.Codigo === editedTime.id)
        //const originalEnd = originalTime && moment(originalTime.data_fim).format('HH:mm')
        if (typeof originalTime !== 'undefined') {
            let GMT = moment.parseZone(originalTime.data_inicio).toString().split('GMT')[1]
            GMT = `${GMT.substr(0, 3)}:${GMT.substr(GMT.length - 2)}`
            let originalEnd = originalTime.data_fim ? parseInt(moment.parseZone(originalTime.data_fim).format('ss')) > 30 ? moment.parseZone(originalTime.data_fim).startOf('minute').add(1, 'minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : moment.parseZone(originalTime.data_fim).startOf('minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : null
            originalEnd = moment(originalEnd).format('HH:mm')
            const original = timeround(originalTime.data_inicio, originalTime.data_fim)

            console.log(editedTime)
            console.log(original)


            const editedDiff = timeround(original.end, editedTime.date_end).diff

            console.log(editedDiff)

            if (originalTime && originalTime.status === "A" && editedDiff > 0) {
                return false
            } else {
                return true
            }

        } else {
            return true
        }

        //const originalEnd = originalTime ? parseInt(moment.parseZone(m.data_fim).format('ss')) > 30 ? moment.parseZone(m.data_fim).startOf('minute').add(1, 'minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : moment.parseZone(m.data_fim).startOf('minute').format(`YYYY-MM-DD HH:mm:ss${GMT}`) : null

        /*if(originalTime && originalTime.status === "A" && (parseInt(editedTime.end.split(":")[0]+editedTime.end.split(":")[1]) > parseInt(originalEnd.split(":")[0]+originalEnd.split(":")[1]))){
            return false
        } else {
            return true
        }*/

    }

    handleSaveTask = async () => {
        const { ProjetoTarefaSelecionado, TarefaSelecionada, showTaskTimes, timesList, justificatory, justificatoryIndex, timeError, newTaskName, newTaskTime } = this.state
        let { Tarefa } = this.state
        Tarefa.Clientes = [{
            Nome: ProjetoTarefaSelecionado.NomeCliente,
            Codigo: ProjetoTarefaSelecionado.CodigoCliente
        }]
        const addNewTask = /*ProjetoTarefaSelecionado.Projeto &&*/ newTaskName.trim().length > 0 ? this.addNewTask(newTaskName, newTaskTime, true) : this.addNewTask(null, null, true)
        const originalTimesList = this.theOriginal()
        const origialInfo = this.theOriginalInfo()
        const originalTime = originalTimesList.reduce((accum, item) => accum + (item.tempo > 0 ? item.tempo : 0), 0)
        const newlTime = timesList.reduce((accum, item) => accum + (item.tempo > 0 ? item.tempo : 0), 0)
        /*const updates = () => {
            let updated = 0
            timesList.map((m,i) => {
                const oldValues = originalTimesList.find( f => f.id === m.id)
                if( typeof oldValues !== 'undefined' && (oldValues.start !== m.start || oldValues.end !== m.end)){
                    updated++
                }
            })
            return updated
        }*/

        const toNumber = (value) => {
            return value ? Number(value.replace(/\D/g, '')) : 0
        }

        const updates = timesList.map((m, i) => {
            let time = m
            const oldValues = originalTimesList.find(f => f.id === m.id)
            if (typeof oldValues !== 'undefined' && (oldValues.start !== m.start || oldValues.end !== m.end)) {
                time.changed = true
                time.compare = m.status === 'A' && toNumber(m.end) - toNumber(oldValues.end) <= 0 ? null : toNumber(m.end) - toNumber(oldValues.end)
            } else {
                time.changed = false
                time.compare = typeof oldValues === 'undefined' ? toNumber(m.end) - toNumber(m.start) : toNumber(m.end) - toNumber(oldValues.end)
            }
            return time
        })
        const updatesCount = updates.filter(f => f.changed || f.newField).length

        const prevJustification = justificatoryIndex >= 0 ? timesList[justificatoryIndex] && timesList[justificatoryIndex].justifications && timesList[justificatoryIndex].justifications[timesList[justificatoryIndex].justifications.length - 1] && timesList[justificatoryIndex].justifications[timesList[justificatoryIndex].justifications.length - 1].Justification : null
        const currJustification = justificatory
        const changeNumberOfLines = originalTimesList.length - timesList.length

        const changeTaskData = origialInfo.descricao !== Tarefa.Descricao || origialInfo.codigoTarefa !== TarefaSelecionada.Codigo || origialInfo.codigoProjeto !== ProjetoTarefaSelecionado.Codigo
        const changeTime = updatesCount > 0 || originalTimesList.filter(f => f.start && f.end).length !== timesList.filter(f => f.start && f.end).length ? true : false
        const needJustification = updates && updates.filter(f => f.compare > 0).length

        if (prevJustification && (prevJustification !== currJustification)) {
            const timeInf = justificatoryIndex >= 0 ? timesList[justificatoryIndex] : null
            Axios.put(`${url.RouterUrl}/api/activity/${Tarefa.Codigo}/justifications`, [{
                code: timeInf.justifications[0].Codigo,
                description: currJustification
            }])
                .then(resp => {
                    if (resp && resp.status === 200) {
                        this.setState({
                            timesList: this.state.timesList.map(m => {
                                let tm = m
                                if (m.id === timesList[justificatoryIndex].id) {
                                    tm.justifications = tm.justifications.map(j => {
                                        let jus = j
                                        if (jus.Codigo === timeInf.justifications[0].Codigo) {
                                            jus.Justification = currJustification
                                        }
                                        return jus
                                    })
                                }
                                return tm
                            })
                        })
                    } else {

                        ToastError('Ocorreu um erro ao adicionar sua atividade')
                    }
                }).catch(err => {
                    console.log(err)
                })
        }

        if (needJustification > 0 && this.state.justificatory.trim().length === 0 && this.state.showTaskTimes) {
            this.setState({
                errorMessage: 'Por favor, insira uma justificativa',
                checkRequired: true
            })

            return
        }
        if (updatesCount === 0 && this.props.tarefa.Codigo > 0 && originalTimesList.length === timesList.length && !changeTaskData) {
            console.log('if')

            this.setState({
                errorMessage: '',
                checkRequired: false,
                justificatory: '',
                showTaskTimes: false
            }, () => this.props.fecharModal())
        } else {
            console.log('else')
            if (this.props.tarefa.Codigo > 0) {
                Tarefa.CodigoProjeto = ProjetoTarefaSelecionado.Codigo
                Tarefa.CodigoTarefa = TarefaSelecionada.Codigo

                if (originalTime < newlTime && this.state.justificatory.trim().length === 0 && this.state.showTaskTimes) {
                    console.log('IFF')
                    this.setState({
                        errorMessage: 'Por favor, insira uma justificativa',
                        checkRequired: true
                    })
                } else {
                    console.log('else')
                    addNewTask.then(() => {
                        console.log('edit task')
                        this.props.editarAtividade(Tarefa, updates, justificatory, changeTime)
                    }).catch(err => {
                        console.log('err')
                    })
                }
                console.log('second if')
            } else {
                console.log('second else')
                if (Tarefa.fixo || moment.parseZone(Tarefa.DataInicio).startOf('day').diff(moment().tz(moment.tz.guess()).startOf('day'), 'days') < 0) {
                    console.log('salvarAtividade')
                    this.props.salvarAtividade(Tarefa, this.state.Tarefa.CodigoProjeto, this.state.Tarefa.CodigoTarefa, [], '')
                } else if (Tarefa.Descricao.trim().length === 0 ||
                    ProjetoTarefaSelecionado.Codigo === 0 ||
                    TarefaSelecionada.Codigo === 0 ||
                    (this.state.Tarefa.Tempo > this.state.Tarefa.OldTempo) && Tarefa.Justificativa.trim().length === 0 ||
                    (updatesCount > 0 && this.state.justificatory.trim().length === 0)
                ) {
                    console.log('checkRequired')
                    this.setState({
                        checkRequired: true,
                    })
                } else if (Tarefa.TempoTarefa && parseInt(Tarefa.TempoTarefa.replace(/\D/g, '')) > 800) {
                    ToastError('Sua tarea não pode ter mais de 8 horas')
                } else {
                    if (moment(this.props.date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') && timesList.filter(f => f.start && f.end).length === 0) {
                        this.setState({
                            errorMessage: 'Por favor, adicione os tempos',
                            showTaskTimes: true
                        }, () => {
                            if (this.state.timesList.length === 0) {
                                this.handleToList(-1)
                            }
                        })
                    } else {
                        this.setState({
                            checkRequired: false,
                            showTaskTimes: false
                        }, () => {
                            addNewTask.then(() => {
                                if (this.state.Tarefa.continueTask) {
                                    this.props.salvarAtividade(Tarefa, this.state.Tarefa.CodigoProjeto, this.state.Tarefa.CodigoTarefa, [], '')
                                } else {
                                    this.props.salvarAtividade(Tarefa, this.state.ProjetoTarefaSelecionado.Codigo, this.state.TarefaSelecionada.Codigo, updates, justificatory)
                                }
                            }).catch(err => {

                            })

                        }
                        )

                    }
                }
            }
        }
    }
    addNewTask = async (name, time) => {
        if (name && name.trim().length > 0) {
            const { Codigo } = this.state.ProjetoTarefaSelecionado
            return await Axios.post(`${url.RouterUrl}/api/project/${Codigo}/sprint/current/todo/add`, {
                name: name,
                plannedTime: time ? TimerUtils.tempoToMinutos(time) : 0,
                planned: time ? true : false
            })
                .then(async resp => {
                    const { Codigo, AtribuidoA, NomeAtrubuidoA, arquivado, CodigoLabel, TempoPlanejado, TempoUtilizado, Nome } = resp.data

                    const newTask = {
                        Arquivado: arquivado,
                        AtribuidoACodigo: AtribuidoA,
                        AtribuidoANome: NomeAtrubuidoA,
                        Codigo: Codigo,
                        CodigoLabel: CodigoLabel,
                        Executando: false, //NÃO VEM
                        Nome: Nome,
                        TempoRestante: TempoPlanejado,
                        TempoUtilizado: TempoUtilizado
                    }
                    this.setState(prevState => ({
                        ProjetoTarefaSelecionado: {
                            ...prevState.ProjetoTarefaSelecionado,
                            Tarefas: [...prevState.ProjetoTarefaSelecionado.Tarefas, newTask]
                        },
                        TarefaSelecionada: newTask
                    }), () => {
                        this.toggleCreateTask()
                        localStorage.setItem('timeModalTask', JSON.stringify(newTask))
                    })
                    return await true

                }).catch(err => {

                })
        } else {
            return await true
        }


    }

    checkTaskIsRuning = (codigo) => {
        Axios.get(`${url.RouterUrl}/api/todo/${codigo}/running`)
            .then(resp => {

                this.setState({
                    running: resp && resp.data && resp.data.Running ? resp.data.NomeAtrubuidoA : this.state.running
                })
            }).catch(err => {

            })
    }

    toggleCreateTask = () => {
        this.setState(prevState => ({
            createTask: !prevState.createTask,
            newTaskName: '',
            newTaskTime: ''
        }))
    }

    render() {
        const { modal, isDisconnected, times, tarefa } = this.props;
        const { timesList, showTaskTimes, checkRequired, timeError, loading } = this.state;

        /*if (modal && !this.state.modal) {
            setTimeout(() => this.abrirModal(), 50);
        }
        if (!modal && this.state.modal) {

            setTimeout(() => this.fecharModal(), 50);
        }*/

        /*let timeSum = timesList.map( m => {
            const datetime = moment(this.props.date).format('YYYY-MM-DD').toString()
            const fullDatetimeStart = `${datetime} ${m.start}`
            const fullDatetimeEnd = `${datetime} ${m.end}`
            if(m.start && m.end){
                return Math.round(moment(fullDatetimeEnd).diff(fullDatetimeStart, 'seconds'))
            } else {
                return Math.floor(moment().diff(fullDatetimeStart, 'seconds'))
            }
            //return minutes
        })*/

        const dayTimes = this.state.Tarefa && this.state.Tarefa.Tempos && this.state.Tarefa.Tempos.length > 0 ? this.state.Tarefa.Tempos.filter(t => t.status !== "C" && t.status !== "R").map(t => { return { start: t.data_inicio, end: t.data_fim } }) : []
        /*const timesArray = dayTimes.map( r => {
            let tempo = r
            const startTime = moment.parseZone(tempo.start).format()
            const endTime = !tempo.end ? moment(new Date()).tz(moment.tz.guess()).format() : moment.parseZone(tempo.end).format()

            return Math.round(moment.parseZone(endTime).diff(moment.parseZone(startTime), 'seconds') / 60)
        })*/
        let runningTime = dayTimes.filter(f => !f.end).map(r => {
            let tempo = r
            const rounded = timeround(tempo.start, moment.parseZone().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss'))
            return rounded.diff
        })
        console.log()
        runningTime = runningTime.reduce((accum, item) => accum + (item > 0 ? item : 0), 0)

        const timesArray = timesList.map((times, idx) => {
            const datetime = moment(this.props.date).format('YYYY-MM-DD').toString()
            const fullDatetimeStart = `${datetime} ${times.start}`
            const fullDatetimeEnd = `${datetime} ${times.end}`
            if (times.status === "A" && times.end === null) {
                return runningTime
            } else if (times.start && times.end) {
                /*const tim = Math.round(moment(fullDatetimeEnd).diff(fullDatetimeStart, 'seconds') / 60)
                return tim > 0 ? tim : 0*/
                const rounded = timeround(times.date_start, times.date_end)
                return rounded.diff
            } else {
                return 0
            }

        })
        //const timesSum = timesArray && timesArray.reduce((accum,item) => accum + item, 0)
        //timeSum = timeSum.reduce((accum,item) => accum + (item > 0 ? item : 0), 0)

        const running = times && times.flat() && times.flat().find(f => f.codigo_atividade === tarefa.Codigo && f.running) ? times.flat().find(f => f.codigo_atividade === tarefa.Codigo && f.running) : null
        const taskTimes = times && times.flat() && times.flat().filter(f => f.codigo_atividade === tarefa.Codigo)
        //const newTimeSum = taskTimes && taskTimes.reduce((accum,item) => accum + (item.diff > 0 ? item.diff : 0), 0)
        const newTimeSum = timesList.filter(f => running ? f.id !== running.codigo : f === f).map(m => m.tempo).reduce((accum, item) => accum + (item > 0 ? item : 0), 0)

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggleModal}

                    className="modal-dialog-centered time-modal-size">
                    <ModalHeader toggle={this.toggleModal}>
                        <If visivel={this.state.Tarefa.continueTask}>
                            {this.state.Tarefa.NomeTarefa}
                        </If>
                        <If visivel={!this.state.Tarefa.continueTask}>
                            <If visivel={this.state.Tarefa.Codigo === 0}>
                                <FormattedMessage id='Nova atividade' />
                            </If>
                            <If visivel={this.state.Tarefa.Codigo !== 0}>
                                <FormattedMessage id='Editar atividade' />
                            </If>
                        </If>
                    </ModalHeader>
                    <ModalBody className="pb-0" style={showTaskTimes ? { minHeight: 300 } : {}}>
                        <div className={`modal-edit-times d-flex ${showTaskTimes ? 'show' : 'hide'}`}>
                            {this.state.errorMessage &&
                                <div className="error-message d-flex justify-content-between align-items-center">
                                    <span className="flex-grow-1">
                                        {this.state.errorMessage}
                                    </span>
                                    <span style={{ width: 30 }} className="text-center" onClick={() => this.setState({ errorMessage: null })}>
                                        <Icon.X size={16} />
                                    </span>


                                </div>
                            }
                            <div className="d-flex flex-column flex-grow-1 w-100">
                                <div className="edit-times-header d-flex justify-content-between align-items-center">
                                    <h6 className="light truncate">
                                        {this.props.tarefa.NomeTarefa ?
                                            <Translate><b className="info">{this.props.tarefa.NomeTarefa}</b>: {this.props.tarefa.Descricao}</Translate> :
                                            <Translate>Nova tarefa</Translate>

                                        }
                                    </h6>
                                    <span className="cursor-pointer" onClick={this.handleEditTask}>
                                        <Icon.X size={16} className="danger" />
                                    </span>
                                </div>
                                <PerfectScroll className="d-flex flex-column flex-grow-1 overflow-auto time-edit-rows">
                                    <div className="add-initial-time cursor-pointer" onClick={() => this.handleToList(-1)}>Adicionar tempo no início</div>
                                    <table>
                                        <tbody>
                                            {timesList.map((times, idx) => {
                                                const startError = this.state.timeError.filter(f => f.index === idx && f.type === 'start')
                                                const endError = this.state.timeError.filter(f => f.index === idx && f.type === 'end')
                                                const startTime = times.start ? times.start.split(':') : 0
                                                const endTime = times.end ? times.end.split(':') : 0


                                                let minutes = 0

                                                //PEGA HORÁRIOS DA LINHA ANTERIOR
                                                const prevLine = timesList[idx - 1]
                                                let prevStart = prevLine && prevLine.start ? prevLine.start.split(':') : null
                                                let prevEnd = prevLine && prevLine.end ? prevLine.end.split(':') : null
                                                prevStart = prevStart && `${prevStart[0]}:${prevStart[1]}`
                                                prevEnd = prevEnd && `${prevEnd[0]}:${prevEnd[1]}`

                                                //PEGA HORÁRIOS DA LINHA EM EDIÇÃO
                                                const thisLine = timesList[idx]
                                                let thisStart = thisLine && thisLine.start ? thisLine.start.split(':') : null
                                                let thisEnd = thisLine && thisLine.end ? thisLine.end.split(':') : null
                                                thisStart = thisStart && `${thisStart[0]}:${thisStart[1]}`
                                                thisEnd = thisEnd && `${thisEnd[0]}:${thisEnd[1]}`

                                                //PEGA HORÁRIOS DA LINHA SEGUINTE
                                                const nextLine = timesList[idx + 1]
                                                let nextStart = nextLine && nextLine.start ? nextLine.start.split(':') : null
                                                let nextEnd = nextLine && nextLine.end ? nextLine.end.split(':') : null
                                                nextStart = nextStart && `${nextStart[0]}:${nextStart[1]}`
                                                nextEnd = nextEnd && `${nextEnd[0]}:${nextEnd[1]}`


                                                const disableCondition = nextStart && thisEnd === nextStart


                                                const datetime = moment(this.props.date).format('YYYY-MM-DD').toString()
                                                const fullDatetimeStart = `${datetime} ${times.start}`
                                                const fullDatetimeEnd = `${datetime} ${times.end}`

                                                if (times.start && times.end) {

                                                    //minutes =  Math.round(moment(fullDatetimeEnd).diff(fullDatetimeStart, 'seconds') / 60)
                                                    minutes = Math.round(moment(times.date_start).diff(times.date_end, 'seconds') / 60)
                                                } else {
                                                    minutes = Math.floor(moment(this.props.date).diff(times.date_start, 'seconds') / 60)
                                                }

                                                //const newMinutes = timeround(times.date_start, times.end ? times.date_end : moment().format())


                                                return (
                                                    <tr key={idx} className={`${idx % 2 === 0 ? 'odd' : 'even'}`}>
                                                        <td width="5px">
                                                            <div className={`edit-type ${timesList[idx].status === "A" ? 'bg-success' : 'bg-danger'}`}></div>
                                                        </td>
                                                        <td width="150px">
                                                            <Input
                                                                type="time"
                                                                size="sm"
                                                                style={{ fontSize: '15px' }}
                                                                className={`form-control modal-edit-time mr-50 ${startError.length > 0 ? 'is-invalid' : ''}`}
                                                                name="start"
                                                                onClick={() => this.setJustification(timesList[idx]["justifications"], idx)}
                                                                value={timesList[idx]["start"] ? `${startTime[0]}:${startTime[1]}` : ''}
                                                                disabled={timesList[idx].status === "A" ? true : false}
                                                                //onBlur={() => this.validateTimeFields(null, idx)}
                                                                //onKeyDown={(e) => this.handleBlur(e, idx)}
                                                                onChange={(e) => this.onChangeList(e, idx)}
                                                            />
                                                        </td>
                                                        <td width="150px">
                                                            {(timesList[idx].status === "A" && timesList[idx]["end"] === null) ?
                                                                <div className="d-flex justify-content-center align-items-center w-100">
                                                                    <small className="edit-time-task-running">Em andamento</small>
                                                                </div>
                                                                :
                                                                <Input
                                                                    type="time"
                                                                    size="sm"
                                                                    style={{ fontSize: '15px' }}
                                                                    className={`form-control modal-edit-time mr-50 ${endError.length > 0 ? 'is-invalid' : ''}`}
                                                                    name="end"
                                                                    onClick={() => this.setJustification(timesList[idx]["justifications"], idx)}
                                                                    //value={timesList[idx]["end"] ? `${endTime[0]}:${adjustEndMinutes < 10 ? `0${adjustEndMinutes}` : adjustEndMinutes}` : ''}
                                                                    value={timesList[idx]["end"] ? `${endTime[0]}:${endTime[1]}` : ''}
                                                                    onChange={(e) => this.onChangeList(e, idx)}
                                                                    //onBlur={() => this.validateTimeFields(null, idx)}
                                                                    onKeyDown={(e) => this.handleBlur(e, idx)}
                                                                    disabled={timesList[idx]["error"] === 'end' || (timesList[idx].status === "A" && timesList[idx]["end"] === null) ? true : false}
                                                                />
                                                            }
                                                        </td>
                                                        <td width="5px">
                                                            <div
                                                                className={`${(timesList[idx]["start"] && timesList[idx]["end"] && startError.length === 0 && endError.length === 0 && !disableCondition) ? 'success' : 'button-disabled cursor-not-allowed'} cursor-pointer ml-50 d-flex justify-content-center align-iems-center`}
                                                                onClick={() => timesList[idx]["start"] && timesList[idx]["end"] && startError.length === 0 && endError.length === 0 ? this.handleToList(idx) : null}>
                                                                <Icon.PlusCircle size={20} />
                                                            </div>
                                                        </td>
                                                        <td width="5px">
                                                            <div
                                                                className={`${(timesList[idx]["start"] && timesList[idx]["end"]) || !timesList[idx].status ? 'danger cursor-pointer' : 'button-disabled cursor-not-allowed'}`}
                                                                //className={`${(timesList[idx]["start"] && timesList[idx]["end"]) ? 'danger cursor-pointer' : 'button-disabled cursor-not-allowed'}`}
                                                                //className={`${(timesList[idx]["start"] && timesList[idx]["end"] && startError.length === 0 && endError.length === 0) || !timesList[idx]["start"] && !timesList[idx]["end"] ? 'danger cursor-pointer' : 'button-disabled cursor-not-allowed'}`}
                                                                //className={`${'danger cursor-pointer'} d-flex justify-content-center align-items-center ml-50`}
                                                                onClick={() => (timesList[idx]["start"] && timesList[idx]["end"]) || !timesList[idx].status ? this.handleRemoveFromList(idx) : this.setState({ errorMessage: 'Não é possível apagar um tempo em andamento' })}
                                                            //onClick={() => (timesList[idx]["start"] && timesList[idx]["end"] && startError.length === 0 && endError.length === 0)  || !timesList[idx]["start"] && !timesList[idx]["end"] ? this.handleRemoveFromList(idx) : null}
                                                            >
                                                                <Icon.Trash2 size={20} />
                                                            </div>
                                                        </td>
                                                        <td width="5px">
                                                            <div className="total-line-time">
                                                                {(timesList[idx].status === "A" && timesList[idx]["end"] === null) ?
                                                                    <b className="info ml-50">{TimerUtils.HoraMinuto.MinutosToHoraMinuto(running && running.diff ? running.diff : 0)}</b>
                                                                    :
                                                                    <b className="info ml-50">{TimerUtils.HoraMinuto.MinutosToHoraMinuto(!timesList[idx].start ? 0 : times.tempo > 0 ? times.tempo : 0)}</b>
                                                                }
                                                            </div>

                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </PerfectScroll>

                                <div className="edit-times-footer d-flex flex-column">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <b className="gray">Total: </b>
                                        <b className="info">{TimerUtils.HoraMinuto.MinutosToHoraMinuto(newTimeSum + (running && running.diff ? running.diff : 0))}</b>
                                    </div>

                                    <div className="mt-50">
                                        <Input
                                            type="textarea"
                                            rows="2"
                                            className={`form-control modal-edit-time mr-50 ${this.state.checkRequired && this.state.justificatory.trim().length === 0 ? 'is-invalid' : ''}`}
                                            placeholder="Insira uma justificativa"
                                            value={this.state.justificatory}
                                            onChange={e => this.setState({ justificatory: e.target.value })}
                                        />
                                    </div>

                                </div>
                                {/*<Button color="success" onClick={this.handleEditTask}>
                                            <Icon.CloudSnow size={12} />{" Fechar"}
                                        </Button>*/}
                            </div>
                        </div>
                        <Row>
                            <Col md="12">
                                <Row>
                                    <If visivel={!this.state.Tarefa.continueTask}>
                                        <Col md="12">
                                            <div className="d-flex justify-content-between">
                                                <h6 className="light">
                                                    <Translate>{this.state.titulo}</Translate>
                                                </h6>
                                                {/* <small className="cursor-pointer" onClick={() => this.state.viewAll ? this.handlehideAllProjects() : this.handleLoadAllProjects()}>{this.state.viewAll ? "Retomar" : "Ver todos"}</small> */}
                                            </div>
                                            <Select
                                                isLoading={loading}
                                                isDisabled={true}
                                                className={`react-select ${checkRequired && this.state.ProjetoTarefaSelecionado.Codigo === 0 ? 'is-invalid' : ''}`}
                                                classNamePrefix="select"
                                                value={{
                                                    codigo: this.state.ProjetoTarefaSelecionado.Codigo,
                                                    value: this.state.ProjetoTarefaSelecionado.Nome + this.state.ProjetoTarefaSelecionado.NomeCliente,
                                                    label: <div><b>{this.state.ProjetoTarefaSelecionado.Nome}</b> {this.state.ProjetoTarefaSelecionado.NomeCliente ? ` (${this.state.ProjetoTarefaSelecionado.NomeCliente})` : ''}</div>
                                                }}
                                                name="color"
                                                options={this.state.ProjetosTarefas.sort((a, b) => {
                                                    if (a.Nome.toLowerCase() < b.Nome.toLowerCase()) { return -1; }
                                                    if (a.Nome.toLowerCase() > b.Nome.toLowerCase()) { return 1; }
                                                    return 0;
                                                }).map(item => {
                                                    return {
                                                        codigo: item.Codigo,
                                                        value: item.Nome + item.NomeCliente,
                                                        label: (<div><b>{item.Nome}</b><span>{item.NomeCliente ? ` (${item.NomeCliente})` : ""}</span></div>)
                                                    }
                                                })}
                                                onChange={e => {
                                                    const selecionado = this.state.ProjetosTarefas.find(x => x.Codigo === e.codigo)
                                                    selecionado.Tarefas = selecionado.Tarefas ?? []
                                                    this.setState({
                                                        ProjetoTarefaSelecionado: selecionado,
                                                        TarefaSelecionada: selecionado.Tarefas.length > 0 ? selecionado.Tarefas[0] : {
                                                            Codigo: 0,
                                                            Nome: 'Nenhuma tarefa para esse projeto',
                                                        }
                                                    }, () => {
                                                        //Caso os campos de criação de tarefas estejam abertos
                                                        //e o usuário selecione um setor
                                                        //oculpar os campos de criação de tarefas
                                                        if (this.state.createTask && !selecionado.Projeto) {
                                                            this.toggleCreateTask()
                                                        }
                                                        //SALVA O PROJETO SELECIONADO EM LOCALSTORAGE
                                                        if (selecionado) {
                                                            localStorage.setItem('timeModalProject', JSON.stringify(selecionado))
                                                        }
                                                        if (selecionado.loadtasks) {
                                                            this.handleLoadProjectTasks(selecionado)
                                                        }
                                                    })
                                                }}
                                            />
                                        </Col>
                                        <Col md="12" className='mt-1'>
                                            <div className="d-flex justify-content-between">
                                                <h6 className="light">
                                                    <Translate>Tarefa</Translate>
                                                </h6>
                                                {/* <small className="cursor-pointer" onClick={() => this.toggleCreateTask()}>{this.state.createTask ? <span className="danger">Desistir</span> : <span>Adicionar</span>}</small> */}
                                            </div>
                                            {this.state.createTask ?
                                                <Row>
                                                    <Col md={this.state.ProjetoTarefaSelecionado.Projeto ? '7' : '12'} >
                                                        <Input
                                                            type="text"
                                                            style={{ height: 39 }}
                                                            className={`React mt-0 ${(checkRequired && this.state.Tarefa.Descricao.trim().length === 0) || this.state.Tarefa.Descricao.trim().length > 500 ? 'is-invalid' : ''}`}
                                                            value={this.state.newTaskName}
                                                            placeholder="Nome da tarefa"
                                                            onKeyUp={this.keyHandler}
                                                            onChange={e => this.setState({
                                                                newTaskName: e.target.value
                                                            })} />
                                                    </Col>
                                                    {this.state.ProjetoTarefaSelecionado.Projeto &&
                                                        <Col md="5" >

                                                            <InputMask
                                                                className="form-control"
                                                                type="text"
                                                                mask="99:99"
                                                                placeholder="Tempo previsto"
                                                                onChange={e => {
                                                                    this.setState({
                                                                        newTaskTime: e.target.value,
                                                                    })
                                                                }}
                                                                value={this.state.newTaskTime ?? ""}
                                                            />
                                                        </Col>
                                                    }
                                                </Row>
                                                :
                                                <React.Fragment>
                                                    <Select
                                                        isLoading={loading}
                                                        isDisabled={true}
                                                        className={`react-select mt-0 ${checkRequired && this.state.TarefaSelecionada.Codigo === 0 ? 'is-invalid' : ''}`}
                                                        classNamePrefix="select"
                                                        placeholder="Descrição da tarefa"
                                                        value={{
                                                            codigo: this.state.TarefaSelecionada.Codigo,
                                                            value: this.state.TarefaSelecionada.Nome,
                                                            label: (
                                                                <React.Fragment>
                                                                    {this.state.TarefaSelecionada.Nome} {this.state.TarefaSelecionada.CodigoLabel && <Badge color='dark'><small>{this.state.TarefaSelecionada.CodigoLabel}</small></Badge>}
                                                                    {/*this.state.TarefaSelecionada.AtribuidoA !== null ? (
                                                                    <React.Fragment>
                                                                        <small className={`ml-1 ${this.state.TarefaSelecionada.Executando ? 'warning' : 'success'}`}>
                                                                            <AvatarPessoa size="sm" codigo={this.state.TarefaSelecionada.AtribuidoACodigo} nome={this.state.TarefaSelecionada.AtribuidoA} />
                                                                            {this.state.TarefaSelecionada.AtribuidoA.split(' ')[0]}
                                                                        </small>
                                                                        {" "}
                                                                        <small>
                                                                            {TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.TarefaSelecionada.TempoUtilizado)} - {TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.TarefaSelecionada.TempoRestante)}
                                                                        </small>
                                                                    </React.Fragment>
                                                                ) : null*/}
                                                                </React.Fragment>
                                                            )
                                                        }}
                                                        name="color"
                                                        options={this.state.ProjetoTarefaSelecionado && this.state.ProjetoTarefaSelecionado.Tarefas && this.state.ProjetoTarefaSelecionado.Tarefas.filter(f => !f.hide)
                                                            .sort((a, b) => {
                                                                if (a.Nome.toLowerCase() < b.Nome.toLowerCase()) { return -1; }
                                                                if (a.Nome.toLowerCase() > b.Nome.toLowerCase()) { return 1; }
                                                                return 0;
                                                            }).map(item => {
                                                                return {
                                                                    codigo: item.Codigo,
                                                                    value: `${item.Nome}${item.CodigoLabel ? item.CodigoLabel : ''}`,
                                                                    label: (
                                                                        <div className="d-flex flex-column">
                                                                            <div>
                                                                                {item.Nome} {item.CodigoLabel && <Badge color='dark'><small>{item.CodigoLabel}</small></Badge>}
                                                                            </div>
                                                                            {/*item.AtribuidoA !== null ? (
                                                                        <React.Fragment>
                                                                            <small className={`ml-1 ${item.Executando ? 'warning' : 'success'}`}>(
                                                                                <AvatarPessoa size="sm" codigo={item.AtribuidoACodigo} nome={item.AtribuidoA.split(' ')[0]} />
                                                                                {item.AtribuidoA.split(' ')[0]})
                                                                            </small>
                                                                            {" "}
                                                                            <small>
                                                                                ({TimerUtils.HoraMinuto.MinutosToHoraMinuto(item.TempoUtilizado)} - {TimerUtils.HoraMinuto.MinutosToHoraMinuto(item.TempoRestante)})
                                                                            </small>
                                                                        </React.Fragment>
                                                                    ) : null*/}
                                                                        </div>
                                                                    ),
                                                                    tarefa: item
                                                                }
                                                            })
                                                        }
                                                        onChange={item => this.setState({ TarefaSelecionada: item.tarefa, running: false }, () => {
                                                            //SALVA A TAREFA SELECIONADA EM LOCALSTORAGE
                                                            if (item.tarefa) {
                                                                localStorage.setItem('timeModalTask', JSON.stringify(item.tarefa))
                                                            }
                                                            if (item && item.tarefa && item.tarefa.AtribuidoACodigo > 0) {
                                                                this.checkTaskIsRuning(item.tarefa.Codigo)
                                                            }
                                                        })}
                                                    />
                                                </React.Fragment>
                                            }

                                        </Col>
                                    </If>
                                    {!this.state.Tarefa.continueTask &&
                                        <Col md="12" className="mb-1 mt-1 d-flex flex-column task-time">
                                            <Input
                                                type="text"
                                                maxLength={5}
                                                style={{ fontSize: '35px', borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
                                                className="form-control text-center modal-edit-time"
                                                value={newTimeSum + (running && running.diff ? running.diff : 0) > 0 ? TimerUtils.HoraMinuto.MinutosToHoraMinuto(newTimeSum + (running && running.diff ? running.diff : 0)) : this.state.Tarefa.TempoTarefa}
                                                onKeyUp={this.keyHandler}
                                                onChange={e => {
                                                    this.calculaTempo(e.target.value)
                                                }}
                                                disabled={moment(this.props.date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') || this.props.tarefa && this.props.tarefa.Tempos && this.props.tarefa.Tempos.length > 0 ? true : false}
                                                onFocus={e => e.target.select()}
                                            />
                                            {//this.props.tarefa.Codigo > 0 ?
                                                <Button.Ripple
                                                    className={`btn-clock btn-block`}
                                                    style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
                                                    color="primary"
                                                    size="sm"
                                                    disabled={
                                                        !(this.state.ProjetoTarefaSelecionado.Codigo > 0 /*&& this.state.TarefaSelecionada.Codigo > 0*/) && !this.state.Tarefa.continueTask ||
                                                        this.state.running || this.state.Tarefa.continueTask

                                                    }
                                                    onClick={() => this.handleEditTask()}>Editar</Button.Ripple>
                                                //: null
                                            }
                                        </Col>
                                    }
                                    {/*<If visivel={this.state.Empresa.JustificativaObrigatoriaOperador}>*/}
                                    <If visivel={this.state.Tarefa.Tempo > this.state.Tarefa.OldTempo}>
                                        <Col md="12" className="mb-1">
                                            <Label>Justificativa</Label>
                                            <Input type="textarea"
                                                className={`React mt-0 ${checkRequired && this.state.Tarefa.Tempo > this.state.Tarefa.OldTempo && this.state.Tarefa.Justificativa.trim().length === 0 ? 'is-invalid' : ''}`}
                                                value={this.state.Tarefa.Justificativa}
                                                onKeyUp={this.keyHandler}
                                                onChange={e => this.setState({
                                                    Tarefa: {
                                                        ...this.state.Tarefa,
                                                        Justificativa: e.target.value
                                                    }
                                                })} />
                                            <Label className="light mt-50">{this.state.Tarefa.OldTempo === 0
                                                ? 'Informe a justificativa para iniciar uma atividade já com um tempo expecifico'
                                                : 'Informe a justificativa para alterar o tempo da tarefa'}</Label>
                                        </Col>
                                    </If>
                                    {/*</If>*/}
                                </Row>
                                {this.state.running ?
                                    <Alert color='danger' className="mb-1" size="sm" isOpen={true}>
                                        <div className='alert-body'>
                                            <span>
                                                <div>
                                                    <small>Não é possível adicionar uma atividade no momento. Essa tarefa está sendo <b>executada por {this.state.running}</b>.</small>
                                                </div>
                                                {/*<small><strong>Tempo restante para conclusao {TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.TarefaSelecionada.TempoRestante)}</strong></small>*/}

                                            </span>
                                        </div>
                                    </Alert>
                                    : this.state.Tarefa.Codigo === 0 && this.state.TarefaSelecionada.AtribuidoACodigo && this.props.user.codigo !== this.state.TarefaSelecionada.AtribuidoACodigo ? (
                                        <Alert color='danger' className="mb-1" size="sm" isOpen={true}>
                                            <div className='alert-body'>
                                                <span>
                                                    <div>
                                                        <small>Tarefa já <b >atribuida a {this.state.TarefaSelecionada.AtribuidoANome}</b>
                                                            {/*this.state.TarefaSelecionada.Executando ? <strong> e está em execução.</strong> : <strong>, mas ainda não começou a faze-la. </strong>*/}. Se você iniciar esta tarefa ela será atribuida para você.</small>
                                                    </div>
                                                    {/*<small><strong>Tempo restante para conclusao {TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.TarefaSelecionada.TempoRestante)}</strong></small>*/}

                                                </span>
                                            </div>
                                        </Alert>

                                    ) : null
                                }

                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col md="12">
                            <Row className="justify-content-between">
                                <Col md="auto">
                                    <Button
                                        color="success"
                                        onClick={() => this.handleSaveTask()}
                                        disabled={!(this.state.ProjetoTarefaSelecionado.Codigo > 0 && this.state.TarefaSelecionada.Codigo > 0) && this.state.newTaskName.trim().length === 0 && !this.state.Tarefa.continueTask || timeError.length > 0 || this.state.Tarefa.Descricao.trim().length > 500 || this.state.running || this.state.createTask && this.state.newTaskName.trim().length === 0 || isDisconnected || this.props.requesting || (this.state.Tarefa.Tempo > this.state.Tarefa.OldTempo && this.state.Tarefa && this.state.Tarefa.Justificativa.trim().length === 0)}>
                                        <span className="d-flex align-items-center"><Icon.Save size={12} className="mr-50" />{this.state.Tarefa.continueTask ? "Iniciar" : "Salvar"}</span>
                                    </Button>
                                </Col>
                                <If visivel={this.state.Tarefa.Codigo > 0}>
                                    <Col md="auto">
                                        <Button color="flat-danger" onClick={() => isDisconnected ? null : this.props.deletarAtividade(Math.floor(newTimeSum / 60))} disabled={(isDisconnected || this.props.requesting) ? true : false}>
                                            <span className="d-flex align-items-center"><Icon.Trash size={12} className="mr-50" />{"Deletar"}</span>
                                        </Button>
                                    </Col>
                                </If>
                            </Row>
                        </Col>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}
export default ModalEditarTarefa
