import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "home",
    title: "Tempo",
    type: "item",
    icon: <Icon.Clock size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "time",
    title: "Time",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/team/config"
  },
  {
    id: "clients",
    title: "Clientes",
    type: "item",
    icon: <Icon.Droplet size={20} />,
    permissions: ["admin"],
    navLink: "/management/clients"
  },
  {
    id: "project",
    title: "Projetos",
    type: "item",
    icon: <Icon.Box size={20} />,
    permissions: ["admin"],
    navLink: "/projects"
  },
  {
    id: "tasks",
    title: "Tarefas",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin"],
    navLink: "/management/tasks"
  },
  {
    id: "rules",
    title: "Regras",
    type: "item",
    icon: <Icon.Flag size={20} />,
    permissions: ["admin"],
    navLink: "/rules"
  }
]

export default horizontalMenuConfig
